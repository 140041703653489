<template>
  <v-dialog v-model="dialog" max-width="100%" scrollable @click:outside="closeDialog">
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="11">
            {{ $t('results.answers') }} {{ $t('results.of') }} {{ pupil.name }}
          </v-col>
          <v-col cols="1" class="d-flex justify-end">
            <v-btn icon @click="closeDialog">
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <LoadingCard v-if="loading" />
      <v-card-text v-else>
        <v-simple-table>
          <template>
            <tbody>
              <tr>
                <td><strong>{{ $t('results.progress') }}</strong></td>
                <td>{{ finishedAnswersAmount }} {{ $t('results.of') }} {{ totalAnswersAmount }} ({{ finishedAnswersPercentage }} %)</td>
              </tr>
              <tr v-if="user.simplifiedView">
                <td><strong>{{ $t('results.correctAnswersAmount') }}</strong></td>
                <td>
                  {{ correctAnswersAmount }} / {{ totalAnswersAmount }}
                </td>
              </tr>
              <tr v-if="!user.simplifiedView">
                <td><strong>{{ $t('results.stage') }}</strong></td>
                <td>
                  <v-chip v-for="(stage, index) in this.namedStages"
                          :key="index"
                          class="mx-1"
                          :class="namedStageColor"
                          label>
                    {{ $t('stages.' + stage.resultName) }}
                    <v-avatar right rounded :color="stageColor">
                      {{ stage.result }}
                    </v-avatar>
                  </v-chip>
                  <v-chip v-for="(stage, index) in this.stages"
                          :key="index"
                          class="mx-1"
                          label
                          :color="stageColor">
                    {{ stage }}
                  </v-chip>
                </td>
              </tr>
              <tr v-if="!user.simplifiedView">
                <td><strong>{{ $t('results.pattern') }}</strong></td>
                <td>
                  <v-sheet v-for="(patternGroup, index) in this.namedPattern"
                           class="pa-1 my-1"
                           :key="index"
                           rounded
                           :color="namedPatternColor">
                    {{ $t('pattern.' + patternGroup.resultName) }}
                    <v-chip v-for="(pattern, iindex) in patternGroup.result"
                            class="mx-1"
                            :key="index + '-' + iindex"
                            :color="patternColor">{{ pattern }}</v-chip>
                  </v-sheet>
                  <v-chip v-for="(patternItem, index) in this.patterns"
                          :key="index"
                          class="mx-1"
                          :color="patternColor">
                    {{ patternItem }}
                  </v-chip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <TestPlayer
                  :courseTest="courseTest"
                  :pupilOutcomes="pupilOutcomes"
                  :test="test"
                  :version="version"
                  :usedDialogIsShown="show"
                  :showSolution="true"
                  :pupilInput="pupilInput"
                  :pagedDisplay="false"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import TestPlayer from '@/components/TestPlayer.vue'
import { BaseVersion, CourseTest, Pupil, ResultInfo, ResultsExplanation, Test, User, Version } from '@/types/Types'
import { LangUtils } from '@/utils/LangUtils'
import { TestUtils } from '@/utils/TestUtils'
import { Watch } from 'vue-property-decorator'
import LoadingCard from '@/components/LoadingCard.vue'
import { ResultUtils } from '@/utils/ResultUtils'
import { ResultTypes } from '@/types/Enums'

const AnswerDialogProps = Vue.extend({
  props: {
    courseTest: { type: Object as () => CourseTest },
    test: { type: Object as () => Test },
    version: { type: Object as () => Version },
    pupil: { type: Object as () => Pupil },
    show: { type: Boolean, required: true },
    showSolution: { type: Boolean, default: false }
  }
})

@Component({
  components: { LoadingCard, TestPlayer }
})
export default class AnswerDialog extends AnswerDialogProps {
  loading = true
  patterns: Array<string> = []
  namedPattern: Array<ResultInfo> = []
  stages: Array<string> = []
  namedStages: Array<ResultInfo> = []
  pupilOutcomes: Record<string, boolean> = {}

  stageColor = ResultUtils.stageColor
  namedStageColor = ResultUtils.namedStageColor
  patternColor = ResultUtils.patternColor
  namedPatternColor = ResultUtils.namedPatternColor

  get user (): User {
    return this.$store.getters['auth/user']
  }

  get dialog (): boolean {
    return this.show
  }

  set dialog (value: boolean) {
    this.$emit('update:show', value)
  }

  get language (): string {
    return this.$i18n.locale
  }

  get selectedLanguage (): string {
    return this.$store.state.language.currentLanguage
  }

  get pupilInput () : Record<string, string> | null {
    if (this.courseTest._id) {
      return ResultUtils.getInputForCourseTestId(this.courseTest._id, this.pupil)
    }

    return null
  }

  get finishedAnswersAmount (): number {
    return ResultUtils.getFinishedAnswersAmount(this.courseTest, this.pupil)
  }

  get finishedAnswersPercentage (): number {
    if (this.totalAnswersAmount > 0) {
      return Math.round(this.finishedAnswersAmount / this.totalAnswersAmount * 100)
    } else {
      return 0
    }
  }

  get totalAnswersAmount (): number {
    return ResultUtils.getTotalAnswersAmount(this.courseTest)
  }

  get correctAnswersAmount (): number {
    return ResultUtils.getCorrectAnswersAmount(this.courseTest, this.pupil)
  }

  @Watch('dialog')
  onDialogChange (showDialog: boolean) {
    if (showDialog) {
      this.loading = true
      this.getResults()
      this.loading = false
    }
  }

  getCurrentLangText (text: Record<string, string>): string {
    return LangUtils.getLangOrFallback(text, this.language)
  }

  closeDialog (): void {
    this.dialog = false
    this.$emit('close')
  }

  getResults (): void {
    if (this.courseTest?._id) {
      this.pupilOutcomes = ResultUtils.getOutcomesForTestId(this.courseTest._id, this.pupil)
      const resultData = ResultUtils.getStageAndPatternForTestId(this.courseTest._id, this.pupil)
      this.namedPattern = resultData.filter(item => item.type === ResultTypes.NamedPattern)
      this.patterns = resultData.filter(item => item.type === ResultTypes.Pattern).map(item => item.result)
      this.namedStages = resultData.filter(item => item.type === ResultTypes.NamedStage)
      this.stages = resultData.filter(item => item.type === ResultTypes.Stage).map(item => item.result)
    }
  }

  mounted (): void {
    this.getResults()

    this.loading = false
  }
}
</script>

<style scoped>

</style>
