<template>
  <v-dialog v-model="dialog" max-width="60%">
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="11">
            {{ $t('addCourseDialog.title') }}
          </v-col>
          <v-col cols="1" class="d-flex justify-end">
            <v-btn icon @click="closeDialog">
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" @submit.prevent="save">
          <v-row>
            <v-col>
              <v-text-field v-model="courseName"
                            :label="$t('addCourseDialog.name')"
                            :rules="courseNameRules"
                            outlined>
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-center">
              <v-btn class="mr-2" :loading="isSaving" @click="closeDialog">
                {{ $t('addCourseDialog.cancel') }}
              </v-btn>
              <v-btn color="primary" type="submit">
                {{ $t('addCourseDialog.save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { VForm } from '@/$refs'

const AddCourseDialogProps = Vue.extend({
  props: {
    show: { type: Boolean, required: true },
    saving: { type: Boolean, default: false }
  }
})

@Component
export default class AddCourseDialog extends AddCourseDialogProps {
  courseName = ''
  courseNameRules = [
    (v: string) => !!v || 'addCourseDialog.nameRequired'
  ]

  get dialog (): boolean {
    return this.show
  }

  set dialog (value: boolean) {
    this.$emit('update:show', value)
  }

  get isSaving (): boolean {
    return this.saving
  }

  set isSaving (value: boolean) {
    this.$emit('update:saving', value)
  }

  get form (): VForm {
    return this.$refs.form as VForm
  }

  save (): void {
    if (this.form.validate()) {
      this.isSaving = true
      this.$emit('save', this.courseName)
      this.courseName = ''
    }
  }

  closeDialog (): void {
    this.dialog = false
    this.$emit('close')
    this.courseName = ''
  }
}
</script>
