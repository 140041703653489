import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { ILoggerOptions } from 'vuejs-logger/dist/interfaces/logger-options'
import { loggerPlugin, logOptions } from '@/plugins/logger'
import i18n from '@/plugins/i18n'
import VueCookies from 'vue-cookies'

Vue.config.productionTip = false

Vue.use<ILoggerOptions>(loggerPlugin, logOptions)
Vue.use(VueCookies)

Vue.$cookies.config('0')

export default new Vue({
  router,
  i18n,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
