<template>
  <v-sheet>
    <LoadingCard v-if="isLoading" flat />
    <template v-else>
      <v-card flat>
        <v-card-title>
          <h2>{{ $t('testResultTable.headline') }}</h2>
          <v-spacer></v-spacer>
          <v-btn text @click="openCourseManagement">
            <v-icon left>
              mdi-plus-circle-outline
            </v-icon>
            {{ $t('testResultTable.manageCourses') }}
          </v-btn>
        </v-card-title>
      </v-card>
      <v-card v-if="!pupils || !pupils.length" flat>
        <v-card-text>
          <p class="d-flex justify-center text-h5 text--disabled mt-10">
            {{ $t('testResultTable.noPupils') }}
          </p>
        </v-card-text>
      </v-card>
      <v-simple-table v-else class="results-overview">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="pupil">
            </th>
            <th class="text-left test" v-for="courseTest in publishedCourseTests" :id="courseTest._id" :key="courseTest._id">
              <div class="d-flex justify-center text-caption">{{ formatDate(courseTest.publishDate) }}</div>
              {{ getCurrentLangText(courseTest.name) }} ({{ courseTest.variant }})
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="pupil in pupils" :id="pupil._id" :key="pupil._id">
            <td>
              {{ pupil.name }}
            </td>
            <td v-for="courseTest in publishedCourseTests" :id="courseTest._id + '-td'" :key="courseTest._id + '-td'">
              <div v-if="!user.simplifiedView && courseTest._id">
                <template
                  v-for="(resultInfo, index) in getStageAndPatternForCourseTestId(courseTest._id, pupil)">
                  <v-sheet v-if="resultInfo.type === namedPattern"
                           class="pa-1 my-1"
                           :key="index"
                           rounded
                           :color="namedPatternColor">
                    {{ $t('pattern.' + resultInfo.resultName) }}
                    <v-chip v-for="(pattern, iindex) in resultInfo.result"
                            class="mx-1"
                            :key="index + '-' + iindex"
                            :color="patternColor">{{ pattern }}</v-chip>
                  </v-sheet>
                  <v-chip
                    v-else
                    :key="index"
                    class="mx-1"
                    :label="resultInfo.type === stage || resultInfo.type === namedStage"
                    :color="getTypeColor(resultInfo.type)">
                    {{ resultInfo.type === namedStage ? $t('stages.' + resultInfo.resultName) : resultInfo.result }}
                    <v-avatar v-if="resultInfo.type === namedStage" rounded right :color="stageColor">
                      {{ resultInfo.result }}
                    </v-avatar>
                  </v-chip>
                </template>
              </div>
              <div v-else>
                {{ getCorrectAnswersAmount(courseTest, pupil) }}
              </div>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </v-sheet>
</template>

<script lang="ts">
import Vue from 'vue'
import { Course, CourseTest, Page, PageParams, Pupil, ResultInfo, User } from '@/types/Types'
import Component from 'vue-class-component'
import LoadingCard from '@/components/LoadingCard.vue'
import { CourseTestService } from '@/services/CourseTestService'
import { AxiosResponse } from 'axios'
import { LangUtils } from '@/utils/LangUtils'
import { DateTime } from 'luxon'
import { ResultUtils } from '@/utils/ResultUtils'
import { ResultTypes } from '@/types/Enums'
import { Watch } from 'vue-property-decorator'

const TestResultTableProps = Vue.extend({
  props: {
    selectedCourseId: { type: String || null },
    courses: { type: Array as () => Array<Course>, required: true },
    pupils: { type: Array as () => Array<Pupil>, required: true }
  }
})
@Component({
  components: { LoadingCard }
})
export default class TestResultTable extends TestResultTableProps {
  isLoading = true
  publishedCourseTests: Array<CourseTest> = []

  stageColor = ResultUtils.stageColor
  namedStageColor = ResultUtils.namedStageColor
  patternColor = ResultUtils.patternColor
  namedPatternColor = ResultUtils.namedPatternColor
  inProgressColor = ResultUtils.inProgressColor
  stage = ResultTypes.Stage
  namedStage = ResultTypes.NamedStage
  pattern = ResultTypes.Pattern
  namedPattern = ResultTypes.NamedPattern
  inProgress = ResultTypes.InProgress

  get user (): User {
    return this.$store.getters['auth/user']
  }

  get language (): string {
    return this.$i18n.locale
  }

  get selectedCourse (): Course | null {
    if (this.selectedCourseId !== null) {
      return this.courses.find(course => course._id === this.selectedCourseId) || null
    } else {
      return null
    }
  }

  @Watch('selectedCourseId')
  onCourseChange (): void {
    this.getCourseTests(this.selectedCourseId)
  }

  getTypeColor (resultType: string): string {
    return ResultUtils.getTypeColor(resultType)
  }

  getStageAndPatternForCourseTestId (courseTestId: string, pupil: Pupil): Array<ResultInfo> {
    return ResultUtils.getStageAndPatternForTestId(courseTestId, pupil)
  }

  getCorrectAnswersAmount (courseTest: CourseTest, pupil: Pupil): string {
    return ResultUtils.getCorrectAnswersAmount(courseTest, pupil) + ' / ' + ResultUtils.getTotalAnswersAmount(courseTest)
  }

  formatDate (date: string): string {
    return DateTime.fromISO(date).setLocale(this.language).toLocaleString(DateTime.DATETIME_SHORT)
  }

  getCurrentLangText (text: Record<string, string>): string {
    return LangUtils.getLangOrFallback(text, this.language)
  }

  getCourseTests (courseId: string): void {
    if (courseId) {
      this.isLoading = true
      this.publishedCourseTests = []

      const pageParams: PageParams = {
        limit: 0
      }

      CourseTestService.getTestsForCourse(courseId, pageParams, true).then((results: AxiosResponse<Page<CourseTest>>) => {
        this.publishedCourseTests = results.data.results.filter((item: CourseTest) => {
          // we'll filter out the ones without any results here, this would mess up any paging!
          return item.resultCount && item.resultCount > 0
        })
      }).catch((error) => {
        this.$log.debug('Could not get course list ', error)

        this.$store.dispatch('notifications/showError', {
          text: this.$t('notifications.error.getCourseTestList').toString()
        })
      }).finally(() => {
        this.isLoading = false
      })
    }
  }

  openCourseManagement () {
    this.$router.push({ name: 'CourseManagement', params: { courseId: this.selectedCourseId } }).catch(error => {
      if (error.name !== 'NavigationDuplicated') {
        throw error
      }
    })
  }

  mounted () {
    this.getCourseTests(this.selectedCourseId)
  }
}
</script>

<style scoped>

</style>
