<template>
  <v-form id="login-form" ref="form">
    <v-text-field prepend-icon="mdi-account"
                  v-model="email"
                  :label="$t('requestPasswordReset.email')"
                  placeholder=" "
                  :rules="emailRules">
      <template #message="{ message }">
        {{ $t(message) }}
      </template>
    </v-text-field>
    <v-row>
      <v-col class="d-flex justify-end">
        <v-btn class="primary" form="login-form" rounded
               type="submit"
               :loading="loading"
               @click.stop.prevent="sendResetLink">
          {{ $t('requestPasswordReset.submit') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import Vue from 'vue'
import { VForm } from '@/$refs'
import i18n from '@/plugins/i18n'

const RequestPasswordResetFormProps = Vue.extend({
  props: {
    //
  }
})

@Component
export default class RequestPasswordResetForm extends RequestPasswordResetFormProps {
  email = ''
  loading = false
  mailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  emailRules = [
    (v: string) => !!v || 'register.emailRequired',
    (v: string) => this.mailRegex.test(v) || 'register.emailRequired'
  ]

  get form (): VForm {
    return this.$refs.form as VForm
  }

  sendResetLink () {
    if (this.form.validate() && this.email) {
      this.loading = true

      this.$store.dispatch('auth/sendPasswordResetLink', this.email).then(() => {
        this.$store.dispatch('notifications/showSuccess', {
          text: i18n.t('notifications.passwordLinkSent').toString()
        })

        this.loading = false
        this.$emit('onSuccess')
      }).catch((error) => {
        this.$log.debug('Could not send password reset link ', error)

        this.$store.dispatch('notifications/showError', {
          text: i18n.t('notifications.couldNotSendPasswordLink').toString()
        })

        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
