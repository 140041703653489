<template>
  <v-container>
    Tutorials
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class Tutorials extends Vue {
}
</script>
