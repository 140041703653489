<template>
  <v-container fluid class="my-15">
    <v-layout align-center justify-center>
      <v-card class="pb-5 pt-5 px-8">
        <v-card-title class="mt-n5">
          <v-container justify="center">
            <v-row justify="center">
              {{ $t('requestPasswordReset.headline') }}
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <RequestPasswordResetForm @onSuccess="forwardToDashboard" />
        </v-card-text>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import Vue from 'vue'

import RequestPasswordResetForm from '@/components/RequestPasswordResetForm.vue'
@Component({
  components: { RequestPasswordResetForm }
})
export default class RequestPasswordReset extends Vue {
  forwardToDashboard () {
    this.$router.push({ name: 'Dashboard' }).catch(error => {
      if (error.name !== 'NavigationDuplicated') {
        throw error
      }
    })
  }

  mounted () {
    // forward if already logged in, we may need to "refresh" the token here to be sure?
    if (this.$store.getters['auth/isAuthenticated']) {
      this.forwardToDashboard()
    }
  }
}
</script>

<style scoped>

</style>
