import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import de from 'vuetify/src/locale/de'
import '@mdi/font/css/materialdesignicons.min.css'

Vue.use(Vuetify)

const opts = {
  breakpoint: {
    mobileBreakpoint: 800
  },
  lang: {
    locales: { de },
    current: 'de'
  },
  theme: {
    themes: {
      light: {
        primary: '#327D87',
        secondary: '#873C32',
        accent: '#3C3287',
        error: '#EC0432',
        info: '#2BDDFF',
        success: '#399D2E',
        warning: '#F67A45',
        anchor: '#D5D5D5'
      }
    }
  }
}

export default new Vuetify(opts)
