import { Pupil, ResultInfo, Result, CourseTest, TypeInfo, ResultsExplanation } from '@/types/Types'
import { DateTime } from 'luxon'
import { ResultTypes } from '@/types/Enums'
import { TestUtils } from '@/utils/TestUtils'
import i18n from '@/plugins/i18n'

export const ResultUtils = {
  stageColor: 'orange',
  namedStageColor: 'orange lighten-4',
  patternColor: 'yellow',
  namedPatternColor: 'yellow lighten-5',
  inProgressColor: 'primary',
  stage: ResultTypes.Stage,
  namedStage: ResultTypes.NamedStage,
  pattern: ResultTypes.Pattern,
  inProgress: ResultTypes.InProgress,

  getTypeColor (resultType: string): string {
    switch (resultType) {
      case this.stage:
        return this.stageColor
      case this.namedStage:
        return this.namedStageColor
      case this.pattern:
        return this.patternColor
      case this.inProgress:
        return this.inProgressColor
      default:
        return 'grey'
    }
  },

  getResultForCourseTestId (courseTestId: string, pupil: Pupil): Record<string, string> | null {
    const result: Result | undefined = pupil.results?.find(item => item.courseTestId === courseTestId)

    return result?.result || null
  },

  getInputForCourseTestId (courseTestId: string, pupil: Pupil): Record<string, string> | null {
    const result: Result | undefined = pupil.results?.find(item => item.courseTestId === courseTestId)

    return result?.inputs || null
  },

  getOutcomesForTestId (courseTestId: string, pupil: Pupil): Record<string, boolean> {
    const result: Result | undefined = pupil.results?.find(item => item.courseTestId === courseTestId)

    return result?.result?.outcomes || null
  },

  getStageAndPatternForTestId (courseTestId: string, pupil: Pupil): Array<ResultInfo> {
    const result: Result | undefined = pupil.results?.find(item => item.courseTestId === courseTestId)
    const resultInfo: Array<ResultInfo> = []

    if (result?.result) {
      // stage may be 0, so we cannot do !result.result.stage
      if (result.result.stage !== null) {
        if (typeof result.result.stage === 'object') {
          Object.entries(result.result.stage).forEach((item) => {
            resultInfo.push({
              type: ResultTypes.NamedStage,
              result: item[1],
              resultName: item[0]
            })
          })
        } else {
          resultInfo.push({
            type: ResultTypes.Stage,
            result: result.result.stage
          })
        }
      }

      if (result.result.patterns) {
        if (Array.isArray(result.result.patterns)) {
          result.result.patterns.forEach((item: any) => {
            // We'll convert all pattern to uppercase letters to prevent case sensitivity issues
            resultInfo.push({
              type: ResultTypes.Pattern,
              result: item.toUpperCase()
            })
          })
        } else {
          Object.entries(result.result.patterns).forEach((item) => {
            resultInfo.push({
              type: ResultTypes.NamedPattern,
              result: item[1],
              resultName: item[0]
            })
          })
        }
      }
    } else if (result) {
      resultInfo.push({
        type: ResultTypes.InProgress,
        result: i18n.t('results.inProgress').toString()
      })
    }

    return resultInfo
  },

  getStageGroupData (stages: Record<string, Record<string, TypeInfo>> | undefined): Record<string, Array<TypeInfo>> {
    const stageGroupList: Record<string, Array<TypeInfo>> = {}

    if (stages) {
      Object.entries(stages).forEach((group) => {
        const [key, value] = group
        stageGroupList[key] = []

        Object.entries(value).forEach((item) => {
          stageGroupList[key].push({
            id: key + item[0],
            name: item[0],
            ...item[1]
          })
        })

        stageGroupList[key].sort((a, b) => {
          if (a.name && b.name) {
            return a.name.localeCompare(b.name)
          } else {
            return 0
          }
        })
      })
    }

    return stageGroupList
  },

  getPatternGroupData (pattern: Record<string, Record<string, TypeInfo>> | undefined): Record<string, Array<TypeInfo>> {
    const patternGroupList: Record<string, Array<TypeInfo>> = {}

    if (pattern) {
      Object.entries(pattern).forEach((group) => {
        const [key, value] = group
        patternGroupList[key] = []

        Object.entries(value).forEach((item) => {
          patternGroupList[key].push({
            id: key + item[0],
            name: item[0],
            ...item[1]
          })
        })
      })
    }

    return patternGroupList
  },

  getHandInDateForCourseTestId (courseTestId: string, pupil: Pupil): DateTime | undefined {
    const result: Result | undefined = pupil.results?.find(item => item.courseTestId === courseTestId)

    if (result?.created) {
      return DateTime.fromISO(result.created)
    } else {
      return undefined
    }
  },

  getCorrectAnswersAmount (courseTest: CourseTest, pupil: Pupil): number {
    if (courseTest._id) {
      const outcomes = this.getOutcomesForTestId(courseTest._id, pupil)
      if (outcomes) {
        return Object.values(outcomes).filter(Boolean).length
      }
    }
    return 0
  },

  getFinishedAnswersAmount (courseTest: CourseTest, pupil: Pupil): number {
    if (courseTest && courseTest._id && courseTest.testVersion && courseTest.versions) {
      const currentVersion = TestUtils.findVersionInList(courseTest.testVersion, courseTest.versions)
      if (currentVersion?.solution) {
        const pupilInput = ResultUtils.getInputForCourseTestId(courseTest._id, pupil)
        let finishedAnswers = 0
        Object.entries(currentVersion.solution).forEach((item) => {
          if (pupilInput && pupilInput[item[0]] !== '' && pupilInput[item[0]] !== 'blank') {
            finishedAnswers += 1
          }
        })
        return finishedAnswers
      }
    }
    return 0
  },

  getTotalAnswersAmount (courseTest: CourseTest): number {
    if (courseTest && courseTest._id && courseTest.testVersion && courseTest.versions) {
      const currentVersion = TestUtils.findVersionInList(courseTest.testVersion, courseTest.versions)
      if (currentVersion?.solution) {
        return Object.entries(currentVersion.solution).length
      }
    }
    return 0
  },

  between (value: number, min: number, max: number): boolean {
    return value >= min && value <= max
  },

  toSliderValue (value: number, normalizationValue: number): number {
    return value * (normalizationValue / 100)
  }
}
