<template>
  <v-dialog v-model="dialog" persistent :max-width="mobile ? '100%' : '60%'">
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="11">
            {{ $t('addPupilDialog.title') }}
          </v-col>
          <v-col cols="1" class="d-flex justify-end">
            <v-btn icon @click="closeDialog">
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-subtitle class="mt-1">
        <v-alert type="info" color="primary" outlined>
          {{ $t('addPupilDialog.info') }}
        </v-alert>
      </v-card-subtitle>

      <v-card-text>
        <v-form @submit.prevent="save">
          <v-text-field v-model="firstname"
                        :label="$t('addPupilDialog.firstname')"
                        outlined>
          </v-text-field>
          <v-text-field v-model="lastname"
                        :label="$t('addPupilDialog.lastname')"
                        outlined>
          </v-text-field>
          <v-row>
            <v-col class="d-flex justify-center">
              <v-btn class="mr-2" :loading="saving" @click="closeDialog">
                {{ $t('addPupilDialog.cancel') }}
              </v-btn>
              <v-btn :loading="saving" color="primary" type="submit">
                {{ $t('addPupilDialog.save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Course } from '@/types/Types'
import { PupilService } from '@/services/PupilService'
import { StringUtils } from '@/utils/StringUtils'

const AddPupilDialogProps = Vue.extend({
  props: {
    course: { type: Object as () => Course, required: true },
    show: { type: Boolean, required: true }
  }
})

@Component
export default class AddPupilDialog extends AddPupilDialogProps {
  firstname = ''
  lastname = ''
  saving = false

  get dialog (): boolean {
    return this.show
  }

  set dialog (value: boolean) {
    this.$emit('update:show', value)
  }

  get mobile (): boolean {
    return this.$vuetify.breakpoint.mobile
  }

  save (): void {
    if (this.course._id) {
      this.saving = true

      const pupilData = {
        name: StringUtils.getPseudonym(this.firstname, this.lastname),
        courseId: this.course._id
      }

      PupilService.createPupil(pupilData).then(() => {
        this.firstname = ''
        this.lastname = ''

        this.$store.dispatch('notifications/showSuccess', {
          text: this.$t('notifications.success.accessNameCreate').toString()
        })

        this.dialog = false
        this.$emit('onSaved', this.course._id)
      }).catch((error) => {
        this.$log.debug('could not create access', error)

        this.$store.dispatch('notifications/showError', {
          text: this.$t('notifications.error.accessNameAlreadyExists').toString()
        })
      }).finally(() => {
        this.saving = false
      })
    }
  }

  closeDialog (): void {
    this.dialog = false
    this.$emit('close')
  }
}
</script>
