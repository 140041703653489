<template>
  <v-card min-width="300" max-width="300" min-height="400" class="d-flex flex-column">
    <v-card-title>
      <v-row align="center">
        <v-col cols="9">
          {{ getCurrentLangText(test.name) }}
        </v-col>
        <v-col cols="3">
          <v-chip>
            {{ test.variant }}
          </v-chip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-subtitle v-if="minClassLevel" class="d-flex justify-end">
      {{ $t('testCard.classLevel') }} {{ minClassLevel }}
    </v-card-subtitle>
    <v-img :src="previewPath" max-height="300" height="300" width="300" contain/>
    <v-card-text v-if="!user.simplifiedView" v-html="getCurrentLangText(test.description)">
    </v-card-text>
    <v-spacer />
    <v-card-actions>
      <v-row>
        <v-col cols="6" class="d-flex justify-start">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip, attrs }">
              <v-btn color="primary" :aria-label="$t('tooltips.preview')" v-bind="attrs" v-on="tooltip" @click="openPreview">
                <v-icon>
                  mdi-magnify
                </v-icon>
                {{ $t('testCard.preview') }}
              </v-btn>
            </template>
            <span>{{ $t('testCard.preview') }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="6" class="d-flex justify-end">
          <v-menu v-if="inLibrary" v-model="addPopout" offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" :loading="isLoading" v-bind="attrs" v-on="on">
                <v-icon>
                  mdi-plus
                </v-icon>
                {{ $t('testCard.addToCourse') }}
              </v-btn>
            </template>
            <v-list>
              <v-card flat>
                <v-card-text>
                  <v-autocomplete
                    :label="$t('testCard.selectCourse')"
                    v-model="selectedCourse"
                    item-text="name"
                    return-object
                    :items="courses">
                  </v-autocomplete>
                  <v-select v-model="selectedVersion"
                            :items="test.versions"
                            hide-details
                            dense
                            return-object
                            :item-text="item => getVersionString(item)" />
                </v-card-text>
                <v-card-actions>
                  <v-btn :disabled="!selectedCourse" @click="addTest(test)">
                    {{ $t('testCard.add') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-list>
          </v-menu>
          <v-menu v-else v-model="addPopout" offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" :loading="isLoading" v-bind="attrs" v-on="on">
                <v-icon>
                  mdi-plus
                </v-icon>
                {{ $t('testCard.addToCourse') }}
              </v-btn>
            </template>
            <v-list>
              <v-card flat>
                <v-card-text>
                  <v-select v-model="selectedVersion"
                            :items="test.versions"
                            hide-details
                            dense
                            return-object
                            :item-text="item => getVersionString(item)" />
                </v-card-text>
                <v-card-actions>
                  <v-btn :disabled="!selectedVersion" @click="addTest(test)">
                    {{ $t('testCard.add') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-list>
          </v-menu>
          <v-btn v-if="false" icon @click="openDetailsDialog">
            <v-icon>
              mdi-information-outline
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import Vue from 'vue'
import { Course, CourseTestAdd, Test, User, Version } from '@/types/Types'
import { LangUtils } from '@/utils/LangUtils'
import { TestUtils } from '@/utils/TestUtils'
import { CourseTestService } from '@/services/CourseTestService'

const TestCardProps = Vue.extend({
  props: {
    loading: { type: Boolean, default: false },
    test: { type: Object as () => Test, required: true },
    courses: { type: Array as () => Array<Course> }
  }
})

@Component
export default class TestCard extends TestCardProps {
  selectedVersion: Version | null = null
  addPopout = false
  selectedCourse: Course | null = null

  get language (): string {
    return this.$i18n.locale
  }

  get isLoading (): boolean {
    return this.loading
  }

  set isLoading (value: boolean) {
    this.$emit('update:loading', value)
  }

  get user (): User {
    return this.$store.getters['auth/user']
  }

  get inLibrary (): boolean {
    return this.$route.name === 'Library'
  }

  get minClassLevel (): number | null {
    if (this.test.classLevel && this.test.classLevel[this.language].length) {
      return Math.min(...this.test.classLevel[this.language].map(item => Number(item)))
    } else {
      return null
    }
  }

  get previewPath (): string {
    return '/tests-src/' + this.test.machineName + '/preview.svg'
  }

  getCurrentLangText (text: Record<string, string>): string {
    return LangUtils.getLangOrFallback(text, this.language)
  }

  getVersionString (version: Version): string {
    return this.$t('global.version').toString() + ' ' + TestUtils.getVersionString(version)
  }

  addTest (test: Test): void {
    if (this.courses && this.courses.length === 1) {
      this.selectedCourse = this.courses[0]
    }

    if (this.selectedCourse?._id &&
      this.selectedVersion?.majorVersion !== undefined &&
      this.selectedVersion?.minorVersion !== undefined &&
      this.selectedVersion?.patchVersion !== undefined) {
      const courseAdd: CourseTestAdd = {
        courseId: this.selectedCourse._id,
        testId: this.test._id,
        testVersion: {
          majorVersion: this.selectedVersion?.majorVersion,
          minorVersion: this.selectedVersion?.minorVersion,
          patchVersion: this.selectedVersion?.patchVersion
        }
      }

      CourseTestService.createTestForCourse(courseAdd).then(() => {
        this.$store.dispatch('notifications/showSuccess', {
          text: this.$t('notifications.success.addTestToCourse').toString()
        })

        this.$emit('added')
      }).catch((error) => {
        this.$log.debug('Could not add test to course', error)

        this.$store.dispatch('notifications/showError', {
          text: this.$t('notifications.error.addTestToCourse').toString()
        })
      })
    } else {
      this.$log.debug('Could not add test to course')

      this.$store.dispatch('notifications/showError', {
        text: this.$t('notifications.error.addTestToCourse').toString()
      })
    }
  }

  openPreview (): void {
    this.$emit('openPreview', this.test)
  }

  openDetailsDialog (): void {
    this.$emit('openDetailsDialog', this.test)
  }

  mounted (): void {
    if (this.test.versions.length) {
      this.selectedVersion = this.test.versions[0]
    }
  }
}
</script>

<style scoped>
  .v-card__title {
    min-height: 140px;
    white-space: pre-line;
    word-break: break-word;
  }

  .v-card__subtitle {
    min-height: 20px;
  }

  .v-card__actions .v-btn.primary {
    width: 98%;
  }
</style>
