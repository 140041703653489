import { Categories } from '@/types/Enums'

export const Options = {
  languages: [
    {
      value: 'en',
      nameKey: 'languages.english'
    },
    {
      value: 'de',
      nameKey: 'languages.german'
    }
  ],

  // We'll pull the categories from the DB, but we still add the "all" category
  categories: [
    {
      value: Categories.All,
      nameKey: 'categories.all'
    }
  ]
}
