export const LangUtils = {
  getLangOrFallback (item: Record<string, string>, language: string): string {
    // if no object was given return an empty string
    if (!item) {
      return ''
    }

    const languages = Object.keys(item)

    // return the currently selected language
    if (languages.includes(language) && item[language]) {
      return item[language]
      // if currently selected language is not available fall back to english
    } else if (languages.includes('en') && item.en) {
      return item.en
      // if english is also not included return the first entry as fallback
    } else {
      if (item[languages[0]] && item[languages[0]]) {
        return item[languages[0]]
      } else {
        // if we cannot find anything return an empty string
        return ''
      }
    }
  }
}
