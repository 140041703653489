import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { RootState } from '@/types/StoreStates'
import { NotificationStore } from '@/store/modules/NotificationStore'
import { AuthStore } from '@/store/modules/AuthStore'
import { LanguageStore } from '@/store/modules/LanguageStore'
import { TestStore } from '@/store/modules/TestStore'
import { LibraryStore } from '@/store/modules/LibraryStore'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const store: StoreOptions<RootState> = {
  state: {
    selectedMenuItem: null,
    showDrawer: false
  },
  mutations: {
    setSelectedMenuItem (state, payload) {
      state.selectedMenuItem = payload
    },

    setShowDrawer (state, payload) {
      state.showDrawer = payload
    }
  },
  modules: {
    auth: AuthStore,
    notifications: NotificationStore,
    language: LanguageStore,
    test: TestStore,
    library: LibraryStore
  },
  plugins: [createPersistedState({
    paths: ['auth.currentUser', 'auth.token']
  })]
}

export default new Vuex.Store<any>(store)
