import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { LibraryState, RootState } from '@/types/StoreStates'
import vuem from '@/main'
import store from '@/store'
import i18n from '@/plugins/i18n'
import { CategoryService } from '@/services/CategoryService'
import { Options } from '@/types/Options'
import { Categories } from '@/types/Enums'
import { OptionalTranslation } from '@/types/Types'

const namespaced = true

const state: LibraryState = {
  selectedTestCategory: null,
  searchTerm: '',
  categories: []
}

const mutations: MutationTree<LibraryState> = {
  setSelectedTestCategory (state, payload) {
    state.selectedTestCategory = payload
  },

  setSearchTerm (state, payload) {
    state.searchTerm = payload
  },

  setCategories (state, payload) {
    state.categories = payload
  }
}

const actions: ActionTree<LibraryState, RootState> = {
  getCategories ({ commit }) {
    return new Promise<void>((resolve, reject) => {
      CategoryService.getCategories(store.state.language.currentLanguage).then((results) => {
        const categories: Array<OptionalTranslation> = Options.categories

        // Map to OptionalTranslation structure, so we can use "all" which is not a predefined category
        results.data.categories.forEach((category: string) => {
          categories.push({
            value: category,
            name: category
          })
        })

        commit('setCategories', categories)

        resolve()
      }).catch((error) => {
        vuem.$log.debug('Could not get categories list ', error)

        store.dispatch('notifications/showError', {
          text: i18n.t('notifications.error.getCategoriesList').toString()
        })

        reject(error)
      })
    })
  }
}

const getters: GetterTree<LibraryState, RootState> = {
}

export const LibraryStore: Module<LibraryState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
