import { AxiosPromise } from 'axios'
import { ApiService } from '@/api/ApiService'
import { Course } from '@/types/Types'

export const CourseService = {
  getCourses (): AxiosPromise {
    return ApiService({
      url: '/courses',
      method: 'get'
    })
  },

  createCourse (data: Course): AxiosPromise {
    return ApiService({
      url: '/courses',
      method: 'post',
      data: data
    })
  },

  updateCourse (courseId: string, data: Course): AxiosPromise {
    return ApiService({
      url: '/courses',
      method: 'put',
      data: data,
      params: {
        id: courseId
      }
    })
  },

  deleteCourse (courseId: string): AxiosPromise {
    return ApiService({
      url: '/courses/' + courseId,
      method: 'delete'
    })
  }
}
