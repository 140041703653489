<template>
  <v-container fluid class="my-15">
    <v-layout align-center justify-center>
      <v-card class="pb-5 pt-5 px-8">
        <v-card-title class="mt-n5">
          <v-container justify="center">
            <v-row justify="center">
              {{ $t('confirmPasswordReset.headline') }}
            </v-row>
          </v-container>
        </v-card-title>
        <v-card-text>
          <ConfirmPasswordResetForm @resetPassword="resetPassword" :loading="loading" />
        </v-card-text>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import Vue from 'vue'
import i18n from '@/plugins/i18n'
import ConfirmPasswordResetForm from '@/components/ConfirmPasswordResetForm.vue'

@Component({
  components: { ConfirmPasswordResetForm }
})
export default class ConfirmPasswordReset extends Vue {
  loading = false

  resetPassword (password: string): void {
    if (this.$route.params.token) {
      this.loading = true

      const payload = {
        token: this.$route.params.token,
        password: password
      }

      this.$store.dispatch('auth/resetPassword', payload).then(() => {
        this.$store.dispatch('notifications/showSuccess', {
          text: i18n.t('notifications.passwordReset').toString()
        })
        this.$router.push({ name: 'Public' })
      }).catch((error) => {
        this.$log.debug('Could not reset password ', error)

        this.$store.dispatch('notifications/showError', {
          text: i18n.t('notifications.couldNotResetPassword').toString()
        })

        this.loading = false
      })
    }
  }

  forwardToDashboard () {
    this.$router.push({ name: 'Dashboard' }).catch(error => {
      if (error.name !== 'NavigationDuplicated') {
        throw error
      }
    })
  }
}
</script>

<style scoped>

</style>
