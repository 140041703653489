import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Login from '@/views/usersManagement/Login.vue'
import RequestPasswordReset from '@/views/usersManagement/RequestPasswordReset.vue'
import ConfirmPasswordReset from '@/views/usersManagement/ConfirmPasswordReset.vue'
import Register from '@/views/usersManagement/Register.vue'
import Verify from '@/views/usersManagement/Verify.vue'
import store from '@/store'
import { DateTime } from 'luxon'
import Imprint from '@/views/legal/Imprint.vue'
import Dataprotection from '@/views/legal/Dataprotection.vue'
import Terms from '@/views/legal/Terms.vue'
import Library from '@/views/main/library/Library.vue'
import Dashboard from '@/views/main/Dashboard.vue'
import Results from '@/views/main/tests/Results.vue'
import Tutorials from '@/views/main/Tutorials.vue'
import PupilLogin from '@/views/pupils/PupilLogin.vue'
import PupilDashboard from '@/views/pupils/PupilDashboard.vue'
import ConductTest from '@/views/pupils/ConductTest.vue'
import Verification from '@/views/usersManagement/Verification.vue'
import Courses from '@/views/main/tests/Courses.vue'
import CourseManagement from '@/views/main/tests/CourseManagement.vue'
import { UserService } from '@/services/UserService'
import UserInit from '@/views/usersManagement/UserInit.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'PupilDashboard',
    component: PupilDashboard,
    meta: { pupils: true }
  },
  {
    path: '/conduct/:courseTestId',
    name: 'ConductTest',
    component: ConductTest,
    meta: { pupils: true }
  },
  {
    path: '/pupils',
    name: 'PupilLogin',
    component: PupilLogin,
    meta: { pupils: true }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    redirect: { name: 'Courses' },
    component: Dashboard,
    children: [
      {
        path: '/courses/',
        name: 'Courses',
        component: Courses
      },
      {
        path: '/course-management/:courseId?',
        name: 'CourseManagement',
        component: CourseManagement
      },
      {
        path: '/library/:category?',
        name: 'Library',
        component: Library
      },
      {
        path: '/results/:courseTestId',
        name: 'Results',
        component: Results
      },
      {
        path: '/tutorials/',
        name: 'Tutorials',
        component: Tutorials
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { public: true }
  },
  {
    path: '/reset/request',
    name: 'RequestPasswordReset',
    component: RequestPasswordReset,
    meta: { public: true }
  },
  {
    path: '/reset/confirm/:token',
    name: 'ConfirmPasswordReset',
    component: ConfirmPasswordReset,
    meta: { public: true }
  },
  {
    path: '/aef41b7c-9995-11ee-8567-54b2039ef4c6',
    name: 'Register',
    component: Register,
    meta: { public: true }
  },
  {
    path: '/verification',
    name: 'Verification',
    component: Verification,
    meta: { public: true }
  },
  {
    path: '/user-init',
    name: 'UserInit',
    component: UserInit,
    meta: { public: true }
  },
  {
    path: '/verify/:token',
    name: 'Verify',
    component: Verify,
    meta: { public: true }
  },
  {
    path: '/imprint',
    name: 'Imprint',
    component: Imprint,
    meta: { public: true }
  },
  {
    path: '/dataprotection',
    name: 'Dataprotection',
    component: Dataprotection,
    meta: { public: true }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
    meta: { public: true }
  },
  {
    path: '*',
    redirect: { name: 'PupilDashboard' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (store.getters['auth/isAuthenticated']) {
    const user = store.getters['auth/user']

    // get a new token if it's time or if the user is not set
    if (store.getters['auth/timeForNewToken'](DateTime.now()) || !user) {
      store.dispatch('auth/renewToken').catch(() => {
        store.dispatch('auth/logout')
      }).then(() => {
        // we need to check this again as it does not trigger another beforeEach when using next() here
        // If logged-in but not verified yet, forward to verification
        if (to.name !== 'Verification' && store.getters['auth/user'] && !store.getters['auth/user'].is_verified) {
          next({ name: 'Verification' })
        }

        // show user init page if pw change, acceptance of terms or code input is required
        if (to.name !== 'UserInit' && UserService.userNotInitialized()) {
          next({ name: 'UserInit' })
        }

        next()
      })
    }

    // If logged-in but not verified yet, forward to verification
    if (to.name !== 'Verification' && user && !user.is_verified) {
      next({ name: 'Verification' })
    }

    // show user init page if pw change, acceptance of terms or code input is required
    if (to.name !== 'UserInit' && UserService.userNotInitialized()) {
      next({ name: 'UserInit' })
    }

    // If logged-in forward to dashboard instead of pupil login
    if (to.path === '/') {
      next({ name: 'Dashboard' })
    }
  } else if (to.name !== 'Login' && !to.meta?.pupils && !to.meta?.public) {
    next({ name: 'Login' })
  } else if (to.name !== 'PupilLogin' && to.meta?.pupils && !store.getters['auth/isPupilAuthenticated']) {
    // if pupil is not logged in foward to pupil login
    next({ name: 'PupilLogin' })
  }

  next()
})

export default router
