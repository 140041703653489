<template>
  <v-dialog v-model="dialog" max-width="60%" :persistent="persistent">
    <v-card class="pt-5">
      <v-card-title v-if="title">
        <v-row>
          <v-col cols="11">
            <h2>{{ title }}</h2>
          </v-col>
          <v-col cols="1" class="d-flex justify-end">
            <v-btn icon @click="closeDialog">
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title v-if="!title" class="mb-5">
        {{ text }}
      </v-card-title>
      <v-card-text v-else>
        {{ text }}
      </v-card-text>
      <v-card-actions class="d-flex justify-center pb-10">
        <v-btn v-if="!isLoading" @click="onCancel">{{ cancelText ? cancelText : $t('global.cancel') }}</v-btn>
        <v-btn color="primary" :loading="isLoading" @click="onConfirm">{{ confirmText ? confirmText : $t('global.confirm') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import Vue from 'vue'

const SimpleConfirmDialogProps = Vue.extend({
  props: {
    persistent: { type: Boolean, required: false, default: false },
    showDialog: { type: Boolean, required: true },
    title: { type: String },
    type: { type: String },
    text: { type: String, required: true },
    cancelText: { type: String, required: false },
    confirmText: { type: String, required: false },
    isLoading: { type: Boolean, default: false }
  }
})

@Component
export default class SimpleConfirmDialog extends SimpleConfirmDialogProps {
  get dialog (): boolean {
    return this.showDialog
  }

  set dialog (value: boolean) {
    this.$emit('update:showDialog', value)
  }

  closeDialog (): void {
    this.dialog = false
  }

  onConfirm (): void {
    this.$emit('update:isLoading', true)
    if (this.type) {
      this.$emit('onConfirm', this.type)
    } else {
      this.$emit('onConfirm')
    }
  }

  onCancel (): void {
    this.$emit('onCancel')
    this.closeDialog()
  }
}
</script>

<style scoped>

</style>
