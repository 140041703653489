import { BaseVersion, Pupil, ResultInfo, ResultsExplanation, Version } from '@/types/Types'
import { LocalApiService } from '@/api/ApiService'
import $ from 'jquery'
import { ResultUtils } from '@/utils/ResultUtils'

export const TestUtils = {
  getVersionString (version: BaseVersion, underScore = false): string {
    if (underScore) {
      return version.majorVersion + '_' + version.minorVersion + '_' + version.patchVersion
    } else {
      return version.majorVersion + '.' + version.minorVersion + '.' + version.patchVersion
    }
  },

  findVersionInList (version: BaseVersion, versionList: Array<Version>): Version | null {
    if (versionList.length === 0) {
      return null
    }

    return versionList.find(test => test.majorVersion === version.majorVersion && test.minorVersion === version.minorVersion && test.patchVersion === version.minorVersion) || null
  },

  getExplanationHTMLForTest (version: BaseVersion, machineName: string, language: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      // example: /tests-src/31-WritingExpressionsMulAddSubA/1.0.0/items/98-en.html
      const urlString = '/tests-src/' + machineName + '/' + this.getVersionString(version) + '/explanation-' + language + '.html'
      LocalApiService({
        url: urlString,
        method: 'get'
      }).then((result) => {
        resolve(result.data)
      }).catch((error) => {
        // fallback to EN
        if (language !== 'en') {
          resolve(this.getExplanationHTMLForTest(version, machineName, 'en'))
        }
        reject(error)
      })
    })
  },

  parseExplanationHTMLForTest (version: BaseVersion, machineName: string, language: string, pupils: Array<Pupil> = [], testId = ''): Promise<ResultsExplanation> {
    return new Promise<ResultsExplanation>((resolve, reject) => {
      this.getExplanationHTMLForTest(version, machineName, language).then((result) => {
        const html = $('<div>' + result + '</div>')

        // We'll convert all pattern to uppercase letters to prevent case sensitivity issues
        let resultsExplanation: ResultsExplanation = {
          introduction: html.find('[data-type="introduction"]').html(),
          suggestionsIntroduction: html.find('[data-type="teaching-suggestions-introduction"]').html(),
          references: html.find('[data-type="references"]').html(),
          stages: {},
          patterns: {}
        }

        html.find('[data-type="description"]').each(function () {
          if ($(this).attr('data-stage')) {
            const stage = $(this).attr('data-stage')
            const group = $(this).attr('data-group')

            if (group && stage) {
              resultsExplanation = TestUtils.createStageIfNonExisting(resultsExplanation, group, stage)
              resultsExplanation.stages[group][stage].description = $(this).html()
            } else if (stage) {
              resultsExplanation = TestUtils.createStageIfNonExisting(resultsExplanation, 'default', stage)
              resultsExplanation.stages.default[stage].description = $(this).html()
            }
          }
          if ($(this).attr('data-pattern')) {
            let pattern = $(this).attr('data-pattern')
            const group = $(this).attr('data-group')

            if (group && pattern) {
              pattern = pattern.toUpperCase()
              resultsExplanation = TestUtils.createPatternIfNonExisting(resultsExplanation, group, pattern)
              resultsExplanation.patterns[group][pattern].description = $(this).html()
            } else if (pattern) {
              pattern = pattern.toUpperCase()
              resultsExplanation = TestUtils.createPatternIfNonExisting(resultsExplanation, 'default', pattern)
              resultsExplanation.patterns.default[pattern].description = $(this).html()
            }
          }
        })

        html.find('[data-type="details"]').each(function () {
          if ($(this).attr('data-stage')) {
            const stage = $(this).attr('data-stage')
            const group = $(this).attr('data-group')

            if (group && stage) {
              resultsExplanation = TestUtils.createStageIfNonExisting(resultsExplanation, group, stage)
              resultsExplanation.stages[group][stage].details = $(this).html()
            } else if (stage) {
              resultsExplanation = TestUtils.createStageIfNonExisting(resultsExplanation, 'default', stage)
              resultsExplanation.stages.default[stage].details = $(this).html()
            }
          }
          if ($(this).attr('data-pattern')) {
            let pattern = $(this).attr('data-pattern')
            const group = $(this).attr('data-group')

            if (group && pattern) {
              pattern = pattern.toUpperCase()
              resultsExplanation = TestUtils.createPatternIfNonExisting(resultsExplanation, group, pattern)
              resultsExplanation.patterns[group][pattern].details = $(this).html()
            } else if (pattern) {
              pattern = pattern.toUpperCase()
              resultsExplanation = TestUtils.createPatternIfNonExisting(resultsExplanation, 'default', pattern)
              resultsExplanation.patterns.default[pattern].details = $(this).html()
            }
          }
        })

        html.find('[data-type="teaching-suggestions"]').each(function () {
          if ($(this).attr('data-stage')) {
            const stage = $(this).attr('data-stage')
            const group = $(this).attr('data-group')

            if (group && stage) {
              resultsExplanation = TestUtils.createStageIfNonExisting(resultsExplanation, group, stage)
              resultsExplanation.stages[group][stage].suggestions = $(this).html()
            } else if (stage) {
              resultsExplanation = TestUtils.createStageIfNonExisting(resultsExplanation, 'default', stage)
              resultsExplanation.stages.default[stage].suggestions = $(this).html()
            }
          }
          if ($(this).attr('data-pattern')) {
            let pattern = $(this).attr('data-pattern')
            const group = $(this).attr('data-group')

            if (group && pattern) {
              pattern = pattern.toUpperCase()
              resultsExplanation = TestUtils.createPatternIfNonExisting(resultsExplanation, group, pattern)
              resultsExplanation.patterns[group][pattern].suggestions = $(this).html()
            } else if (pattern) {
              pattern = pattern.toUpperCase()
              resultsExplanation = TestUtils.createPatternIfNonExisting(resultsExplanation, 'default', pattern)
              resultsExplanation.patterns.default[pattern].suggestions = $(this).html()
            }
          }
        })

        if (pupils && pupils.length && testId) {
          // merge results of pupils in the object
          pupils.forEach((pupil: Pupil) => {
            const resultInfo: Array<ResultInfo> = ResultUtils.getStageAndPatternForTestId(testId, pupil)
            resultInfo.forEach((result) => {
              if (result.type === 'stage' && resultsExplanation.stages.default[result.result]) {
                resultsExplanation.stages.default[result.result].pupilNames.push(pupil.name)
              } else if (result.type === 'namedStage' && result.resultName && resultsExplanation.stages[result.resultName]) {
                resultsExplanation.stages[result.resultName][result.result].pupilNames.push(pupil.name)
              } else if (result.type === 'pattern' && resultsExplanation.patterns.default[result.result]) {
                resultsExplanation.patterns.default[result.result].pupilNames.push(pupil.name)
              } else if (result.type === 'namedPattern' && result.resultName && resultsExplanation.stages[result.resultName]) {
                resultsExplanation.patterns[result.resultName][result.result].pupilNames.push(pupil.name)
              }
            })
          })
        }

        resolve(resultsExplanation)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  createStageIfNonExisting (resultsExplanation: ResultsExplanation, group: string, key: string): ResultsExplanation {
    if (!resultsExplanation.stages[group]) {
      resultsExplanation.stages[group] = {}
    }

    if (!resultsExplanation.stages[group][key]) {
      resultsExplanation.stages[group][key] = {
        description: '',
        details: '',
        suggestions: '',
        pupilNames: []
      }
    }

    return resultsExplanation
  },

  createPatternIfNonExisting (resultsExplanation: ResultsExplanation, group: string, key: string): ResultsExplanation {
    if (!resultsExplanation.patterns[group]) {
      resultsExplanation.patterns[group] = {}
    }

    if (!resultsExplanation.patterns[group][key]) {
      resultsExplanation.patterns[group][key] = {
        description: '',
        details: '',
        suggestions: '',
        pupilNames: []
      }
    }

    return resultsExplanation
  },

  getSingleHTMLForTest (version: Version, htmlId: number, machineName: string, language: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      // example: /tests-src/31-WritingExpressionsMulAddSubA/1.0.0/items/98-en.html
      const urlString = '/tests-src/' + machineName + '/' + this.getVersionString(version) + '/items/' + htmlId + '-' + language + '.html'
      LocalApiService({
        url: urlString,
        method: 'get'
      }).then((result) => {
        resolve(result.data)
      }).catch((error) => {
        // fallback to EN
        if (language !== 'en') {
          resolve(this.getSingleHTMLForTest(version, htmlId, machineName, 'en'))
        } else {
          reject(error)
        }
      })
    })
  },

  getCompleteHTMLForTest (version: Version, machineName: string, language: string, isTeacher: boolean): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const promises: Array<Promise<string>> = []

      if (version.pageIds) {
        version.pageIds.forEach((item) => {
          // Teacher should not see pages with ids greater or equal 1000, for study only
          if (!isTeacher || (isTeacher && item < 10000)) {
            promises.push(this.getSingleHTMLForTest(version, item, machineName, language))
          }
        })
      }

      Promise.allSettled(promises).then((results: Array<PromiseSettledResult<string>>) => {
        let html = '<form id="test-form">'
        let counter = 1

        // it should actually be PromiseSettledResult<string> but this does not recognize the attribute "value"
        results.forEach((result: any) => {
          html += '<div class="page" id="page-' + counter + '">'
          if (result.value) {
            html += result.value
          }
          html += '</div>'

          counter++
        })

        html += '</form>'

        // For study only
        if (isTeacher) {
          const $html = $($.parseHTML(html))
          $html.find('.hidden-for-teacher').remove()
          html = $html.prop('outerHTML')
        }

        resolve(html)
      }).catch((error) => {
        reject(error)
      })
    })
  }
}
