<template>
  <v-dialog v-model="dialog" :persistent="persistent" :max-width="mobile ? '100%' : '60%'">
    <v-card class="pt-5">
      <v-card-title v-if="title">
        <v-row>
          <v-col cols="11">
            <h2>{{ title }}</h2>
          </v-col>
          <v-col cols="1" class="d-flex justify-end">
            <v-btn icon @click="closeDialog">
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text :class="!title ? 'mb-5' : ''">
        <p>{{ text }}</p>
        <v-form ref="form" @submit.prevent="onDelete">
          <v-row>
            <v-col cols="6">
              <p>{{ $t('secureDeleteDialog.enterToDelete') }} ({{ this.deletePhrase }}):</p>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="repeatPhrase"
                        :rules="phraseRepeatRules"
                        outlined>
                <template #message="{ message }">
                  {{ $t(message) }}
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-center">
              <v-btn class="mr-2" v-if="!isLoading" @click="onCancel">
                {{ $t('global.cancel') }}
              </v-btn>
              <v-btn color="primary" :loading="isLoading" type="submit">
                {{ $t('global.delete') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import Vue from 'vue'
import { VForm } from '@/$refs'
import { Watch } from 'vue-property-decorator'

const SimpleConfirmDialogProps = Vue.extend({
  props: {
    deletePhrase: { type: String, required: true },
    showDialog: { type: Boolean, required: true },
    text: { type: String, required: true },
    title: { type: String, required: true },
    persistent: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false }
  }
})

@Component
export default class SimpleConfirmDialog extends SimpleConfirmDialogProps {
  repeatPhrase = ''
  phraseRepeatRules = [
    (v: string) => !!v || 'secureDeleteDialog.phraseRequired',
    (v: string) => this.matchesPhrase(v) || 'secureDeleteDialog.phraseRepeatWrong'
  ]

  get dialog (): boolean {
    return this.showDialog
  }

  set dialog (value: boolean) {
    this.$emit('update:showDialog', value)
  }

  get mobile (): boolean {
    return this.$vuetify.breakpoint.mobile
  }

  get form (): VForm {
    return this.$refs.form as VForm
  }

  @Watch('showDialog')
  onDialogChange (): void {
    this.repeatPhrase = ''
  }

  matchesPhrase (phrase: string): boolean {
    return this.deletePhrase === phrase
  }

  closeDialog (): void {
    this.dialog = false
  }

  onDelete (): void {
    if (this.form.validate()) {
      this.$emit('update:isLoading', true)
      this.$emit('onDelete')
    }
  }

  onCancel (): void {
    this.$emit('onCancel')
    this.closeDialog()
  }
}
</script>

<style scoped>

</style>
