import { ApiService } from '@/api/ApiService'

export const CategoryService = {
  getCategories (lang = 'de') {
    return ApiService({
      url: '/categories/' + lang,
      method: 'get'
    })
  }
}
