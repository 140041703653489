<template>
  <v-footer :app="!mobile" :absolute="mobile" dark color="primary" class="footer">
    <v-img contain src="@/assets/img/dzlm_white.png" max-height="36px" max-width="84px"/>
    <v-spacer/>
    <div>
      &nbsp;
      <template v-if="false">
        <router-link :to="{ name: 'Dataprotection' }">{{ $t('footer.dataprotection') }}</router-link>
        |
        <router-link :to="{ name: 'Terms' }">{{ $t('footer.terms') }}</router-link>
        |
        <router-link :to="{ name: 'Imprint' }">{{ $t('footer.imprint') }}</router-link>
      </template>
    </div>
  </v-footer>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'

const MainFooterProps = Vue.extend({
  props: {
    mobile: { type: Boolean, default: false }
  }
})

@Component
export default class MainFooter extends MainFooterProps {
}
</script>

<style scoped>

</style>
