<template>
  <v-container>
    <LoadingCard v-if="loading" flat/>
    <template v-else>
      <v-card v-if="!selectedCourseId" flat>
        <v-card-title>
          <v-tooltip :open-delay="500" top>
            <template v-slot:activator="{ on }">
              <v-btn icon
                     @click="backToCoursesView"
                     :aria-label="$t('tooltips.backToCourses')"
                     v-on="on">
                <v-icon>mdi-arrow-left-circle</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('tooltips.backToCourses') }}</span>
          </v-tooltip>
          {{ $t('results.title') }}
        </v-card-title>
        <v-divider/>
        <v-card-text>
          {{ $t('results.description') }}
        </v-card-text>
      </v-card>
      <TestResultTable v-else-if="publishedCourseTests.length && !selectedCourseTestId"
                       :selectedCourseId="selectedCourseId"
                       :courses="courses"
                       :pupils="pupils"/>
      <v-card v-else-if="selectedCourseTest" flat>
        <v-card-title>
          <v-row>
            <v-col class="d-flex shrink">
              <v-tooltip :open-delay="500" top>
                <template v-slot:activator="{ on }">
                  <v-btn icon
                         @click="backToCoursesView"
                         :aria-label="$t('tooltips.backToCourses')"
                         v-on="on">
                    <v-icon>mdi-arrow-left-circle</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('tooltips.backToCourses') }}</span>
              </v-tooltip>
            </v-col>
            <v-col class="d-flex align-center">
              <h3>{{ getCurrentLangText(selectedCourseTest.name) }} ({{ selectedCourseTest.variant }})</h3>
            </v-col>
            <v-col cols="2" class="d-flex justify-end">
              <CourseTestToolbar :courseTest="selectedCourseTest"
                                 :selectedCourse="selectedCourse"
                                 @onEdited="getCourseTests()"
                                 @onTogglePublishTest="getCourseTests()"/>
            </v-col>
          </v-row>
        </v-card-title>
        <LoadingCard v-if="explanationLoading"/>
        <template v-else>
          <v-card-subtitle class="mt-2">
            <div v-if="!user.simplifiedView && resultsExplanation" v-html="resultsExplanation.introduction"></div>
          </v-card-subtitle>
          <template v-if="!user.simplifiedView">
            <template v-for="(stageData, index) in Object.entries(stageGroupData)">
              <v-card-subtitle v-if="stageData[0] !== 'default'" :key="index + 'title'">
                {{ $t('stages.' + stageData[0]) }}
              </v-card-subtitle>
              <v-card-text :key="index + 'data'">
                <v-data-table
                  calculate-widths
                  disable-sort
                  hide-default-footer
                  show-expand
                  item-key="id"
                  :expanded.sync="expandedStages[index]"
                  :headers="stageHeaders"
                  :items="stageData[1]"
                  :loading="loading">
                  <template v-slot:item.stage="{ item }">
                    <v-chip label :color="stageColor">
                      {{ item.name }}
                    </v-chip>
                  </template>
                  <template v-slot:item.stageDetails="{ item }">
                    <div v-html="item.description"></div>
                  </template>
                  <template v-slot:item.distribution="{ item }">
                    {{ item.pupilNames.join(', ') }}
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <v-tabs>
                        <v-tab>{{ $t('results.details') }}</v-tab>
                        <v-tab>{{ $t('results.teachingSuggestions') }}</v-tab>
                        <v-tab-item class="mt-3">
                          <template v-if="item.details">
                            <div v-html="item.details"></div>
                          </template>
                        </v-tab-item>
                        <v-tab-item class="mt-3">
                          <template v-if="item.suggestions">
                            <div v-html="item.suggestions"></div>
                          </template>
                        </v-tab-item>
                      </v-tabs>
                    </td>
                  </template>
                </v-data-table>
              </v-card-text>
            </template>
            <v-card-text>
              <template v-for="(patternData, index) in Object.entries(patternGroupData)">
                <v-card-subtitle v-if="patternData[0] !== 'default'" :key="index + 'title'">
                  {{ $t('pattern.' + patternData[0]) }}
                </v-card-subtitle>
                <v-data-table
                  :key="index + 'data'"
                  disable-sort
                  hide-default-footer
                  show-expand
                  item-key="name"
                  :expanded.sync="expandedPatterns[index]"
                  :headers="patternHeaders"
                  :items="patternData[1]"
                  :loading="loading">
                  <template v-slot:item.pattern="{ item }">
                    <v-chip :color="patternColor">
                      {{ item.name }}
                    </v-chip>
                  </template>
                  <template v-slot:item.patternDetails="{ item }">
                    <div v-html="item.description"></div>
                  </template>
                  <template v-slot:item.distribution="{ item }">
                    {{ item.pupilNames.join(', ') }}
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <v-tabs>
                        <v-tab>{{ $t('results.details') }}</v-tab>
                        <v-tab v-if="item.suggestions">{{ $t('results.teachingSuggestions') }}</v-tab>
                        <v-tab-item class="mt-3">
                          <template v-if="item.details">
                            <div v-html="item.details"></div>
                          </template>
                        </v-tab-item>
                        <v-tab-item>
                          <template v-if="item.suggestions">
                            <div v-html="item.suggestions"></div>
                          </template>
                        </v-tab-item>
                      </v-tabs>
                    </td>
                  </template>
                </v-data-table>
              </template>
            </v-card-text>
          </template>
          <v-card flat>
            <v-card-title>
              <h4>{{ $t('results.courseOverview') }}</h4>
            </v-card-title>
            <v-card-text>
              <v-data-table :headers="resultsHeaders"
                            :items="resultData"
                            :items-per-page="-1"
                            hide-default-footer>
                <template v-slot:item.stages="{ item }">
                  <v-chip v-for="(stage, index) in item.namedStages"
                          :key="index"
                          class="mx-1"
                          :class="namedStageColor"
                          label>
                    {{ $t('stages.' + stage.resultName) }}
                    <v-avatar rounded right :color="stageColor">
                      {{ stage.result }}
                    </v-avatar>
                  </v-chip>
                  <v-chip v-for="(stage, index) in item.stages"
                          :key="index"
                          class="mx-1"
                          label
                          :color="stageColor">
                    {{ stage }}
                  </v-chip>
                </template>
                <template v-slot:item.pattern="{ item }">
                  <v-sheet v-for="(patternGroup, index) in item.namedPattern"
                           class="pa-1 my-1"
                           :key="index"
                           rounded
                           :color="namedPatternColor">
                    {{ $t('pattern.' + patternGroup.resultName) }}
                    <v-chip v-for="(pattern, iindex) in patternGroup.result"
                            class="mx-1"
                            :key="index + '-' + iindex"
                            :color="patternColor">{{ pattern }}</v-chip>
                  </v-sheet>
                  <v-chip v-for="(patternItem, index) in item.pattern"
                          :key="index"
                          class="mx-1"
                          :color="patternColor">
                    {{ patternItem }}
                  </v-chip>
                </template>
                <template v-slot:item.handedIn="{ item }">
                  {{ formatDateObject(item.handedIn) }}
                </template>
                <template v-slot:item.inputs="{ item }">
                  <v-tooltip v-if="hasResults(item.pupil)" :open-delay="500" top>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on" @click="showSolution(item.pupil)" :aria-label="$t('results.showAnswers')">
                        <v-icon>
                          mdi-file-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('results.showAnswers') }}</span>
                  </v-tooltip>
                </template>
                <template v-slot:item.warnings="{ item }">
                  <v-tooltip v-if="!filledAllInputs(item.pupil)" :open-delay="500" top>
                    <template v-slot:activator="{ on }">
                      <v-icon color="warning" v-on="on" :aria-label="$t('results.inputWarningNotAllAnswered')">
                        mdi-alert-outline
                      </v-icon>
                    </template>
                    <span>{{ $t('results.inputWarningNotAllAnswered') }}</span>
                  </v-tooltip>
                  <v-tooltip v-if="lowStageHighScore(item.pupil)" :open-delay="500" top>
                    <template v-slot:activator="{ on }">
                      <v-icon color="warning" v-on="on" :aria-label="$t('results.inputWarningLowStageHighScore')">
                        mdi-alert-outline
                      </v-icon>
                    </template>
                    <span>{{ $t('results.inputWarningLowStageHighScore') }}</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          <v-card flat v-if="resultsExplanation && resultsExplanation.references && !user.simplifiedView">
            <v-card-title>
              <h4>{{ $t('results.references') }}</h4>
            </v-card-title>
            <v-card-text class="mb-5">
              <div v-html="resultsExplanation.references"></div>
            </v-card-text>
          </v-card>
        </template>
      </v-card>
      <v-card v-else flat>
        <v-card-title>
          <v-row>
            <v-col class="d-flex shrink">
              <v-tooltip :open-delay="500" top>
                <template v-slot:activator="{ on }">
                  <v-btn icon
                         @click="backToCoursesView"
                         :aria-label="$t('tooltips.backToCourses')"
                         v-on="on">
                    <v-icon>mdi-arrow-left-circle</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('tooltips.backToCourses') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <p class="d-flex justify-center text-h5 text--disabled mt-10">
            {{ $t('results.noResults') }}
          </p>
        </v-card-text>
      </v-card>
    </template>
    <AnswerDialog v-if="selectedPupil"
                    :showSolution="true"
                    :pupil="selectedPupil"
                    :show.sync="showAnswerDialog"
                    :courseTest="selectedCourseTest"/>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import LoadingCard from '@/components/LoadingCard.vue'
import {
  Course,
  CourseTest,
  Page,
  PageParams,
  Pupil,
  ResultInfo,
  ResultsExplanation,
  TypeInfo,
  User
} from '@/types/Types'
import { CourseTestService } from '@/services/CourseTestService'
import { AxiosResponse } from 'axios'
import { LangUtils } from '@/utils/LangUtils'
import { DateTime } from 'luxon'
import { PupilService } from '@/services/PupilService'
import CourseTestToolbar from '@/components/CourseTestToolbar.vue'
import { TestUtils } from '@/utils/TestUtils'
import { ResultUtils } from '@/utils/ResultUtils'
import { ResultTypes } from '@/types/Enums'
import TestDataDialog from '@/components/TestDataDialog.vue'
import TestResultTable from '@/components/TestResultTable.vue'
import AnswerDialog from '@/components/AnswerDialog.vue'

@Component({
  components: { AnswerDialog, TestResultTable, TestDataDialog, CourseTestToolbar, LoadingCard }
})
export default class Results extends Vue {
  loading = true
  explanationLoading = true
  resultsExplanation: ResultsExplanation | null = null
  selectedPupil: Pupil | null = null
  publishedCourseTests: Array<CourseTest> = []
  pupils: Array<Pupil> = []
  totalItems = 0
  expandedStages: Array<Array<boolean>> = [[]]
  expandedPatterns: Array<Array<boolean>> = [[]]

  stageColor = ResultUtils.stageColor
  namedStageColor = ResultUtils.namedStageColor
  patternColor = ResultUtils.patternColor
  namedPatternColor = ResultUtils.namedPatternColor
  inProgressColor = ResultUtils.inProgressColor
  stage = ResultTypes.Stage
  pattern = ResultTypes.Pattern
  inProgress = ResultTypes.InProgress

  showAnswerDialog = false

  get user (): User {
    return this.$store.getters['auth/user']
  }

  get selectedCourseId (): string {
    return this.$store.state.test.selectedCourseId
  }

  set selectedCourseId (courseId: string) {
    this.$store.commit('test/setSelectedCourseId', courseId)
  }

  get courses (): Array<Course> {
    return this.$store.state.test.courses
  }

  get selectedCourseTestId () {
    return this.$route.params.courseTestId
  }

  get stageHeaders () {
    return [
      { text: this.$t('results.stage'), value: 'stage', width: '80px' },
      { text: '', value: 'stageDetails', width: '40%' },
      { text: this.$t('results.distribution'), value: 'distribution' },
      { text: '', value: 'data-table-expand' }
    ]
  }

  get patternHeaders () {
    return [
      { text: this.$t('results.pattern'), value: 'pattern', width: '80px' },
      { text: '', value: 'patternDetails', width: '40%' },
      { text: this.$t('results.distribution'), value: 'distribution' },
      { text: '', value: 'data-table-expand' }
    ]
  }

  get resultsHeaders () {
    if (this.user.simplifiedView) {
      return [
        { text: this.$t('results.name'), value: 'name' },
        { text: this.$t('results.correctAnswersAmount'), value: 'correctAnswersAmount' },
        { text: this.$t('results.handedIn'), value: 'handedIn' },
        { text: '', value: 'inputs', sortable: false, align: 'end' },
        { text: '', value: 'warnings', sortable: false, align: 'start' }
      ]
    }

    return [
      { text: this.$t('results.name'), value: 'name' },
      { text: this.$t('results.stage'), value: 'stages', sortable: false },
      { text: this.$t('results.pattern'), value: 'pattern', sortable: false },
      { text: this.$t('results.handedIn'), value: 'handedIn' },
      { text: '', value: 'inputs', sortable: false, align: 'end' },
      { text: '', value: 'warnings', sortable: false, align: 'start' }
    ]
  }

  get resultData () {
    const resultList = []

    if (this.selectedCourseTestId && this.selectedCourseTest) {
      const totalAmount = ResultUtils.getTotalAnswersAmount(this.selectedCourseTest)

      for (const pupil of this.pupils) {
        const resultData = this.getStageAndPatternForCourseTestId(this.selectedCourseTestId, pupil)

        resultList.push({
          name: pupil.name,
          correctAnswersAmount: ResultUtils.getCorrectAnswersAmount(this.selectedCourseTest, pupil) + ' / ' + totalAmount,
          stages: resultData.filter(item => item.type === ResultTypes.Stage).map(item => item.result),
          namedStages: resultData.filter(item => item.type === ResultTypes.NamedStage),
          pattern: resultData.filter(item => item.type === ResultTypes.Pattern).map(item => item.result),
          namedPattern: resultData.filter(item => item.type === ResultTypes.NamedPattern),
          handedIn: ResultUtils.getHandInDateForCourseTestId(this.selectedCourseTestId, pupil),
          pupil: pupil
        })
      }
    }

    return resultList
  }

  get selectedCourse (): Course | null {
    if (this.selectedCourseId !== null) {
      return this.courses.find(course => course._id === this.selectedCourseId) || null
    } else {
      return null
    }
  }

  get selectedCourseTest (): CourseTest | null {
    if (this.selectedCourseTestId !== null) {
      return this.publishedCourseTests.find(courseTest => courseTest._id === this.selectedCourseTestId) || null
    } else {
      return null
    }
  }

  get language (): string {
    return this.$i18n.locale
  }

  get selectedLanguage (): string {
    return this.$store.state.language.currentLanguage
  }

  get mobile (): boolean {
    return this.$vuetify.breakpoint.mobile
  }

  get showDrawer (): boolean {
    return this.$store.state.showDrawer
  }

  set showDrawer (value: boolean) {
    this.$store.commit('setShowDrawer', value)
  }

  get patternGroupData (): Record<string, Array<TypeInfo>> {
    return ResultUtils.getPatternGroupData(this.resultsExplanation?.patterns)
  }

  get stageGroupData (): Record<string, Array<TypeInfo>> {
    return ResultUtils.getStageGroupData(this.resultsExplanation?.stages)
  }

  backToCoursesView (): void {
    this.$router.push({ name: 'Courses' }).catch(error => {
      if (error.name !== 'NavigationDuplicated') {
        throw error
      }
    })
  }

  getTypeColor (resultType: string): string {
    return ResultUtils.getTypeColor(resultType)
  }

  hasResults (pupil: Pupil): boolean {
    if (this.selectedCourseTestId) {
      const results = ResultUtils.getResultForCourseTestId(this.selectedCourseTestId, pupil)

      return !!results && Object.entries(results).length > 0
    } else {
      return false
    }
  }

  filledAllInputs (pupil: Pupil): boolean {
    if (this.selectedCourseTestId) {
      const inputs = ResultUtils.getInputForCourseTestId(this.selectedCourseTestId, pupil)

      if (inputs) {
        // TODO: Implement this in a general way, this is a hotfix for the study!
        //  filter down and remove the following items from the list (if available)
        const remove = [
          'klassenstufe',
          'zeugnisnote',
          'sprache',
          'geschlecht',
          'resp10001_2',
          'resp10002_2',
          'resp10003_1',
          'resp10004_1',
          'resp10005_1',
          'resp10005_2',
          'resp10005_3',
          'resp10005_4',
          'resp10005_5',
          'resp10006_1',
          'resp10007_1',
          'resp10008_1',
          'resp10008_2',
          'resp10008_3',
          'resp10008_4',
          'resp10008_5',
          'resp81_2',
          'resp82_2',
          'resp85_2',
          'resp86_2'
        ]

        const filtered = Object.keys(inputs).filter(key => !remove.includes(key))
          .reduce((object: Record<string, string>, key) => {
            object[key] = inputs[key]
            return object
          }, {})

        // compare non-empty entries vs. values
        return Object.values(filtered).filter(Boolean).length === Object.entries(filtered).filter(Boolean).length
      } else {
        return false
      }
    } else {
      return false
    }
  }

  lowStageHighScore (pupil: Pupil): boolean {
    const result = ResultUtils.getResultForCourseTestId(this.selectedCourseTestId, pupil)

    return !this.user.simplifiedView && !!result && +result.stage === 0 && +result.diagnosisallcorrect >= 80
  }

  showSolution (pupil: Pupil): void {
    if (this.selectedCourseTestId) {
      this.selectedPupil = pupil
      this.showAnswerDialog = true
    }
  }

  getStageAndPatternForCourseTestId (courseTestId: string, pupil: Pupil): Array<ResultInfo> {
    return ResultUtils.getStageAndPatternForTestId(courseTestId, pupil)
  }

  getFormattedHandInDateForCourseTestId (courseTestId: string, pupil: Pupil): string {
    const createdDate = ResultUtils.getHandInDateForCourseTestId(courseTestId, pupil)
    if (createdDate) {
      return createdDate.setLocale(this.language).toLocaleString(DateTime.DATETIME_SHORT)
    } else {
      return ''
    }
  }

  formatDateObject (date: DateTime): string {
    if (date) {
      return date.setLocale(this.language).toLocaleString(DateTime.DATETIME_SHORT)
    } else {
      return ''
    }
  }

  formatDate (date: string): string {
    return DateTime.fromISO(date).setLocale(this.language).toLocaleString(DateTime.DATETIME_SHORT)
  }

  getCurrentLangText (text: Record<string, string>): string {
    return LangUtils.getLangOrFallback(text, this.language)
  }

  getCourseTests (): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.selectedCourseId) {
        this.loading = true
        this.publishedCourseTests = []

        const pageParams: PageParams = {
          limit: 0
        }

        CourseTestService.getTestsForCourse(this.selectedCourseId, pageParams, true).then((results: AxiosResponse<Page<CourseTest>>) => {
          this.publishedCourseTests = results.data.results

          resolve()
        }).catch((error) => {
          this.$log.debug('Could not get course list ', error)

          this.$store.dispatch('notifications/showError', {
            text: this.$t('notifications.error.getCourseTestList').toString()
          })

          reject(error)
        }).finally(() => {
          this.loading = false
        })
      } else {
        this.loading = false
        resolve()
      }
    })
  }

  getPupilsForCourse (): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.selectedCourseId) {
        this.loading = true

        // For now, we'll simply pull everything, this may need to be changed if the data grows very large
        const pageParams: PageParams = {
          limit: 0
        }

        PupilService.getPupilsByCourse(this.selectedCourseId, pageParams).then((results) => {
          this.pupils = results.data.results

          resolve()
        }).catch((error) => {
          this.$log.debug('Could not get pupil list ', error)

          this.$store.dispatch('notifications/showError', {
            text: this.$t('notifications.error.getPupilList').toString()
          })

          reject(error)
        }).finally(() => {
          this.loading = false
        })
      } else {
        this.loading = false
        resolve()
      }
    })
  }

  getTestData (): void {
    const promises: Array<Promise<void>> = []

    promises.push(this.getCourseTests())
    promises.push(this.getPupilsForCourse())

    Promise.allSettled(promises).then(() => {
      if (this.selectedCourseTest?.testVersion && this.selectedCourseTest.machineName) {
        this.explanationLoading = true
        TestUtils.parseExplanationHTMLForTest(this.selectedCourseTest.testVersion,
          this.selectedCourseTest.machineName,
          this.selectedLanguage,
          this.pupils,
          this.selectedCourseTestId).then((result) => {
          this.resultsExplanation = result
          this.explanationLoading = false
        })
      }
    })
  }

  mounted (): void {
    if (!this.selectedCourseTestId) {
      this.$router.push({ name: 'Courses' })
    }

    if (!this.selectedCourseId) {
      CourseTestService.getCourseTest(this.selectedCourseTestId, false).then((result: AxiosResponse<CourseTest>) => {
        if (result.data.courseId) {
          this.selectedCourseId = result.data.courseId
          this.getTestData()
        }
      })
    } else {
      this.getTestData()
    }
  }
}
</script>
