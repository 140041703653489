<template>
  <v-container>
    <i18n path="header.verification" tag="label">
      <template v-slot:verify>
        <a @click="requestVerification">
          {{ $t('register.success.verify') }}
        </a>
      </template>
    </i18n>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import i18n from '@/plugins/i18n'

@Component
export default class Verification extends Vue {
  get userNotVerified (): boolean {
    if (this.$store.getters['auth/user']) {
      return !this.$store.getters['auth/user'].is_verified
    } else {
      return true
    }
  }

  requestVerification (): void {
    this.$store.dispatch('auth/requestVerificationForCurrentUser').then(() => {
      this.$store.dispatch('notifications/showWarning', {
        text: i18n.t('notifications.emailSent').toString()
      })
    })
  }

  forwardToDashboard (): void {
    this.$router.push({ name: 'Dashboard' }).catch(error => {
      if (error.name !== 'NavigationDuplicated') {
        throw error
      }
    })
  }

  mounted (): void {
    // forward if already logged in, we may need to "refresh" the token here to be sure?
    if (!this.$store.getters['auth/isAuthenticated'] || !this.userNotVerified) {
      // this.forwardToDashboard()
    }
  }
}
</script>

<style scoped>

</style>
