import * as XLSX from 'xlsx'
import i18n from '@/plugins/i18n'
import { WorkSheet } from 'xlsx'
import { Pupil } from '@/types/Types'

export const ExcelUtils = {
  generateExcelTemplate (): void {
    const data = [
      ['Vorname', 'Nachname'],
      ['Paul', 'Schneider'],
      ['Luca', 'Maier'],
      ['Lara', 'Schubert']
    ]

    this.generateExcel(i18n.t('util.excel.pupilTemplateName').toString() + '.xlsx', data, 'Klasse')
  },

  generatePupilExcelExport (pupilList: Array<Pupil>): void {
    let data = [
      ['Zugangsname']
    ]

    data = data.concat(pupilList.map(pupil => {
      return [
        pupil.name
      ]
    }))

    const filename = i18n.t('util.excel.pupilExportName').toString() + '.xlsx'

    this.generateExcel(filename, data, 'Zugänge')
  },

  autoWidthWs (data: any, maxWidthFirst: number): WorkSheet {
    const objectMaxLength: any = []

    // automatically adjust width of columns
    // find max width for columns
    data.forEach((arr: any) => {
      arr.forEach((value: any, key: any) => {
        if (value) {
          let len = 0
          switch (typeof value) {
            case 'number': len = 10; break
            case 'string': len = value.length; break
            case 'object': if (value instanceof Date) len = 10; break
          }

          objectMaxLength[key] = Math.max(objectMaxLength[key] ? objectMaxLength[key] : 0, len)
        }
      })
    })

    // We'll override the length for the first column manually, because this can get very long
    if (objectMaxLength[0] > maxWidthFirst) {
      objectMaxLength[0] = maxWidthFirst
    }

    const worksheetCols = objectMaxLength.map((width: any) => {
      return {
        width
      }
    })

    // create sheet and set calculated widths
    const ws = XLSX.utils.aoa_to_sheet(data)
    ws['!cols'] = worksheetCols
    return ws
  },

  generateExcel (filename: string, data: any, workSheetName: string): void {
    const wb = XLSX.utils.book_new()
    const ws = this.autoWidthWs(data, 60)
    XLSX.utils.book_append_sheet(wb, ws, workSheetName)
    XLSX.writeFile(wb, filename)
  },

  capitalizeFirstLetter (string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
}
