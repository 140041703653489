<template>
  <v-app-bar app clipped-left elevation="0" extension-height="80">
    <template v-slot:extension v-if="!mobile && teacherLoggedIn && !userNotVerified && !userNotInitialized">
      <v-tabs v-if="!mobile && teacherLoggedIn && !userNotVerified" v-model="menuTab" dark icons-and-text>
        <v-tab @click="changeRoute('Courses')">
          {{ $t('header.courses') }}
          <v-icon large>mdi-account-multiple</v-icon>
        </v-tab>
        <v-tab @click="changeRoute('Library')">
          {{ $t('header.library') }}
          <v-icon large>mdi-clipboard-list-outline</v-icon>
        </v-tab>
        <v-tab v-if="false" @click="changeRoute('Tutorials')">
          {{ $t('header.tutorial') }}
          <v-icon large>mdi-help-circle-outline</v-icon>
        </v-tab>
      </v-tabs>
    </template>
    <v-toolbar-title class="clickable d-flex align-center">
      <v-app-bar-nav-icon v-if="mobile && teacherLoggedIn && !userNotVerified && !userNotInitialized" @click.stop="toggleDrawer"></v-app-bar-nav-icon>
      <v-img src="../assets/img/logo.png"
             contain
             max-width="320px"
             max-height="50px"
             @click="goToHome"/>
    </v-toolbar-title>

    <v-row no-gutters class="d-flex justify-end">
      <v-col v-if="false" class="shrink">
        <v-menu left bottom offset-y :close-on-content-click="false" v-model="langMenuOpen">
          <template v-slot:activator="{ on: menu }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip, attrs }">
                <v-btn icon v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                  <v-icon>mdi-translate</v-icon>
                </v-btn>
              </template>
              <span>{{ $t('tooltips.languageSelection') }}</span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item>
              <v-toolbar-items class="d-flex justify-center align-center">
                <v-select outlined label="Language" hide-details
                          :item-text="item => $t(item.nameKey)"
                          item-value="value"
                          v-model="selectedLanguage"
                          :items="languages" />
              </v-toolbar-items>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col class="shrink">
        <v-tooltip v-if="!teacherLoggedIn && !pupilLoggedIn" bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="d-inline-block">
              <v-btn :aria-label="$t('tooltips.login')"
                     icon
                     @click="login">
                <v-icon large>mdi-account-circle</v-icon>
              </v-btn>
            </div>
          </template>
          <span>{{ $t('tooltips.login') }}</span>
        </v-tooltip>
        <template v-else>
          <v-menu bottom offset-y :close-on-content-click="false" v-model="loggedInMenuOpen">
            <template v-slot:activator="{ on: menu }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on: tooltip, attrs }">
                  <div v-bind="attrs" v-on="{ ...tooltip, ...menu }" class="d-inline-block">
                    <v-btn icon>
                      <v-icon large>mdi-account-circle</v-icon>
                    </v-btn>
                  </div>
                </template>
                <span>{{ $t('tooltips.account') }}</span>
              </v-tooltip>
            </template>
            <v-list dense>
              <v-list-item v-if="false" @click="changeRoute('Profile')">
                <v-list-item-icon class="mr-2">
                  <v-icon>mdi-account-edit</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('global.profile') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="logout">
                <v-list-item-icon class="mr-2">
                  <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('global.logout') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import Vue from 'vue'
import { Category, Translation } from '@/types/Types'
import { Options } from '@/types/Options'
import i18n from '@/plugins/i18n'
import { Watch } from 'vue-property-decorator'
import { UserService } from '@/services/UserService'

const HeaderBarProps = Vue.extend({
  props: {
    //
  }
})

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
])

@Component
export default class HeaderBar extends HeaderBarProps {
  langMenuOpen = false
  loggedInMenuOpen = false

  get selectedLanguage (): string {
    return this.$store.state.language.currentLanguage
  }

  set selectedLanguage (value: string) {
    this.$store.commit('language/setCurrentLanguage', value)
  }

  get menuTab (): number | null {
    return this.$store.state.selectedMenuItem
  }

  set menuTab (value: number | null) {
    this.$store.commit('setSelectedMenuItem', value)
  }

  get mobile (): boolean {
    return this.$vuetify.breakpoint.mobile
  }

  get teacherLoggedIn (): boolean {
    return this.$store.getters['auth/isAuthenticated']
  }

  get pupilLoggedIn (): boolean {
    return this.$store.getters['auth/isPupilAuthenticated']
  }

  get languages (): Array<Translation> {
    return Options.languages
  }

  get categories (): Array<Category> {
    return this.$store.state.library.categories
  }

  get currentUserName (): string {
    if (this.$store.getters['auth/user']) {
      return this.$store.getters['auth/user'].username
    }

    return ''
  }

  get showDrawer (): boolean {
    return this.$store.state.showDrawer
  }

  set showDrawer (value: boolean) {
    this.$store.commit('setShowDrawer', value)
  }

  get userNotVerified (): boolean {
    if (this.$store.getters['auth/user']) {
      return !this.$store.getters['auth/user'].is_verified
    } else {
      return true
    }
  }

  get userNotInitialized (): boolean {
    return UserService.userNotInitialized()
  }

  @Watch('selectedLanguage')
  onLanguageChange (value: string): void {
    i18n.locale = value
  }

  goToHome (): void {
    if (this.userNotVerified) {
      if (this.$route.name === 'Login') {
        this.changeRoute('PupilDashboard')
      } else {
        return
      }
    }

    if (this.teacherLoggedIn) {
      this.changeRoute('Tests')
    } else {
      this.changeRoute('PupilDashboard')
    }
  }

  toggleDrawer (): void {
    this.showDrawer = !this.showDrawer
  }

  changeRoute (routeName: string, params?: any): void {
    this.$router.push({ name: routeName }).catch(error => {
      if (error.name !== 'NavigationDuplicated') {
        throw error
      }
    })
  }

  login (): void {
    this.changeRoute('Login')
  }

  logout (): void {
    // this will automatically redirect to public
    this.$store.dispatch('auth/logout')
  }

  toDashboard (): void {
    this.$router.push({ name: 'Dashboard' }).catch(error => {
      if (error.name !== 'NavigationDuplicated') {
        throw error
      }
    })
  }

  mounted (): void {
    //
  }
}
</script>

<style scoped>
  .v-tabs {
    width: 80%
  }
</style>
