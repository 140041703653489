<template>
  <v-container>
    Terms
  </v-container>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import Vue from 'vue'

@Component
export default class Terms extends Vue {
}
</script>

<style scoped>

</style>
