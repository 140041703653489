<template>
  <v-dialog v-model="dialog" :width="mobile ? '100%' : '60%'" @click:outside="closeDialog">
    <v-card class="pt-5">
      <v-card-title>
        <v-row>
          <v-col cols="11">
            <h2>{{ getCurrentLangText(test.name) }} ({{ test.variant }})</h2>
          </v-col>
          <v-col cols="1" class="d-flex justify-end">
            <v-btn icon @click="closeDialog">
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-simple-table height="300px">
          <template v-slot:default>
            <tbody>
            <tr>
              <td>{{ $t('testInfoDialog.author') }}</td>
              <td>{{ test.author }}</td>
            </tr>
            <tr>
              <td>{{ $t('testInfoDialog.license') }}</td>
              <td>{{ test.license }}</td>
            </tr>
            <tr>
              <td>{{ $t('testInfoDialog.licenseVersion') }}</td>
              <td>{{ test.licenseVersion }}</td>
            </tr>
            <tr>
              <td>{{ $t('testInfoDialog.changelog') }}</td>
              <td class="respect-linebreaks">{{ changeLog }}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import Vue from 'vue'
import { Test, Version } from '@/types/Types'
import { LangUtils } from '@/utils/LangUtils'
import { TestUtils } from '@/utils/TestUtils'

const TestInfoDialogProps = Vue.extend({
  props: {
    showDialog: { type: Boolean, required: true },
    test: { type: Object as () => Test, required: true }
  }
})

@Component
export default class TestInfoDialog extends TestInfoDialogProps {
  get dialog (): boolean {
    return this.showDialog
  }

  set dialog (value: boolean) {
    this.$emit('update:showDialog', value)
  }

  get mobile (): boolean {
    return this.$vuetify.breakpoint.mobile
  }

  get language (): string {
    return this.$i18n.locale
  }

  get changeLog (): string {
    let changeLogString = ''

    for (let i = this.test.versions.length - 1; i >= 0; i--) {
      if (i < this.test.versions.length - 1) {
        changeLogString += '\n\n'
      }

      const version = this.test.versions[i]

      changeLogString += this.getVersionString(version) + '\n'
      if (version.changelog) {
        changeLogString += this.getCurrentLangText(version.changelog)
      }
    }

    return changeLogString
  }

  getVersionString (version: Version, underScore = false): string {
    return TestUtils.getVersionString(version, underScore)
  }

  getCurrentLangText (text: Record<string, string>): string {
    return LangUtils.getLangOrFallback(text, this.language)
  }

  closeDialog (): void {
    this.dialog = false
    this.$emit('onClose')
  }
}
</script>

<style scoped>

</style>
