<template>
  <v-sheet color="transparent">
    <v-tooltip :open-delay="500" top>
      <template v-slot:activator="{ on }">
        <v-btn icon :aria-label="courseTest.published ? $t('tooltips.unpublish') : $t('tooltips.publish')" :loading="saving" v-on="on" @click="togglePublishTest(courseTest)">
          <v-icon>
            {{ getPublishIcon(courseTest) }}
          </v-icon>
        </v-btn>
      </template>
      <span>{{ courseTest.published ? $t('tooltips.publish') : $t('tooltips.unpublish') }}</span>
    </v-tooltip>
    <v-tooltip :open-delay="500" top>
      <template v-slot:activator="{ on }">
        <v-btn icon :aria-label="$t('tooltips.showData')" @click="showTestData(courseTest)" v-on="on">
          <v-icon>
            mdi-information-outline
          </v-icon>
        </v-btn>
      </template>
      <span>{{ $t('tooltips.showData') }}</span>
    </v-tooltip>
    <v-tooltip v-if="showDelete && !user.disableDelete" :open-delay="500" top>
      <template v-slot:activator="{ on }">
        <v-btn :aria-label="$t('tooltips.delete')" icon @click="openDeleteTestDialog(courseTest)" v-on="on">
          <v-icon>
            mdi-delete-outline
          </v-icon>
        </v-btn>
      </template>
      <span>{{ $t('tooltips.delete') }}</span>
    </v-tooltip>
    <TestDataDialog v-if="selectedCourseTest"
                  :show.sync="showPlayerDialog"
                  :courseTest="selectedCourseTest"/>
    <SecureDeleteDialog v-if="selectedCourseTest"
                        :showDialog.sync="showDeleteTestDialog"
                        :title="$t('tests.deleteTest.title')"
                        :text="$t('tests.deleteTest.text')"
                        :deletePhrase="getCurrentLangText(selectedCourseTest.name)"
                        :isLoading="saving"
                        @onDelete="deleteTest" />
  </v-sheet>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Course, CourseTest, CourseTestUpdate, User } from '@/types/Types'
import { CourseTestService } from '@/services/CourseTestService'
import TestDataDialog from '@/components/TestDataDialog.vue'
import SecureDeleteDialog from '@/components/SecureDeleteDialog.vue'
import { LangUtils } from '@/utils/LangUtils'

const CourseTestToolbarProps = Vue.extend({
  props: {
    courseTest: { type: Object as () => CourseTest, required: true },
    selectedCourse: { type: Object as () => Course },
    showDelete: { type: Boolean, default: false }
  }
})
@Component({
  components: { SecureDeleteDialog, TestDataDialog }
})
export default class CourseTestToolbar extends CourseTestToolbarProps {
  saving = false
  selectedCourseTest: CourseTest | null = null
  showPlayerDialog = false
  showDeleteTestDialog = false

  get user (): User {
    return this.$store.getters['auth/user']
  }

  get language (): string {
    return this.$i18n.locale
  }

  getCurrentLangText (text: Record<string, string>): string {
    return LangUtils.getLangOrFallback(text, this.language)
  }

  onEdited (): void {
    this.$emit('onEdited')
  }

  deleteTest (): void {
    if (this.selectedCourseTest && this.selectedCourseTest._id) {
      CourseTestService.deleteCourseTest(this.selectedCourseTest._id).then(() => {
        this.$store.dispatch('notifications/showSuccess', {
          text: this.$t('notifications.success.deleteCourseTest').toString()
        })

        this.$emit('onDeleted')
      }).catch((error) => {
        this.$log.debug('could not delete test in course', error)

        this.$store.dispatch('notifications/showError', {
          text: this.$t('notifications.error.deleteCourseTest').toString()
        })
      })
    }
  }

  togglePublishTest (test: CourseTest): void {
    if (test && test._id) {
      this.saving = true

      const data: CourseTestUpdate = {
        published: !test.published
      }

      CourseTestService.updateTestForCourse(test._id, data).then(() => {
        this.$store.dispatch('notifications/showSuccess', {
          text: this.$t('notifications.success.editCourseTest').toString()
        })

        this.$emit('onTogglePublishTest')
      }).catch((error) => {
        this.$log.debug('could not edit test in course', error)

        this.$store.dispatch('notifications/showError', {
          text: this.$t('notifications.error.editCourseTest').toString()
        })
      }).finally(() => {
        this.saving = false
      })
    }
  }

  showTestData (test: CourseTest): void {
    this.selectedCourseTest = test
    this.showPlayerDialog = true
  }

  openDeleteTestDialog (test: CourseTest): void {
    this.selectedCourseTest = test
    this.showDeleteTestDialog = true
  }

  getPublishIcon (test: CourseTest): string {
    if (test.published) {
      return 'mdi-eye-outline'
    } else {
      return 'mdi-eye-off-outline'
    }
  }
}
</script>

<style scoped>

</style>
