<template>
  <LoadingCard v-if="isLoading" flat />
  <v-container v-else fluid>
    <v-container v-if="courseTestList.length">
      <v-row>
        <v-col>
          <h2 class="dashboard">{{ $t('pupilDashboard.welcomeHeadline') }}</h2>
          <p v-html="$t('pupilDashboard.welcome')" class="mt-5"></p>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-start shrink" v-for="courseTest in courseTestList" :key="courseTest._id">
          <CourseTestCard :courseTest="courseTest" />
        </v-col>
      </v-row>
    </v-container>
    <v-alert v-else type="info" color="primary" class="d-flex mt-10" outlined>
      {{ $t('pupilDashboard.noItems') }}
    </v-alert>
    <v-pagination v-if="!isLoading && pageCount > 1"
                  v-model="currentPage"
                  :length="pageCount"
                  total-visible="10" />
  </v-container>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import Vue from 'vue'
import { CourseTestService } from '@/services/CourseTestService'
import { CourseTest, Page, PageParams } from '@/types/Types'
import { AxiosResponse } from 'axios'
import { Watch } from 'vue-property-decorator'
import CourseTestCard from '@/components/CourseTestCard.vue'
import LoadingCard from '@/components/LoadingCard.vue'

@Component({
  components: { LoadingCard, CourseTestCard: CourseTestCard }
})
export default class PupilDashboard extends Vue {
  isLoading = true
  pageCount = 0
  currentPage = 1
  courseTestList: Array<CourseTest> = []

  @Watch('currentPage')
  onPageChanged (): void {
    this.getCourseTests()
  }

  get pupilName (): string | undefined {
    return this.$store.getters['auth/pupilName']
  }

  get pupilId (): string | undefined {
    return this.$store.getters['auth/pupilId']
  }

  getCourseTests (): void {
    this.isLoading = true

    const pageParams: PageParams = {
      page: (this.currentPage - 1)
    }

    if (this.pupilId !== undefined) {
      CourseTestService.getTestsForPupil(this.pupilId, pageParams).then((result: AxiosResponse<Page<CourseTest>>) => {
        this.courseTestList = result.data.results
      }).catch((error) => {
        this.$log.debug('could not get course test', error)

        this.$store.dispatch('notifications/showError', {
          text: this.$t('notifications.error.getCourseTest').toString()
        })
      }).finally(() => {
        this.isLoading = false
      })
    } else {
      this.$store.dispatch('auth/logout')
    }
  }

  mounted (): void {
    this.getCourseTests()
  }
}
</script>

<style scoped>

</style>
