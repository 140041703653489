<template><v-container fluid class="my-15">
  <v-layout align-center justify-center>
    <v-card class="pb-5 pt-5 px-8">
      <v-card-title class="mt-n5">
        <v-container justify="center">
          <v-row justify="center">
            {{ $t('pupilLogin.headline') }}
          </v-row>
        </v-container>
      </v-card-title>
      <v-row justify="center">
        <v-alert text :icon="false" dense max-width="300px" dismissible
                 v-model="showFailedLogin" type="error" outlined>
          {{ $t('pupilLogin.failed') }}
        </v-alert>
      </v-row>
      <v-card-text>
        <v-form id="login-form" ref="form">
          <v-text-field prepend-inner-icon="mdi-account"
                        outlined
                        v-model="pupilName"
                        :label="$t('pupilLogin.access')"
                        placeholder=" "
                        :rules="[v => !!v || 'pupilLogin.accessRequired']">
            <template #message="{ message }">
              {{ $t(message) }}
            </template>
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-center">
        <v-btn class="primary" form="login-form"
               :disabled="loginDisabled"
               type="submit"
               :loading="loading"
               @click.stop.prevent="handleLogin">
          {{ this.loginWaitTime > 0 ? $t('pupilLogin.submitTimer', { time: this.loginWaitTime }) : $t('pupilLogin.submit') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-layout>
</v-container>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import Vue from 'vue'
import { VForm } from '@/$refs'

@Component
export default class PupilLogin extends Vue {
  pupilName = ''
  loading = false
  showFailedLogin = false
  runningTimer: number | null = null
  loginWaitTime = 0
  failedRetries = 0

  get form (): VForm {
    return this.$refs.form as VForm
  }

  get loginDisabled (): boolean {
    return this.loginWaitTime > 0
  }

  handleLogin (): void {
    this.form.validate()

    if (this.pupilName) {
      this.loading = true

      // Convert to x-www-form-urlencoded
      const pupil = new URLSearchParams()
      pupil.append('pupilName', this.pupilName)

      this.$store.dispatch('auth/validatePupilName', pupil).then(() => {
        this.forwardToPupilDashboard()
        this.loading = false
      }).catch(error => {
        this.loading = false

        this.startLoginTimer()

        if (error.status === 401) {
          this.showFailedLogin = true
        }
      })
    }
  }

  countDownTimer (): void {
    if (this.loginWaitTime > 0) {
      this.runningTimer = setTimeout(() => {
        this.loginWaitTime -= 1
        this.countDownTimer()
      }, 1000)
    }
  }

  startLoginTimer (): void {
    this.failedRetries++
    this.loginWaitTime = this.failedRetries * 2
    this.countDownTimer()
  }

  forwardToPupilDashboard (): void {
    this.$router.push({ name: 'PupilDashboard' }).catch(error => {
      if (error.name !== 'NavigationDuplicated') {
        throw error
      }
    })
  }

  destroy (): void {
    if (this.runningTimer) {
      clearTimeout(this.runningTimer)
    }
  }

  mounted (): void {
    if (this.$store.getters['auth/isPupilAuthenticated']) {
      this.forwardToPupilDashboard()
    }
  }
}
</script>

<style scoped>

</style>
