<template>
  <v-form id="login-form" ref="form">
    <v-text-field prepend-icon="mdi-lock"
                  v-model="password"
                  :label="$t('register.password')"
                  :rules="passwordRules"
                  @click:append="isPasswordVisible = !isPasswordVisible"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :append-icon="isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'">
      <template #message="{ message }">
        {{ $t(message) }}
      </template>
    </v-text-field>
    <v-text-field prepend-icon="mdi-lock"
                  v-model="passwordRepeat"
                  :label="$t('register.passwordRepeat')"
                  :rules="passwordRepeatRules"
                  @click:append="isPasswordVisible = !isPasswordVisible"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :append-icon="isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'">
      <template #message="{ message }">
        {{ $t(message) }}
      </template>
    </v-text-field>
    <v-row>
      <v-col class="d-flex justify-end">
        <v-btn class="primary" form="login-form" rounded
               type="submit"
               :loading="loading"
               @click.stop.prevent="resetPassword">
          {{ $t('confirmPasswordReset.submit') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import Vue from 'vue'
import { VForm } from '@/$refs'

const ConfirmPasswordResetFormProps = Vue.extend({
  props: {
    loading: { type: Boolean, default: false }
  }
})

@Component
export default class ConfirmPasswordResetForm extends ConfirmPasswordResetFormProps {
  password = ''
  passwordRepeat = ''
  isPasswordVisible = false

  get form (): VForm {
    return this.$refs.form as VForm
  }

  passwordRules = [
    (v: string) => !!v || 'register.passwordRequired'
  ]

  passwordRepeatRules = [
    (v: string) => !!v || 'register.passwordRequired',
    (v: string) => this.matchesPassword(v) || 'register.passwordRepeatWrong'
  ]

  matchesPassword (password: string): boolean {
    return this.password === password
  }

  resetPassword (): void {
    if (this.form.validate() && this.password) {
      this.$emit('resetPassword', this.password)
    }
  }
}
</script>

<style scoped>

</style>
