<template>
  <v-dialog v-model="dialog" persistent :max-width="mobile ? '100%' : '60%'">
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="11">
            {{ $t('editCourseDialog.title') }}
          </v-col>
          <v-col cols="1" class="d-flex justify-end">
            <v-btn icon @click="closeDialog">
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text v-if="course">
        <v-form @submit.prevent="save">
          <v-text-field v-model="course.name"
                        :label="$t('editCourseDialog.name')"
                        outlined>
          </v-text-field>
          <v-row>
            <v-col class="d-flex justify-center">
              <v-btn class="mr-2" :loading="saving" @click="closeDialog">
                {{ $t('editCourseDialog.cancel') }}
              </v-btn>
              <v-btn :loading="saving" color="primary" type="submit">
                {{ $t('editCourseDialog.save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Course } from '@/types/Types'
import { CourseService } from '@/services/CourseService'
import { Watch } from 'vue-property-decorator'

const EditCourseDialogProps = Vue.extend({
  props: {
    courseProp: { type: Object as () => Course, required: true },
    show: { type: Boolean, required: true }
  }
})

@Component
export default class EditCourseDialog extends EditCourseDialogProps {
  saving = false
  course: Course | null = null

  get dialog (): boolean {
    return this.show
  }

  set dialog (value: boolean) {
    this.$emit('update:show', value)
  }

  get mobile (): boolean {
    return this.$vuetify.breakpoint.mobile
  }

  save (): void {
    this.saving = true

    if (this.course?._id) {
      const data = {
        name: this.course.name
      }

      CourseService.updateCourse(this.course._id, data).then(() => {
        this.$store.dispatch('notifications/showSuccess', {
          text: this.$t('notifications.success.courseName').toString()
        })
      }).catch((error) => {
        if (error.status === 400) {
          this.$log.debug('could not edit course', error)

          this.$store.dispatch('notifications/showError', {
            text: this.$t('notifications.error.editCourse').toString()
          })
        }
      }).finally(() => {
        this.$emit('onSaved')
        this.saving = false
        this.dialog = false
      })
    }
  }

  closeDialog (): void {
    this.dialog = false
    this.course = null
    this.$emit('close')
  }

  @Watch('show')
  onDialogVisibilityChange (show: boolean): void {
    if (show) {
      // copy object instead of referencing
      this.course = JSON.parse(JSON.stringify(this.courseProp))
    } else {
      this.course = null
    }
  }

  mounted (): void {
    // copy object instead of referencing
    this.course = JSON.parse(JSON.stringify(this.courseProp))
  }
}
</script>
