import axios from 'axios'
import store from '@/store'
import i18n from '@/plugins/i18n'
import { DateTime } from 'luxon'

const baseURL = process.env.VUE_APP_BASEURL

const LocalApiService = axios.create({
  baseURL: ''
})

const ApiService = axios.create({
  baseURL: baseURL
})

ApiService.interceptors.request.use(
  (config) => {
    const token = store.getters['auth/token']

    if (token && config?.headers) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },

  (error) => {
    return Promise.reject(error)
  }
)

ApiService.interceptors.response.use(
  function (response: any) {
    if (store.getters['auth/isAuthenticated'] && store.getters['auth/timeForNewToken'](DateTime.now())) {
      store.dispatch('auth/renewToken').catch(() => {
        store.dispatch('auth/logout')
      })
    }

    return response
  },
  function (error) {
    if (typeof error === 'undefined' || error === null) {
      return
    }

    // On network issues this will be undefined
    if (error.response) {
      const stat = error.response.status

      switch (stat) {
        case 500:
          store.dispatch('notifications/showError', {
            text: i18n.t('notifications.error.serverError').toString()
          })
          break
        case 401:
          // store.dispatch('auth/logout')
          break
        default:
          break
      }

      return Promise.reject(error.response)
    } else {
      store.dispatch('notifications/showNotification', {
        color: 'error',
        timeout: 0,
        text: i18n.t('notifications.error.networkingError').toString()
      })
    }

    return Promise.reject(error)
  }
)

export { LocalApiService, ApiService }
