<template>
  <v-container>
    <v-stepper v-model="currentStep" outlined flat>
      <v-stepper-header class="elevation-0">
        <v-stepper-step :complete="!passwordChangeRequired" step="1">
          {{ $t('userInit.stepper.passwordReset') }}
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="!consentRequired" step="2">
          {{ $t('userInit.stepper.consent') }}
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="!personalCodeRequired" step="3">
          {{ $t('userInit.stepper.personalCode') }}
        </v-stepper-step>
      </v-stepper-header>

      <v-divider/>

      <v-stepper-items>
        <v-stepper-content step="1">
          <v-card>
            <v-card-title>
              {{ $t('userInit.passwordReset.title') }}
            </v-card-title>
            <v-card-text>
              {{ $t('userInit.passwordReset.description') }}
              <v-form ref="passwordForm">
                <v-text-field prepend-icon="mdi-lock"
                              v-model="oldPassword"
                              :label="$t('userInit.passwordReset.oldPassword')"
                              :rules="oldPasswordRules"
                              type="password">
                  <template #message="{ message }">
                    {{ $t(message) }}
                  </template>
                </v-text-field>
                <v-text-field prepend-icon="mdi-lock"
                              v-model="newPassword"
                              :label="$t('userInit.passwordReset.newPasswort')"
                              :rules="passwordRules"
                              type="password">
                  <template #message="{ message }">
                    {{ $t(message) }}
                  </template>
                </v-text-field>
                <v-text-field prepend-icon="mdi-lock"
                              v-model="passwordRepeat"
                              :label="$t('userInit.passwordReset.passwordRepeat')"
                              :rules="passwordRepeatRules"
                              type="password">
                  <template #message="{ message }">
                    {{ $t(message) }}
                  </template>
                </v-text-field>
                <v-btn class="mt-5"
                       color="primary"
                       :loading="loading"
                       @click="updatePassword">
                  <v-icon left>mdi-content-save-outline</v-icon>
                  {{ $t('userInit.passwordReset.save') }}
                </v-btn>
              </v-form>
            </v-card-text>
            <v-card-actions v-if="!passwordChangeRequired">
              <v-btn v-if="consentRequired || personalCodeRequired" @click="nextStep">
                {{ $t('userInit.continue') }}
              </v-btn>
              <v-btn v-else-if="!userNotInitialized" color="primary" @click="forwardToDashboard">
                {{ $t('userInit.done') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-card>
            <v-card-text class="text-subtitle-1">
              <div v-html="$t('userInit.consent.text1')"></div>
              <div class="mt-5"><a href='/informationen-projekt-smart.pdf' target='_blank'>Informationen und Bitte um Einverständnis zum Projekt SMART (PDF)</a></div>
            </v-card-text>
            <v-card-title>
              {{ $t('userInit.consent.title') }}
            </v-card-title>
            <v-card-text class="text-subtitle-1">
              <div v-html="$t('userInit.consent.text2')"></div>
              <v-form>
                <v-checkbox v-model="consent1"
                            :label="$t('userInit.consent.checkbox1')">
                </v-checkbox>
                <v-checkbox v-model="consent2"
                            :label="$t('userInit.consent.checkbox2')">
                </v-checkbox>
                <v-btn class="mt-5"
                       color="primary"
                       :disabled="!consent1 || !consent2"
                       :loading="loading"
                       @click="saveConsent">
                  <v-icon left>mdi-clipboard-check-outline</v-icon>
                  {{ $t('userInit.consent.accept') }}
                </v-btn>
              </v-form>
            </v-card-text>
            <v-card-actions v-if="!consentRequired">
              <v-btn v-if="personalCodeRequired" @click="nextStep">
                {{ $t('userInit.continue') }}
              </v-btn>
              <v-btn v-else-if="!userNotInitialized" color="primary" @click="forwardToDashboard">
                {{ $t('userInit.done') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card>
            <v-card-title>
              {{ $t('userInit.personalCode.title') }}
            </v-card-title>
            <v-card-text class="respect-linebreaks">{{ $t('userInit.personalCode.description') }}</v-card-text>
            <v-card-text class="respect-linebreaks"><div v-html="$t('userInit.personalCode.example')"></div></v-card-text>
            <v-card-text>
              <v-form ref="codeForm">
                <v-text-field prepend-icon="mdi-card-account-details-outline"
                              v-model="code"
                              :label="$t('userInit.personalCode.textfieldLabel')"
                              :rules="codeRules">
                  <template #message="{ message }">
                    {{ $t(message) }}
                  </template>
                </v-text-field>

                <v-btn class="mt-5"
                       color="primary"
                       :loading="loading"
                       @click="saveCode">
                  <v-icon left>mdi-content-save-outline</v-icon>
                  {{ $t('userInit.personalCode.save') }}
                </v-btn>
              </v-form>
            </v-card-text>
            <v-card-actions v-if="!personalCodeRequired">
              <v-btn v-if="!userNotInitialized" color="primary" @click="forwardToDashboard">
                {{ $t('userInit.done') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { UserService } from '@/services/UserService'
import { AuthService } from '@/services/AuthService'
import { User } from '@/types/Types'
import { VForm } from '@/$refs'

@Component
export default class UserInit extends Vue {
  loading = false
  currentStep = 1
  oldPassword = ''
  newPassword = ''
  passwordRepeat = ''
  consent1 = false
  consent2 = false
  code = ''

  passwordRegex = /^(?=.*[A-Za-zäÄöÖüÜß])(?=.*\d)(?=.*[@$!%*#?_&-])[A-Za-zäÄöÖüÜß\d@$!_%*#?&-]{8,}$/
  codeRegex = /^([A-Z]{4}[0-9]{2})?$/

  passwordRules = [
    (v: string) => !!v || 'userInit.passwordReset.passwordRequired',
    (v: string) => !this.oldPasswordMatches(v) || 'userInit.passwordReset.passwordMatchesOld',
    (v: string) => this.passwordRegex.test(v) || 'userInit.passwordReset.passwordRegex'
  ]

  oldPasswordRules = [
    (v: string) => !!v || 'userInit.passwordReset.oldPasswordRequired'
  ]

  passwordRepeatRules = [
    (v: string) => !!v || 'userInit.passwordReset.passwordRepeatWrong',
    (v: string) => this.newPasswordMatches(v) || 'userInit.passwordReset.passwordRepeatWrong'
  ]

  codeRules = [
    (v: string) => !!v || 'userInit.personalCode.codeRequired'
  ]

  get passwordChangeRequired (): boolean {
    return UserService.passwordChangeRequired()
  }

  get consentRequired (): boolean {
    return UserService.consentRequired()
  }

  get personalCodeRequired (): boolean {
    return UserService.personalCodeRequired()
  }

  get userNotInitialized (): boolean {
    return UserService.userNotInitialized()
  }

  get currentUser (): User {
    return this.$store.getters['auth/user']
  }

  get passwordForm (): VForm {
    return this.$refs.passwordForm as VForm
  }

  get codeForm (): VForm {
    return this.$refs.codeForm as VForm
  }

  newPasswordMatches (password: string): boolean {
    return this.newPassword === password
  }

  oldPasswordMatches (password: string): boolean {
    return this.oldPassword === password
  }

  updatePassword () {
    this.loading = true

    if (this.passwordForm.validate()) {
      AuthService.updatePassword(this.oldPassword, this.newPassword).then(() => {
        if (this.currentUser) {
          this.currentUser.passwordChangeRequired = false
          this.$store.commit('auth/setCurrentUser', this.currentUser)
        }

        this.oldPassword = ''
        this.newPassword = ''
        this.passwordRepeat = ''
        this.loading = false

        // forward the user if all needed values are set
        if (!this.userNotInitialized) {
          this.forwardToDashboard()
        } else {
          this.nextStep()
        }
      }).catch((error) => {
        if (error.status === 401) {
          this.$store.dispatch('notifications/showError', {
            text: this.$t('notifications.error.wrongPassword').toString()
          })
        } else {
          this.$log.debug('could not update password', error)

          this.$store.dispatch('notifications/showError', {
            text: this.$t('notifications.error.updatePassword').toString()
          })
        }

        this.loading = false
      })
    } else {
      this.loading = false
    }
  }

  saveConsent (): void {
    this.loading = true

    AuthService.acceptConsent().then(() => {
      if (this.currentUser) {
        this.currentUser.consentRequired = false
        this.currentUser.consentConfirmed = true
        this.$store.commit('auth/setCurrentUser', this.currentUser)
      }

      this.loading = false

      // forward the user if all needed values are set
      if (!this.userNotInitialized) {
        this.forwardToDashboard()
      } else if (this.personalCodeRequired) {
        this.currentStep = 3
      }
    }).catch((error) => {
      this.$log.debug('could not save consent acceptance', error)

      this.$store.dispatch('notifications/showError', {
        text: this.$t('notifications.error.serverError').toString()
      })
    })
  }

  saveCode (): void {
    this.loading = true

    if (this.codeForm.validate()) {
      AuthService.updatePersonalCode(this.code).then(() => {
        if (this.currentUser) {
          this.currentUser.personalCodeRequired = false
          this.currentUser.personalCode = this.code
          this.$store.commit('auth/setCurrentUser', this.currentUser)
        }

        this.code = ''
        this.loading = false

        // forward the user if all needed values are set
        if (!this.userNotInitialized) {
          this.forwardToDashboard()
        } else if (this.passwordChangeRequired) {
          this.currentStep = 1
        } else if (this.consentRequired) {
          this.currentStep = 2
        }
      }).catch((error) => {
        this.$log.debug('could not save code', error)

        this.$store.dispatch('notifications/showError', {
          text: this.$t('notifications.error.saveCode').toString()
        })

        this.loading = false
      })
    } else {
      this.loading = false
    }
  }

  nextStep (): void {
    if (this.consentRequired) {
      this.currentStep = 2
    } else if (this.personalCodeRequired) {
      this.currentStep = 3
    }
  }

  forwardToDashboard (): void {
    this.$router.push({ name: 'Dashboard' }).catch(error => {
      if (error.name !== 'NavigationDuplicated') {
        throw error
      }
    })
  }

  mounted (): void {
    if (!this.userNotInitialized) {
      this.forwardToDashboard()
    }

    if (!this.passwordChangeRequired) {
      if (!this.consentRequired) {
        this.currentStep = 3
      } else {
        this.currentStep = 2
      }
    }
  }
}
</script>

<style scoped>

</style>
