import { render, staticRenderFns } from "./UserInit.vue?vue&type=template&id=6983bc7c&scoped=true"
import script from "./UserInit.vue?vue&type=script&lang=ts"
export * from "./UserInit.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6983bc7c",
  null
  
)

export default component.exports