<template>
  <v-dialog v-model="dialog" max-width="100%" scrollable @click:outside="closeDialog">
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="11">
            {{ $t('testDataDialog.title', { name: testName }) }}
          </v-col>
          <v-col cols="1" class="d-flex justify-end">
            <v-btn icon @click="closeDialog">
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <LoadingCard v-if="loading"/>
      <v-card-text v-else>
        <div v-if="!user.simplifiedView" v-html="this.explanation && this.explanation.introduction"></div>
        <v-tabs v-model="currentTab">
          <v-tab v-if="!user.simplifiedView">{{ $t('testDataDialog.tabs.stage') }}</v-tab>
          <v-tab v-if="!user.simplifiedView">{{ $t('testDataDialog.tabs.pattern') }}</v-tab>
          <v-tab v-if="!user.simplifiedView">{{ $t('testDataDialog.tabs.teachingSuggestions') }}</v-tab>
          <v-tab>{{ $t('testDataDialog.tabs.test') }}</v-tab>
          <v-tab>{{ $t('testDataDialog.tabs.solution') }}</v-tab>

          <v-tab-item v-if="!user.simplifiedView">
            <div>
              <v-card
                v-for="(stageData, index) in Object.entries(getStageGroupData(this.explanation.stages)) "
                class="my-1"
                :key="index">
                <v-card-title v-if="stageData[0] !== 'default'">
                  {{ $t('stages.' + stageData[0]) }}
                </v-card-title>
                <template v-for="(stage, key) in stageData[1]">
                  <v-card-title :key="key + 'title'">
                    <v-chip class="mr-3" label color="orange">
                      <span v-html="stage.name"></span>
                    </v-chip>
                    <span v-html="stage.description"></span>
                  </v-card-title>
                  <v-card-text :key="key + 'data'">
                    <span v-html="stage.details"></span>
                  </v-card-text>
                </template>
              </v-card>
            </div>
          </v-tab-item>
          <v-tab-item v-if="!user.simplifiedView">
            <div>
              <v-card
                v-for="(patternData, index) in Object.entries(getPatternGroupData(this.explanation.patterns)) "
                class="my-1"
                :key="index">
                <v-card-title v-if="patternData[0] !== 'default'">
                  {{ $t('pattern.' + patternData[0]) }}
                </v-card-title>
                <template v-for="(pattern, key) in patternData[1]">
                  <v-card-title :key="key + 'title'">
                    <v-chip class="mr-3" color="yellow">
                      <span v-html="pattern.name"></span>
                    </v-chip>
                    <span v-html="pattern.description"></span>
                  </v-card-title>
                  <v-card-text :key="key + 'data'">
                    <span v-html="pattern.details"></span>
                  </v-card-text>
                </template>
              </v-card>
            </div>
          </v-tab-item>
          <v-tab-item v-if="!user.simplifiedView">
            <div v-html="this.explanation.suggestionsIntroduction"></div>
            <div>
              <v-card
                v-for="(stageData, index) in Object.entries(getStageGroupData(this.explanation.stages)) "
                class="my-1"
                :key="index">
                <v-card-title v-if="stageData[0] !== 'default'">
                  {{ $t('stages.' + stageData[0]) }}
                </v-card-title>
                <template v-for="(stage, key) in stageData[1]">
                  <v-card-title :key="key + 'title'">
                    <v-chip class="mr-3" label color="orange">
                      <span v-html=stage.name></span>
                    </v-chip>
                    <span v-html="stage.description"></span>
                  </v-card-title>
                  <v-card-text :key="key + 'suggestions'">
                    <span v-html="stage.suggestions"></span>
                  </v-card-text>
                </template>
              </v-card>
            </div>
            <div>
              <v-card
                v-for="(patternData, index) in Object.entries(getPatternGroupData(this.explanation.patterns)) "
                class="my-1"
                :key="index">
                <v-card-title v-if="patternData[0] !== 'default'">
                  {{ $t('pattern.' + patternData[0]) }}
                </v-card-title>
                <template v-for="(pattern, key) in patternData[1]">
                  <v-card-title :key="key + 'title'">
                    <v-chip class="mr-3" color="yellow">
                      <span v-html=pattern.name></span>
                    </v-chip>
                    <span v-html="pattern.description"></span>
                  </v-card-title>
                  <v-card-text :key="key + 'suggestions'">
                    <span v-html="pattern.suggestions"></span>
                  </v-card-text>
                </template>
              </v-card>
            </div>
          </v-tab-item>
          <v-tab-item class="mb-1">
            <TestPlayer v-if="(user.simplifiedView && currentTab == 0) || currentTab === 3"
                        :courseTest="courseTest"
                        :test="test"
                        :version="version"
                        :usedDialogIsShown="show"
                        :showSolution="false"
                        :pagedDisplay="false"/>
          </v-tab-item>
          <v-tab-item class="mb-1">
            <TestPlayer v-if="(user.simplifiedView && currentTab == 1) || currentTab === 4"
                        :courseTest="courseTest"
                        :test="test"
                        :version="version"
                        :usedDialogIsShown="show"
                        :showSolution="true"
                        :pupilInput="pupilInput"
                        :pagedDisplay="false"/>
          </v-tab-item>
        </v-tabs>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import TestPlayer from '@/components/TestPlayer.vue'
import { BaseVersion, CourseTest, ResultsExplanation, Test, TypeInfo, User, Version } from '@/types/Types'
import { LangUtils } from '@/utils/LangUtils'
import { TestUtils } from '@/utils/TestUtils'
import { Watch } from 'vue-property-decorator'
import LoadingCard from '@/components/LoadingCard.vue'
import { ResultUtils } from '@/utils/ResultUtils'

const TestDataDialogProps = Vue.extend({
  props: {
    courseTest: { type: Object as () => CourseTest },
    test: { type: Object as () => Test },
    version: { type: Object as () => Version },
    pupilInput: { type: Object as () => Record<string, string> },
    show: { type: Boolean, required: true },
    showSolution: { type: Boolean, default: false },
    tab: { type: Number || null, default: null }
  }
})

@Component({
  components: { LoadingCard, TestPlayer }
})
export default class TestDataDialog extends TestDataDialogProps {
  explanation: ResultsExplanation | null = null
  loading = true
  localTab = 0

  get user (): User {
    return this.$store.getters['auth/user']
  }

  get currentTab (): number {
    if (this.tab) {
      return this.tab
    } else {
      return this.localTab
    }
  }

  set currentTab (tab: number) {
    if (this.tab) {
      this.$emit('update:tab', tab)
    } else {
      this.localTab = tab
    }
  }

  get dialog (): boolean {
    return this.show
  }

  set dialog (value: boolean) {
    this.$emit('update:show', value)
  }

  get language (): string {
    return this.$i18n.locale
  }

  get testName (): string {
    if (this.courseTest && this.courseTest.name) {
      return this.getCurrentLangText(this.courseTest.name) + ' (' + this.courseTest.variant + ')'
    } else if (this.test) {
      return this.getCurrentLangText(this.test.name) + ' (' + this.test.variant + ')'
    } else {
      return ''
    }
  }

  get selectedLanguage (): string {
    return this.$store.state.language.currentLanguage
  }

  getPatternGroupData (pattern: Record<string, Record<string, TypeInfo>> | undefined): Record<string, Array<TypeInfo>> {
    return ResultUtils.getPatternGroupData(pattern)
  }

  getStageGroupData (stages: Record<string, Record<string, TypeInfo>>): Record<string, Array<TypeInfo>> {
    return ResultUtils.getStageGroupData(stages)
  }

  getCurrentLangText (text: Record<string, string>): string {
    return LangUtils.getLangOrFallback(text, this.language)
  }

  closeDialog (): void {
    this.dialog = false
    this.$emit('close')
  }

  loadExplanation (): void {
    if (this.user.simplifiedView) {
      this.loading = false
      return
    }

    this.loading = true
    let currentVersion: BaseVersion | null = null
    let machineName = ''

    if (this.test?.versions && this.test.versions.length && this.test.machineName) {
      currentVersion = this.test.versions[this.test.versions.length - 1]
      machineName = this.test.machineName
    } else if (this.courseTest?.testVersion && this.courseTest.machineName) {
      currentVersion = this.courseTest.testVersion
      machineName = this.courseTest.machineName
    }

    if (currentVersion) {
      TestUtils.parseExplanationHTMLForTest(currentVersion, machineName, this.selectedLanguage).then((result) => {
        this.explanation = result
      }).finally(() => {
        this.loading = false
      })
    }
  }

  @Watch('show')
  onDialogVisibilityChange (show: boolean): void {
    if (show) {
      this.loadExplanation()
    }
  }

  mounted (): void {
    this.loadExplanation()
  }
}
</script>

<style scoped>
.v-card__title {
  font-size: 1rem;
  line-height: 1rem;
  word-break: normal;
}

.v-card__title span:nth-child(2) {
  flex: 90%;
}
</style>
