// We'll pull the categories from the DB, but we still add the "all" category
export enum Categories {
  All = 'All'
}

export enum ResultTypes {
  NamedPattern = 'namedPattern',
  NamedStage = 'namedStage',
  Pattern = 'pattern',
  Stage = 'stage',
  InProgress = 'inProgress'
}
