<template>
  <v-navigation-drawer v-if="!userNotInitialized"
                       app
                       :clipped="!mobile"
                       :fixed="mobile"
                       :permanent="!mobile"
                       class="nav-color"
                       v-model="showDrawer">
    <v-list v-if="loading" nav>
      <LoadingCard flat />
    </v-list>
    <v-list v-if="!loading && showCourses" nav>
      <template v-if="courses.length">
        <v-list-group v-for="course in courses"
                      v-model="course.active"
                      no-action
                      :key="course._id"
                      @click="changeCourse(course._id)">
          <template v-slot:activator>
            <v-list-item-content :value="course._id">
              <v-list-item-title>{{ course.name }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item link>
            <v-list-item-content @click="goToTests">
              <v-list-item-title>{{ $t('globalNavDrawer.tests') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link>
            <v-list-item-content @click="goToCourses">
              <v-list-item-title>{{ $t('globalNavDrawer.pupils') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </template>
      <v-list-item v-else>
        {{ $t('globalNavDrawer.noCourses') }}
      </v-list-item>
    </v-list>
    <v-list v-if="!loading && showLibrary" nav>
      <v-text-field v-model="searchTerm"
                    outlined
                    background-color="white"
                    :label="$t('globalNavDrawer.search')">
      </v-text-field>
      <v-list-item-group v-model="selectedCategory" mandatory>
        <v-list-item v-for="category in categories"
                     link
                     :key="category.value"
                     @click="selectedTestCategory = category.value">
          <v-list-item-content>
            <v-list-item-title>{{ category.nameKey ? $t(category.nameKey) : category.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <template v-if="showCourses" v-slot:append>
      <v-container>
        <v-btn text @click="openAddCourseDialog">
          <v-icon left>
            mdi-plus-circle-outline
          </v-icon>
          {{ $t('globalNavDrawer.addCourse') }}
        </v-btn>
      </v-container>
    </template>
    <AddCourseDialog :saving.sync="saving"
                     :show.sync="showCourseDialog"
                     @save="addNewCourse"/>
  </v-navigation-drawer>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Category, Course } from '@/types/Types'
import { CourseService } from '@/services/CourseService'
import AddCourseDialog from '@/components/AddCourseDialog.vue'
import { Watch } from 'vue-property-decorator'
import { Categories } from '@/types/Enums'
import LoadingCard from '@/components/LoadingCard.vue'
import { UserService } from '@/services/UserService'

const GlobalNavDrawerProps = Vue.extend({
  props: {
    loading: { type: Boolean, default: false }
  }
})

@Component({
  components: { LoadingCard, AddCourseDialog }
})
export default class GlobalNavDrawer extends GlobalNavDrawerProps {
  saving = false
  showCourseDialog = false
  selectedCategory = 0

  get allCategoriesValue (): string {
    return Categories.All
  }

  get searchTerm (): string {
    return this.$store.state.library.searchTerm
  }

  set searchTerm (searchTerm: string) {
    this.$store.commit('library/setSearchTerm', searchTerm)
  }

  get selectedCourseId (): string {
    return this.$store.state.test.selectedCourseId
  }

  set selectedCourseId (courseId: string) {
    this.$store.commit('test/setSelectedCourseId', courseId)
  }

  get selectedTestCategory (): string | null {
    return this.$store.state.library.selectedTestCategory
  }

  set selectedTestCategory (value: string | null) {
    this.$store.commit('library/setSelectedTestCategory', value)
  }

  get courses (): Array<Course> {
    return this.$store.state.test.courses
  }

  set courses (courses: Array<Course>) {
    this.$store.commit('test/setCourses', courses)
  }

  get mobile (): boolean {
    return this.$vuetify.breakpoint.mobile
  }

  get userNotInitialized (): boolean {
    return UserService.userNotInitialized()
  }

  get showDrawer (): boolean {
    return this.$store.state.showDrawer
  }

  set showDrawer (value: boolean) {
    this.$store.commit('setShowDrawer', value)
  }

  get showCourses () {
    if (this.$route.name) {
      return ['Courses', 'CourseManagement', 'Results'].includes(this.$route.name)
    } else {
      return false
    }
  }

  get showLibrary () {
    return this.$route.name === 'Library'
  }

  get categories (): Array<Category> {
    return this.$store.state.library.categories
  }

  @Watch('$route.name')
  onRouteChange () {
    this.updateNavIndices()
  }

  @Watch('loading')
  onSelectedTestCategoryChange (loading: boolean) {
    if (!loading) {
      this.updateNavIndices()
    }
  }

  goToTests () {
    if (this.$route.name === 'Courses') {
      this.$vuetify.goTo('#testTable')
    } else {
      this.$router.push({ name: 'Courses' }).then(() => {
        this.$vuetify.goTo('#testTable')
      })
    }
  }

  goToCourses () {
    if (this.$route.name === 'Courses') {
      this.$vuetify.goTo('#courseTable')
    } else {
      this.$router.push({ name: 'Courses' }).then(() => {
        this.$vuetify.goTo('#courseTable')
      })
    }
  }

  changeCourse (id: string) {
    this.selectedCourseId = id
  }

  openAddCourseDialog (): void {
    this.showCourseDialog = true
  }

  addNewCourse (courseName: string): void {
    CourseService.createCourse({
      name: courseName
    }).then((results) => {
      this.$store.dispatch('test/getCourses').then(() => {
        this.selectedCourseId = results.data._id
      })
    }).finally(() => {
      this.showCourseDialog = false
      this.saving = false
    })
  }

  updateNavIndices () {
    if (this.showCourses) {
      this.$store.dispatch('test/getCourses').then(() => {
        this.courses = this.courses.map((item) => {
          item.active = item._id === this.selectedCourseId
          return item
        })
      })
    } else if (this.showLibrary) {
      this.selectedCategory = this.categories.findIndex((item) => item.value === this.selectedTestCategory)
    }
  }

  mounted (): void {
    this.updateNavIndices()
  }
}
</script>

<style scoped>

</style>
