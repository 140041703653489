<template>
  <v-card class="text-center" :flat="flat">
    <v-progress-circular :size="50" color="primary" indeterminate class="ma-5"/>
    <v-card-title class="d-flex justify-center">{{ this.text }}</v-card-title>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import i18n from '@/plugins/i18n'

const LoadingCardProps = Vue.extend({
  props: {
    text: { type: String, default: i18n.t('loadingCard.loading') },
    flat: { type: Boolean, default: false }
  }
})

@Component
export default class LoadingCard extends LoadingCardProps {

}
</script>

<style scoped>

</style>
