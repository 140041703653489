import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { RootState, TestState } from '@/types/StoreStates'
import { CourseService } from '@/services/CourseService'
import i18n from '@/plugins/i18n'
import vuem from '@/main'
import store from '@/store'

const namespaced = true

const state: TestState = {
  selectedCourseId: null,
  courses: []
}

const mutations: MutationTree<TestState> = {
  setSelectedCourseId (state, payload) {
    state.selectedCourseId = payload
  },

  setCourses (state, payload) {
    state.courses = payload
  }
}

const actions: ActionTree<TestState, RootState> = {
  getCourses ({ commit, dispatch }) {
    return new Promise<void>((resolve, reject) => {
      CourseService.getCourses().then((results) => {
        commit('setCourses', results.data)

        resolve()
      }).catch((error) => {
        vuem.$log.debug('Could not get course list ', error)

        store.dispatch('notifications/showError', {
          text: i18n.t('notifications.error.getCourseList').toString()
        })

        reject(error)
      })
    })
  }
}

const getters: GetterTree<TestState, RootState> = {
}

export const TestStore: Module<TestState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
