import { ApiService } from '@/api/ApiService'
import { Result } from '@/types/Types'

export const EvaluationService = {
  evaluateResults (testResults: Result) {
    return ApiService({
      url: '/results/evaluate',
      method: 'post',
      data: testResults
    })
  }
}
