<template>
  <v-container>
    <LoadingCard v-if="loading" flat/>
    <template v-else>
      <v-card v-if="selectedCourse" flat>
        <v-card-title>
          <v-tooltip :open-delay="500" top>
            <template v-slot:activator="{ on }">
              <v-btn icon
                     @click="backToCoursesView"
                     :aria-label="$t('tooltips.backToCourses')"
                     v-on="on">
                <v-icon>mdi-arrow-left-circle</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('tooltips.backToCourses') }}</span>
          </v-tooltip>
          <h2>{{ selectedCourse.name }}</h2>
          <v-btn :aria-label="$t('editCourses.edit')" icon @click="openEditCourseDialog()">
            <v-icon>
              mdi-pencil-outline
            </v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="openAddPupilDialog">
            <v-icon left>
              mdi-plus-circle-outline
            </v-icon>
            {{ $t('editCourses.addPupil') }}
          </v-btn>
          <v-btn text @click="openPupilImportDialog">
            <v-icon>
              mdi-database-import-outline
            </v-icon>
            {{ $t('editCourses.import') }}
          </v-btn>
          <v-btn text @click="exportPupils">
            <v-icon>
              mdi-file-download-outline
            </v-icon>
            {{ $t('editCourses.export') }}
          </v-btn>
          <v-btn v-if="!user.disableDelete" :aria-label="$t('editCourses.delete')" icon @click="openDeleteCourseDialog(selectedCourse)">
            <v-icon>
              mdi-delete-outline
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="pupils"
            :items-per-page="-1"
            :loading="pupilLoading"
            :loading-text="$t('loadingCard.loading')"
            :no-data-text="$t('editCourses.noPupil')"
            :options.sync="options"
            :server-items-length="totalItems"
            hide-default-footer>
            <template v-slot:item.actions="{ item }">
              <v-btn v-if="!user.disableDelete" :aria-label="$t('editCourses.pupil.delete')" icon @click="openDeletePupilDialog(item)">
                <v-icon>
                  mdi-delete-outline
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
      <v-card v-else flat>
        <v-card-title>
          {{ $t('editCourses.title') }}
        </v-card-title>
        <v-divider/>
        <v-card-text>
          {{ $t('editCourses.description') }}
        </v-card-text>
      </v-card>
    </template>

    <AddPupilDialog v-if="selectedCourse"
                    :course="selectedCourse"
                    :saving.sync="saving"
                    :show.sync="showPupilDialog"
                    @onSaved="refreshPupils"/>
    <EditCourseDialog v-if="selectedCourse"
                      :courseProp="selectedCourse"
                      :saving.sync="saving"
                      :show.sync="showEditCourseDialog"
                      @onSaved="refreshCourses"/>
    <SecureDeleteDialog v-if="selectedCourse"
                        :showDialog.sync="showDeleteDialog"
                        :title="deleteDialogTitle"
                        :text="deleteDialogText"
                        :deletePhrase="deleteDialogPhrase"
                        :isLoading="saving"
                        @onDelete="deleteItem"
                        @onCancel="closeDeleteDialog"/>
    <ImportPupilsDialog v-if="selectedCourse"
                        :course="selectedCourse"
                        :show.sync="showImportDialog"
                        @saved="refreshPupils"/>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Course, PageParams, Pupil, User } from '@/types/Types'
import { CourseService } from '@/services/CourseService'
import { PupilService } from '@/services/PupilService'
import LoadingCard from '@/components/LoadingCard.vue'
import AddPupilDialog from '@/components/AddPupilDialog.vue'
import EditCourseDialog from '@/components/EditCourseDialog.vue'
import SecureDeleteDialog from '@/components/SecureDeleteDialog.vue'
import { Watch } from 'vue-property-decorator'
import ImportPupilsDialog from '@/components/ImportPupilsDialog.vue'
import { ExcelUtils } from '@/utils/ExcelUtils'

@Component({
  components: { ImportPupilsDialog, SecureDeleteDialog, EditCourseDialog, AddPupilDialog, LoadingCard }
})
export default class CourseManagement extends Vue {
  loading = true
  pupilLoading = true
  saving = false

  showPupilDialog = false
  showEditCourseDialog = false
  showDeleteDialog = false
  showImportDialog = false

  deleteDialogTitle = ''
  deleteDialogText = ''
  deleteDialogPhrase = ''

  selectedPupil: Pupil | null = null
  pupils: Array<Pupil> = []
  totalItems = 0

  options = { sortBy: ['name'], sortDesc: [false], page: 1, itemsPerPage: -1 }

  get user (): User {
    return this.$store.getters['auth/user']
  }

  get selectedCourseId (): string {
    return this.$store.state.test.selectedCourseId
  }

  set selectedCourseId (courseId: string) {
    this.$store.commit('test/setSelectedCourseId', courseId)
  }

  get courses (): Array<Course> {
    return this.$store.state.test.courses
  }

  get headers () {
    return [
      { text: this.$t('editCourses.pupil.name'), value: 'name' },
      { text: this.$t('datatable.headers.actions'), value: 'actions', sortable: false, align: 'end' }
    ]
  }

  get selectedCourse (): Course | undefined {
    if (this.selectedCourseId !== null) {
      return this.courses.find(course => course._id === this.selectedCourseId)
    }
  }

  get mobile (): boolean {
    return this.$vuetify.breakpoint.mobile
  }

  get showDrawer (): boolean {
    return this.$store.state.showDrawer
  }

  set showDrawer (value: boolean) {
    this.$store.commit('setShowDrawer', value)
  }

  @Watch('selectedCourseId')
  onSelectedCourseIdChange (): void {
    this.getPupilsForCourse()
  }

  @Watch('options')
  onOptionChange (): void {
    this.refreshPupils()
  }

  backToCoursesView (): void {
    this.$router.push({ name: 'Courses' }).catch(error => {
      if (error.name !== 'NavigationDuplicated') {
        throw error
      }
    })
  }

  openAddPupilDialog (): void {
    this.showPupilDialog = true
  }

  openPupilImportDialog (): void {
    this.showImportDialog = true
  }

  openDeleteCourseDialog (course: Course): void {
    this.deleteDialogTitle = this.$t('editCourses.deleteCourse.title').toString()
    this.deleteDialogText = this.$t('editCourses.deleteCourse.text').toString()
    this.deleteDialogPhrase = course.name

    this.showDeleteDialog = true
  }

  openDeletePupilDialog (pupil: Pupil): void {
    this.selectedPupil = pupil
    this.deleteDialogTitle = this.$t('editCourses.pupil.deletePupil.title').toString()
    this.deleteDialogText = this.$t('editCourses.pupil.deletePupil.text').toString()
    this.deleteDialogPhrase = pupil.name

    this.showDeleteDialog = true
  }

  closeDeleteDialog (): void {
    this.showDeleteDialog = false
  }

  openEditCourseDialog (): void {
    this.showEditCourseDialog = true
  }

  closeEditCourseDialog (): void {
    this.showEditCourseDialog = false
  }

  deleteItem (): void {
    if (this.selectedPupil) {
      this.deletePupil()
    } else if (this.selectedCourse) {
      this.deleteCourse()
    }
  }

  exportPupils (): void {
    ExcelUtils.generatePupilExcelExport(this.pupils)
  }

  deletePupil (): void {
    if (this.selectedPupil?._id) {
      this.saving = true

      PupilService.deletePupil(this.selectedPupil._id).then(() => {
        this.$store.dispatch('notifications/showSuccess', {
          text: this.$t('notifications.success.deletePupil').toString()
        })
      }).finally(() => {
        this.refreshPupils()
        this.showDeleteDialog = false
        this.saving = false
        this.selectedPupil = null
      })
    }
  }

  deleteCourse (): void {
    if (this.selectedCourse?._id) {
      this.saving = true

      CourseService.deleteCourse(this.selectedCourse._id).then(() => {
        this.$store.dispatch('notifications/showSuccess', {
          text: this.$t('notifications.success.deleteCourse').toString()
        })
      }).finally(() => {
        this.$store.dispatch('test/getCourses').finally(() => {
          this.showDeleteDialog = false
          this.saving = false
        })
      })
    }
  }

  refreshPupils (): void {
    this.getPupilsForCourse()
  }

  refreshCourses (): void {
    this.$store.dispatch('test/getCourses')
  }

  getPupilsForCourse (): void {
    if (this.selectedCourseId) {
      this.pupilLoading = true

      // // Note: Enable for Paging
      // const { sortBy, sortDesc, page, itemsPerPage } = this.options
      //
      // const params: PageParams = {}
      //
      // params.page = page - 1
      // params.limit = itemsPerPage
      //
      // if (sortBy.length) {
      //   params.sort = ''
      //
      //   if (sortDesc && !sortDesc[0]) {
      //     params.sort += '-'
      //   }
      // }

      const params: PageParams = {
        limit: 0
      }

      const { sortBy, sortDesc } = this.options

      if (sortBy.length) {
        params.sort = ''

        if (sortDesc && !sortDesc[0]) {
          params.sort += '-'
        }

        // workaround for fullCategory sorting
        if (sortBy[0] === 'fullCategory') {
          params.sort += 'category'
        } else {
          params.sort += sortBy[0]
        }
      }

      PupilService.getPupilsByCourse(this.selectedCourseId, params).then((results) => {
        this.pupils = results.data.results
        this.totalItems = results.data.totalCount
      }).catch((error) => {
        this.$log.debug('Could not get pupil list ', error)

        this.$store.dispatch('notifications/showError', {
          text: this.$t('notifications.error.getPupilList').toString()
        })
      }).finally(() => {
        this.pupilLoading = false
      })
    }
  }

  mounted (): void {
    this.loading = true

    if (this.$route.params.courseId) {
      this.selectedCourseId = this.$route.params.courseId
    }

    this.loading = false
  }
}
</script>
