import { AuthState, RootState } from '@/types/StoreStates'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { ApiService } from '@/api/ApiService'
import store from '@/store'
import i18n from '@/plugins/i18n'
import { AxiosResponse } from 'axios'
import { DateTime } from 'luxon'
import router from '@/router'
import { Pupil, User } from '@/types/Types'
import { AuthService } from '@/services/AuthService'
import Vue from 'vue'

const namespaced = true

const state: AuthState = {
  // We use null instead of undefined, as it seems to be not reactive if used undefined instead
  tokenLifetime: 10800,
  token: null,
  pupil: null,
  currentUser: null,
  tokenIssueDate: null
}

const mutations: MutationTree<AuthState> = {
  setCurrentUser (state, payload) {
    state.currentUser = payload
  },

  clearUser (state) {
    state.currentUser = null
  },

  storeToken (state, payload) {
    state.tokenIssueDate = DateTime.now().toISO()
    state.token = payload
  },

  clearToken (state) {
    state.tokenIssueDate = null
    state.token = null
  },

  setPupil (state, payload) {
    state.pupil = payload
  },

  clearPupil (state) {
    state.pupil = null
    Vue.$cookies.remove('pupil')
  }
}

const actions: ActionTree<AuthState, RootState> = {
  reInitPupil ({ commit, state }) {
    if (!state.pupil) {
      if (Vue.$cookies.get('pupil')) {
        commit('setPupil', JSON.parse(Vue.$cookies.get('pupil')) as Pupil)
      }
    }
  },

  register ({ commit }, user) {
    return new Promise<User>((resolve, reject) => {
      AuthService.registerUser(user).then((result: AxiosResponse<any>) => {
        resolve(result.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  sendPasswordResetLink ({ commit }, email) {
    return new Promise<void>((resolve, reject) => {
      if (email) {
        AuthService.sendPasswordResetLink(email).then(() => {
          resolve()
        }).catch((error) => {
          reject(error)
        })
      } else {
        reject(new Error('no email given'))
      }
    })
  },

  resetPassword ({ commit }, payload) {
    return new Promise<void>((resolve, reject) => {
      if (payload.token && payload.password) {
        AuthService.resetPassword(payload.token, payload.password).then(() => {
          resolve()
        }).catch((error) => {
          reject(error)
        })
      } else {
        reject(new Error('no token or password given'))
      }
    })
  },

  requestVerificationForCurrentUser ({ commit, state }) {
    return new Promise<User>((resolve, reject) => {
      if (state.currentUser) {
        AuthService.requestVerificationForUser(state.currentUser.email).then((result: AxiosResponse<any>) => {
          resolve(result.data)
        }).catch((error) => {
          reject(error)
        })
      } else {
        reject(new Error('no current user found'))
      }
    })
  },

  requestVerificationForEMail ({ commit, state }, email) {
    return new Promise<User>((resolve, reject) => {
      if (email) {
        AuthService.requestVerificationForUser(email).then((result: AxiosResponse<any>) => {
          resolve(result.data)
        }).catch((error) => {
          reject(error)
        })
      } else {
        reject(new Error('no email given'))
      }
    })
  },

  verify ({ commit }, token) {
    return new Promise<User>((resolve, reject) => {
      AuthService.verifyUser(token).then((result: AxiosResponse<any>) => {
        resolve(result.data)
      }).catch((error) => {
        reject(error)
      })
    })
  },

  requestAuth ({ commit, state }, user) {
    return new Promise<void>((resolve, reject) => {
      ApiService({
        url: '/auth/login',
        data: user,
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then((response: AxiosResponse<any>) => {
        const token = response.data.access_token
        commit('storeToken', token)

        AuthService.getCurrentUser().then((result: AxiosResponse<User>) => {
          const currentUser = result.data
          commit('setCurrentUser', currentUser)

          resolve()
        }).catch((error) => {
          store.dispatch('notifications/showError', {
            text: i18n.t('notifications.couldNotLoadUser').toString()
          })
          reject(error)
        })
      }).catch((error) => {
        if (error.status === 400 && error.data.detail === 'LOGIN_BAD_CREDENTIALS') {
          store.dispatch('notifications/showError', {
            text: i18n.t('notifications.wrongUserOrPassword').toString()
          })
        } else if (error.status === 400 && error.data.detail === 'LOGIN_USER_NOT_VERIFIED') {
          store.dispatch('notifications/showError', {
            text: i18n.t('notifications.userNotVerified').toString()
          })
          // the username is in our case the email address, the object is already encoded as a URLParam
          store.dispatch('auth/requestVerificationForEMail', user.get('username'))
        } else {
          store.dispatch('notifications/showError', {
            text: i18n.t('notifications.loginError').toString()
          })
        }
        commit('clearToken')
        reject(error)
      })
    })
  },

  renewToken ({ commit, state }) {
    return new Promise<void>((resolve, reject) => {
      ApiService({
        url: '/auth/refresh',
        method: 'post'
      }).then((response: AxiosResponse<any>) => {
        commit('storeToken', response.data.access_token)

        if (!state.currentUser) {
          AuthService.getCurrentUser().then((result) => {
            commit('setCurrentUser', result.data)
            resolve()
          })
        } else {
          resolve()
        }
      }).catch((error) => {
        commit('clearToken')
        // This happens silent in the background and should not show a user feedback
        reject(error)
      })
    })
  },

  inactivityLogout ({ dispatch }) {
    return new Promise<void>((resolve) => {
      store.dispatch('notifications/showWarning', {
        text: i18n.t('notifications.warning.inactivityLogout').toString(),
        timeout: 0
      })

      dispatch('logout').finally(() => {
        resolve()
      })
    })
  },

  logout ({ commit }) {
    return new Promise<void>((resolve) => {
      commit('clearToken')
      commit('clearUser')
      commit('clearPupil')

      router.push({ name: 'PupilLogin' }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          throw error
        }
      })
      resolve()
    })
  },

  validatePupilName  ({ commit, state }, pupil) {
    return new Promise<void>((resolve, reject) => {
      ApiService({
        url: '/auth/verify-pupil-access',
        data: pupil,
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      }).then((response: AxiosResponse<Pupil>) => {
        // replace _id with id as _id is not a valid json
        Vue.$cookies.set('pupil', JSON.stringify(response.data))
        commit('setPupil', response.data)

        resolve()
      }).catch((error) => {
        if (error.status !== 401) {
          store.dispatch('notifications/showError', {
            text: i18n.t('notifications.loginError').toString()
          })
        }

        commit('clearPupil')
        reject(error)
      })
    })
  }
}

const getters: GetterTree<AuthState, RootState> = {
  timeForNewToken: (state: AuthState) => (now: DateTime): boolean => {
    // time for a new token if lifetime < 10 minutes
    if (state.tokenIssueDate && state.tokenLifetime) {
      // subtract seconds since token was issued from lifetime
      return (state.tokenLifetime + DateTime.fromISO(state.tokenIssueDate).diff(now, 'seconds').seconds) <= 600
    } else {
      return true
    }
  },

  isAuthenticated (state: AuthState) {
    // This does not check the validity of the token, but all rest calls will fail if it's not, so this is good enough
    return !!state.token
  },

  isPupilAuthenticated (state: AuthState) {
    // Probably not the best to set something in the getter
    // but we cannot do this on init and we need to change the state to make this reactive
    if (!state.pupil && !!Vue.$cookies.get('pupil')) {
      store.commit('auth/setPupil', Vue.$cookies.get('pupil'))
    }
    return !!state.pupil || !!Vue.$cookies.get('pupil')
  },

  pupilName (state: AuthState) {
    return state.pupil?.name || (Vue.$cookies.get('pupil') as Pupil).name
  },

  pupilId (state: AuthState) {
    return state.pupil?._id || (Vue.$cookies.get('pupil') as Pupil)._id
  },

  token (state: AuthState) {
    return state.token
  },

  user (state: AuthState) {
    return state.currentUser
  },

  userIsSuperuser (state: AuthState) {
    return !!state.currentUser?.is_superuser
  },

  userVerified (state: AuthState) {
    return !!state.currentUser?.is_verified
  }
}

export const AuthStore: Module<AuthState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
