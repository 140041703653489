<template>
  <v-container>
    <v-row v-if="!selectedCourseId">
      <v-col class="mt-5">
        <v-alert v-if="courses && courses.length > 0" type="info" color="primary" outlined>
          {{ $t('courses.selectCourse') }}
        </v-alert>
        <v-alert v-else type="info" color="primary" outlined>
          {{ $t('courses.addCourseHint') }}
        </v-alert>
      </v-col>
    </v-row>
    <template v-else>
      <v-row>
        <v-col id="testTable">
          <CourseTestTable :selectedCourseId="selectedCourseId"
                           :courses="courses" />
        </v-col>
      </v-row>
      <v-row>
        <v-col id="courseTable">
          <TestResultTable :selectedCourseId="selectedCourseId"
                           :courses="courses"
                           :pupils="pupils" />
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Course, PageParams, Pupil } from '@/types/Types'
import { CourseService } from '@/services/CourseService'
import AddTestDialog from '@/components/AddTestDialog.vue'
import CourseTestToolbar from '@/components/CourseTestToolbar.vue'
import AddCourseDialog from '@/components/AddCourseDialog.vue'
import CourseTestTable from '@/components/CourseTestTable.vue'
import TestResultTable from '@/components/TestResultTable.vue'
import { PupilService } from '@/services/PupilService'
import { Watch } from 'vue-property-decorator'

@Component({
  components: { TestResultTable, CourseTestTable, AddCourseDialog, CourseTestToolbar, AddTestDialog }
})
export default class Courses extends Vue {
  loading = true
  pupils: Array<Pupil> = []

  get selectedCourseId (): string {
    return this.$store.state.test.selectedCourseId
  }

  set selectedCourseId (courseId: string) {
    this.$store.commit('test/setSelectedCourseId', courseId)
  }

  get courses (): Array<Course> {
    return this.$store.state.test.courses
  }

  get mobile (): boolean {
    return this.$vuetify.breakpoint.mobile
  }

  get showDrawer (): boolean {
    return this.$store.state.showDrawer
  }

  set showDrawer (value: boolean) {
    this.$store.commit('setShowDrawer', value)
  }

  @Watch('selectedCourseId')
  onSelectedCourseIdChange (): void {
    this.getPupilsForCourse()
  }

  getPupilsForCourse (): void {
    if (this.selectedCourseId) {
      this.loading = true

      // For now, we'll simply pull everything, this may need to be changed if the data grows very large
      const pageParams: PageParams = {
        limit: 0
      }

      PupilService.getPupilsByCourse(this.selectedCourseId, pageParams).then((results) => {
        this.pupils = results.data.results
      }).catch((error) => {
        this.$log.debug('Could not get pupil list ', error)

        this.$store.dispatch('notifications/showError', {
          text: this.$t('notifications.error.getPupilList').toString()
        })
      }).finally(() => {
        this.loading = false
      })
    }
  }

  mounted (): void {
    this.getPupilsForCourse()
    this.loading = false
  }
}
</script>
