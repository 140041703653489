import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { LanguageState, RootState } from '@/types/StoreStates'

const namespaced = true

const state: LanguageState = {
  currentLanguage: 'de'
}
const mutations: MutationTree<LanguageState> = {
  setCurrentLanguage (state, payload) {
    state.currentLanguage = payload
  }
}

const actions: ActionTree<LanguageState, RootState> = {
}

const getters: GetterTree<LanguageState, RootState> = {
}

export const LanguageStore: Module<LanguageState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
}
