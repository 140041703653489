import store from '@/store'

export const UserService = {
  passwordChangeRequired (): boolean {
    if (store.getters['auth/isAuthenticated']) {
      const currentUser = store.getters['auth/user']
      if (currentUser) {
        return currentUser.passwordChangeRequired
      } else {
        return false
      }
    } else {
      return false
    }
  },

  consentRequired (): boolean {
    if (store.getters['auth/isAuthenticated']) {
      const currentUser = store.getters['auth/user']
      if (currentUser) {
        return currentUser.consentRequired
      } else {
        return false
      }
    } else {
      return false
    }
  },

  personalCodeRequired (): boolean {
    if (store.getters['auth/isAuthenticated']) {
      const currentUser = store.getters['auth/user']
      if (currentUser) {
        return currentUser.personalCodeRequired
      } else {
        return false
      }
    } else {
      return false
    }
  },

  userNotInitialized (): boolean {
    return this.passwordChangeRequired() || this.consentRequired() || this.personalCodeRequired()
  }
}
