import { AxiosPromise } from 'axios'
import { ApiService } from '@/api/ApiService'
import { CourseTestAdd, CourseTestUpdate, PageParams } from '@/types/Types'

export const CourseTestService = {
  getCourseTest (courseTestId: string, includeTest = true): AxiosPromise {
    const params = {
      includeTest: includeTest
    }

    return ApiService({
      url: '/courseTests/' + courseTestId,
      method: 'get',
      params: params
    })
  },

  getTestsForCourse (courseId: string, params: PageParams, includeResultCount = false, includeTests = true): AxiosPromise {
    const currentParams = {
      includeTests: includeTests,
      includeResultCount: includeResultCount,
      ...params
    }
    return ApiService({
      url: '/courseTests/byCourse/' + courseId,
      method: 'get',
      params: currentParams
    })
  },

  getTestsForPupil (pupilId: string, params: PageParams, includeTests = true): AxiosPromise {
    const currentParams = {
      includeTests: includeTests,
      ...params
    }
    return ApiService({
      url: '/courseTests/byPupil/' + pupilId,
      method: 'get',
      params: currentParams
    })
  },

  createTestForCourse (courseTestAdd: CourseTestAdd): AxiosPromise {
    return ApiService({
      url: '/courseTests',
      method: 'post',
      data: courseTestAdd
    })
  },

  updateTestForCourse (courseId: string, courseTestUpdate: CourseTestUpdate): AxiosPromise {
    return ApiService({
      url: '/courseTests',
      method: 'put',
      data: courseTestUpdate,
      params: {
        id: courseId
      }
    })
  },

  deleteCourseTest (courseTestId: string): AxiosPromise {
    return ApiService({
      url: '/courseTests/' + courseTestId,
      method: 'delete'
    })
  }
}
