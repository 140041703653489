<template>
  <v-container v-if="noCategory">
    <v-card flat>
      <v-card-title>
        {{ $t('dashboard.title') }}
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <loading-card flat/>
      </v-card-text>
    </v-card>
  </v-container>
  <LibraryTestContainer v-else/>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import Vue from 'vue'
import LibraryTestContainer from '@/components/LibraryTestContainer.vue'
import LoadingCard from '@/components/LoadingCard.vue'
import router from '@/router'
import { Watch } from 'vue-property-decorator'
import { Categories } from '@/types/Enums'

@Component({
  components: { LoadingCard, LibraryTestContainer }
})
export default class Library extends Vue {
  get noCategory (): boolean {
    return !this.$route.params.category
  }

  get mobile (): boolean {
    return this.$vuetify.breakpoint.mobile
  }

  get selectedTestCategory (): string | null {
    return this.$store.state.library.selectedTestCategory
  }

  set selectedTestCategory (value: string | null) {
    this.$store.commit('library/setSelectedTestCategory', value)
  }

  @Watch('selectedTestCategory')
  onTestCategoryChange (value: string): void {
    this.openTestPage(value)
  }

  @Watch('noCategory')
  onNoCatChange (value: boolean): void {
    if (value) {
      // Wait for the router to be ready, so we can be sure that this.$route exists!
      router.onReady(() => {
        if (this.noCategory) {
          this.openTestPage(Categories.All)
        }
      })
    }
  }

  openTestPage (category: string): void {
    this.$router.push({ name: 'Library', params: { category: category } }).catch(error => {
      if (error.name !== 'NavigationDuplicated') {
        throw error
      }
    })
  }

  mounted (): void {
    // Wait for the router to be ready, so we can be sure that this.$route exists!
    router.onReady(() => {
      if (this.noCategory) {
        this.openTestPage(Categories.All)
      } else {
        this.selectedTestCategory = this.$route.params.category
      }
    })
  }
}
</script>

<style scoped>

</style>
