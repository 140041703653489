<template>
  <v-dialog v-model="dialog" persistent max-width="100%">
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="11">
            {{ $t('importPupilsDialog.title') }}
          </v-col>
          <v-col cols="1" class="d-flex justify-end">
            <v-btn icon @click="closeDialog">
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-subtitle>
        <v-row class="d-flex">
          <v-col>
            <v-alert type="info" color="primary" outlined>
              {{ $t('importPupilsDialog.info') }} <br><br>
              {{ $t('addPupilDialog.info') }}
            </v-alert>
          </v-col>
        </v-row>
        <v-row class="d-flex">
          <v-col>
            <v-btn text color="primary" @click="generateExcelTemplate">
              {{ $t('importPupilsDialog.excelButton') }}
              <v-icon right>mdi-microsoft-excel</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-card-title>
        <v-row class="justify-left mx-0 bg-normal">
          <v-col cols="10">
            <v-file-input
              show-size
              :label="$t('importPupilsDialog.uploadExcel')"
              v-model="selectedFile">
            </v-file-input>
            <v-btn
              :disabled="!selectedFile"
              :loading="uploading"
              color="primary"
              outlined
              rounded
              @click="parseFile">
              {{ $t('importPupilsDialog.save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import Vue from 'vue'
import * as XLSX from 'xlsx'
import { Course } from '@/types/Types'
import { ExcelUtils } from '@/utils/ExcelUtils'
import { StringUtils } from '@/utils/StringUtils'
import LoadingCard from '@/components/LoadingCard.vue'
import { PupilService } from '@/services/PupilService'

const ImportPupilsDialogProps = Vue.extend({
  props: {
    course: { type: Object as () => Course, required: true },
    show: { type: Boolean, required: true }
  }
})
@Component({
  components: { LoadingCard }
})
export default class ImportPupilsDialog extends ImportPupilsDialogProps {
  selectedFile: File | null = null
  uploading = false
  newPupilNames: Array<string> = []

  get dialog (): boolean {
    return this.show
  }

  set dialog (value: boolean) {
    this.$emit('update:show', value)
  }

  parseFile (): void {
    if (this.selectedFile) {
      this.uploading = true
      const fileExtension = this.selectedFile.name.split('.').pop()

      if (fileExtension === 'xlsx') {
        const reader = new FileReader()

        reader.onloadend = () => {
          this.selectedFile = null
          this.uploading = false
          // this.closeDialog()
        }

        reader.onload = (e) => {
          const data = new Uint8Array(e.target?.result as ArrayBuffer)
          const workbook = XLSX.read(data, { type: 'array' })
          const worksheet = workbook.Sheets[Object.keys(workbook.Sheets)[0]]

          this.newPupilNames = XLSX.utils.sheet_to_json(worksheet).map((item: any) => {
            return StringUtils.getPseudonym(item.Vorname, item.Nachname)
          })

          if (this.course._id) {
            PupilService.createPupils(this.course._id, this.newPupilNames.length, this.newPupilNames).then(() => {
              this.saved()
            }).catch(() => {
              this.$log.debug('Could not add access to database')
              this.$store.dispatch('notifications/showError', {
                text: this.$t('notifications.error.addAccess').toString()
              })
            })
          }
        }
        reader.readAsArrayBuffer(this.selectedFile)
      } else {
        this.$log.debug('File parsing error: File extension not recognized!')
        this.$store.dispatch('notifications/showError', {
          text: this.$t('notifications.onlyXlsxAllowed').toString()
        })

        this.uploading = false
        this.selectedFile = null
      }
    }
  }

  generateExcelTemplate (): void {
    ExcelUtils.generateExcelTemplate()
  }

  saved (): void {
    this.dialog = false
    this.$emit('saved')
  }

  closeDialog (): void {
    this.dialog = false
    this.$emit('close')
  }
}
</script>

<style scoped>

</style>
