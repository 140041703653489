export const StringUtils = {

  getPseudonym (firstname: string, lastname: string): string {
    return this.capitalizeFirstLetter(lastname.substr(0, 3)) + this.capitalizeFirstLetter(firstname.substr(0, 3))
  },

  capitalizeFirstLetter (string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }
}
