<template>
  <div class="text-center ma-2">
    <v-snackbar :color="color" v-model="open" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="open = false">
          {{ $t('global.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import Vue from 'vue'

@Component
export default class GlobalNotificationBar extends Vue {
  get color () {
    return this.$store.state.notifications.color
  }

  set color (value) {
    this.$store.commit('notifications/setColor', value)
  }

  get open () {
    return this.$store.state.notifications.open
  }

  set open (value) {
    this.$store.commit('notifications/setOpenState', value)
  }

  get timeout () {
    return this.$store.state.notifications.timeout
  }

  set timeout (value) {
    this.$store.commit('notifications/setTimeout', value)
  }

  get text () {
    return this.$store.state.notifications.text
  }

  set text (value) {
    this.$store.commit('notifications/setText', value)
  }
}
</script>

<style scoped>

</style>
