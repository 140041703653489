<template>
  <div>
    <LoadingCard v-if="isLoading" flat />
    <v-container v-else fluid>
      <v-row v-if="tests.length">
        <v-col class="d-flex justify-start shrink" v-for="test in tests" :key="test.title">
          <TestCard :test="test"
                    :courses="courses"
                    @openPreview="openPreviewDialog"
                    @openDetailsDialog="openDetailsDialog"
                    @added="testAdded" />
        </v-col>
      </v-row>
      <v-card v-else flat>
        <v-card-text>
          {{ $t('tests.noItems') }}
        </v-card-text>
      </v-card>
      <v-pagination v-if="!isLoading && pageCount > 1"
                    v-model="currentPage"
                    :length="pageCount"
                    total-visible="10" />
    </v-container>
    <TestInfoDialog v-if="selectedTest"
                   :showDialog.sync="showDetailsDialog"
                   :test="selectedTest" />
    <TestDataDialog v-if="selectedTest"
                  :show.sync="showPreviewDialog"
                  :test="selectedTest"/>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { Category, Course, PageParams, Test, Version } from '@/types/Types'
import TestCard from '@/components/TestCard.vue'
import { TestService } from '@/services/TestService'
import { Watch } from 'vue-property-decorator'
import TestInfoDialog from '@/components/TestInfoDialog.vue'
import { TestUtils } from '@/utils/TestUtils'
import LoadingCard from '@/components/LoadingCard.vue'
import { CourseService } from '@/services/CourseService'
import TestDataDialog from '@/components/TestDataDialog.vue'
import { Categories } from '@/types/Enums'

const LibraryTestContainerProps = Vue.extend({
  props: {
    // course is used for non-library usage
    course: { type: Object as () => Course, default: null },
    tab: { type: Number, default: undefined }
  }
})

@Component({
  components: { TestDataDialog, LoadingCard, TestInfoDialog, TestCard }
})
export default class LibraryTestContainer extends LibraryTestContainerProps {
  tests: Array<Test> = []
  currentCategory: string | null = null
  selectedTest: Test | null = null
  showPreviewDialog = false
  showDetailsDialog = false
  isLoading = false
  pageCount = 0
  currentPage = 1
  courses: Array<Course> = []
  typingTimer = 0
  doneTypingInterval = 550

  get searchTerm (): string {
    return this.$store.state.library.searchTerm
  }

  set searchTerm (searchTerm: string) {
    this.$store.commit('library/setSearchTerm', searchTerm)
  }

  get categories (): Array<Category> {
    return this.$store.state.library.categories
  }

  get language (): string {
    return this.$i18n.locale
  }

  get selectedLanguage (): string {
    return this.$store.state.language.currentLanguage
  }

  @Watch('$route.params.category')
  onParamChange (value: string): void {
    this.currentCategory = value
    this.getData()
  }

  @Watch('currentPage')
  onPageChanged (): void {
    this.getData()
  }

  @Watch('tab')
  onTabChange (tab: number): void {
    if (tab) {
      this.currentCategory = this.categories[this.tab].value
      this.getData()
    }
  }

  @Watch('course')
  onCourseChange (): void {
    // We need a watcher here to make sure that this is always correct when used in a dialog
    // if not this would only be correct once when mounted
    if (!this.course) {
      this.getCourses()
    } else {
      this.courses = []
      this.courses.push(this.course)
    }
  }

  @Watch('searchTerm')
  onSearchChange (searchTerm: string): void {
    this.searchWithTimeout()
  }

  searchWithTimeout () {
    clearTimeout(this.typingTimer)
    this.typingTimer = window.setTimeout(() => {
      this.getData()
    }, this.doneTypingInterval)
  }

  testAdded (): void {
    this.$emit('testAdded')
  }

  onCloseDialog (): void {
    this.selectedTest = null
    this.showDetailsDialog = false
    this.showPreviewDialog = false
  }

  openPreviewDialog (test: Test): void {
    this.selectedTest = test
    this.showPreviewDialog = true
  }

  openDetailsDialog (test: Test): void {
    this.selectedTest = test
    this.showDetailsDialog = true
  }

  getVersionString (version: Version, underScore = false): string {
    return TestUtils.getVersionString(version, underScore)
  }

  getCourses (): void {
    CourseService.getCourses().then((results) => {
      this.courses = results.data
    }).catch((error) => {
      this.$log.debug('Could not get course list ', error)

      this.$store.dispatch('notifications/showError', {
        text: this.$t('notifications.error.getCourseList').toString()
      })
    })
  }

  getData (): void {
    this.isLoading = true

    const params: PageParams = {
      language: this.selectedLanguage,
      page: (this.currentPage - 1)
    }

    if (this.currentCategory !== Categories.All) {
      // params.qfilter = 'categories.' + this.selectedLanguage + '=in=' + this.currentCategory
      params.qfilter = 'categories=in=' + this.currentCategory
    }

    if (this.searchTerm.length >= 3) {
      params.q = this.searchTerm
    }

    TestService.getTests(params).then((response) => {
      this.tests = response.data.results
      this.pageCount = response.data.pageCount
      this.isLoading = false
    })
  }

  mounted (): void {
    if (this.$route.params.category) {
      this.currentCategory = this.$route.params.category
    } else if (this.tab) {
      this.currentCategory = this.categories[this.tab].value
    } else {
      this.currentCategory = Categories.All.valueOf()
    }

    if (!this.course) {
      this.getCourses()
    } else {
      this.courses = []
      this.courses.push(this.course)
    }
    this.getData()
  }
}
</script>
