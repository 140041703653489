<template>
  <v-app>
    <HeaderBar :showTestSelection="false" />
    <GlobalNavDrawer v-if="teacherLoggedIn" :loading="loading"/>
    <v-main>
      <router-view :class="mobile ? 'mb-10' : ''"/>
      <MainFooter v-if="mobile" :mobile="mobile"/>
      <GlobalNotificationBar/>
    </v-main>
    <MainFooter v-if="!mobile" :mobile="mobile"/>
    <v-bottom-navigation
      v-if="mobile && teacherLoggedIn"
      :value="menuTab"
      color="primary" grow fixed app>
      <v-btn @click="changeRoute('Courses')">
        <span>{{ $t('header.courses') }}</span>
        <v-icon large>mdi-account-multiple</v-icon>
      </v-btn>
      <v-btn @click="changeRoute('Library')">
        <span>{{ $t('header.library') }}</span>
        <v-icon large>mdi-clipboard-list-outline</v-icon>
      </v-btn>
      <v-btn v-if="false" @click="changeRoute('Tutorials')">
        <span>{{ $t('header.tutorial') }}</span>
        <v-icon large>mdi-help-circle-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </v-app>
</template>

<script lang="ts">
import Vue from 'vue'
import GlobalNotificationBar from '@/components/GlobalNotificationBar.vue'
import Component from 'vue-class-component'
import i18n from '@/plugins/i18n'
import { Options } from '@/types/Options'
import HeaderBar from '@/components/HeaderBar.vue'
import { Category } from '@/types/Types'
import router from '@/router'
import { Watch } from 'vue-property-decorator'
import MainFooter from '@/components/MainFooter.vue'
import GlobalNavDrawer from '@/components/GlobalNavDrawer.vue'

@Component({
  components: { GlobalNavDrawer, MainFooter, HeaderBar, GlobalNotificationBar }
})
export default class App extends Vue {
  loading = true

  get selectedLanguage (): string {
    return this.$store.state.language.currentLanguage
  }

  set selectedLanguage (value: string) {
    this.$store.commit('language/setCurrentLanguage', value)
  }

  get mobile (): boolean {
    return this.$vuetify.breakpoint.mobile
  }

  get teacherLoggedIn (): boolean {
    return this.$store.getters['auth/isAuthenticated']
  }

  get menuTab (): number | null {
    return this.$store.state.selectedMenuItem
  }

  set menuTab (value: number | null) {
    this.$store.commit('setSelectedMenuItem', value)
  }

  get categories (): Array<Category> {
    return this.$store.state.library.categories
  }

  changeRoute (routeName: string, params?: any): void {
    this.$router.push({ name: routeName }).catch(error => {
      if (error.name !== 'NavigationDuplicated') {
        throw error
      }
    })
  }

  autoDetectLanguage (): void {
    let detectedLanguage
    if (navigator.language.includes('-')) {
      detectedLanguage = navigator.language.split('-')[0]
    } else {
      detectedLanguage = navigator.language
    }

    for (const lang of Options.languages) {
      if (lang.value === detectedLanguage) {
        i18n.locale = lang.value
        this.selectedLanguage = i18n.locale
      }
    }
  }

  selectCurrentNavItem (): void {
    if (this.teacherLoggedIn) {
      switch (this.$router.currentRoute.name) {
        case 'Courses':
          this.menuTab = 0
          break
        case 'EditCourses':
          this.menuTab = 0
          break
        case 'Library':
          this.menuTab = 1
          break
        case 'Tutorials':
          this.menuTab = 4
          break
        default:
          break
      }
    }
  }

  refreshItems () {
    this.selectCurrentNavItem()

    // Check if the categories are already set and get them if not
    if (this.teacherLoggedIn &&
      (!this.$store.state.library.categories || this.$store.state.library.categories.length === 0)) {
      this.$store.dispatch('library/getCategories').finally(() => {
        this.loading = false
      })
    }
  }

  @Watch('$route.name')
  onRouteChange (): void {
    // Wait for the router to be ready, so we can be sure that this.$route exists!
    router.onReady(() => {
      this.refreshItems()
    })
  }

  mounted (): void {
    // this.autoDetectLanguage()

    // Wait for the router to be ready, so we can be sure that this.$route exists!
    router.onReady(() => {
      this.refreshItems()
    })
  }
}
</script>

<style lang="scss">
@import 'src/scss/_vue.scss';
@import '../node_modules/typeface-roboto/index.css';

/* source-serif-pro-regular - latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/source-serif-pro-v15-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/source-serif-pro-v15-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/source-serif-pro-v15-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/source-serif-pro-v15-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/source-serif-pro-v15-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/source-serif-pro-v15-latin-regular.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
}

/* source-serif-pro-italic - latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: italic;
  font-weight: 400;
  src: url('assets/fonts/source-serif-pro-v15-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/source-serif-pro-v15-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/source-serif-pro-v15-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/source-serif-pro-v15-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/source-serif-pro-v15-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/source-serif-pro-v15-latin-italic.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
}

/* source-serif-pro-700 - latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/source-serif-pro-v15-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/source-serif-pro-v15-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/source-serif-pro-v15-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/source-serif-pro-v15-latin-700.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/source-serif-pro-v15-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/source-serif-pro-v15-latin-700.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
}

/* source-serif-pro-700italic - latin */
@font-face {
  font-family: 'Source Serif Pro';
  font-style: italic;
  font-weight: 700;
  src: url('assets/fonts/source-serif-pro-v15-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('assets/fonts/source-serif-pro-v15-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/source-serif-pro-v15-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/source-serif-pro-v15-latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/source-serif-pro-v15-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/source-serif-pro-v15-latin-700italic.svg#SourceSerifPro') format('svg'); /* Legacy iOS */
}

// Fix for issues with the navbar, will be fixed in vuetify 3
// https://github.com/vuetifyjs/vuetify/issues/8067
.v-bottom-navigation--fixed {position: fixed !important;}
.v-item-group.v-bottom-navigation .v-btn {height: inherit !important;}
.v-bottom-navigation .theme--light.v-btn.v-btn--has-bg {background-color: inherit !important;}
</style>
