<template>
  <v-card min-width="350" max-width="350" min-height="250" class="d-flex flex-column">
    <v-card-title>
      {{ getCurrentLangText(courseTest.name) }}
    </v-card-title>
    <v-img :src="previewPath" contain/>
    <v-spacer />
    <v-card-actions class="d-flex justify-end">
      <v-btn icon :aria-label="$t('courseTestCard.startTest')" color="primary" @click="startTest">
        <v-icon x-large>
          mdi-play-circle-outline
        </v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import Vue from 'vue'
import { CourseTest } from '@/types/Types'
import { LangUtils } from '@/utils/LangUtils'

const CourseTestCardProps = Vue.extend({
  props: {
    loading: { type: Boolean, default: false },
    courseTest: { type: Object as () => CourseTest, required: true }
  }
})

@Component
export default class CourseTestCard extends CourseTestCardProps {
  get language (): string {
    return this.$i18n.locale
  }

  get isLoading (): boolean {
    return this.loading
  }

  set isLoading (value: boolean) {
    this.$emit('update:loading', value)
  }

  get previewPath (): string {
    return '/tests-src/' + this.courseTest.machineName + '/preview.svg'
  }

  startTest (): void {
    if (this.courseTest._id) {
      this.$router.push({ name: 'ConductTest', params: { courseTestId: this.courseTest._id } })
    }
  }

  getCurrentLangText (text: Record<string, string>): string {
    return LangUtils.getLangOrFallback(text, this.language)
  }
}
</script>

<style scoped>
.v-card__title {
  min-height: 140px;
  white-space: pre-line;
  word-break: break-word;
}
</style>
