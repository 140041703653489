<template>
  <v-container fluid class="my-15">
    <v-layout align-center justify-center>
      <v-card class="pb-5 pt-5 px-8">
        <v-card-title class="mt-n5">
          <v-container justify="center">
            <v-row justify="center">
              {{ $t('login.headline') }}
            </v-row>
          </v-container>
        </v-card-title>
        <v-row justify="center">
          <v-alert text :icon="false" dense max-width="300px" dismissible
                   v-model="showFailedLogin" type="error" outlined>
            {{ $t('login.failed') }}
          </v-alert>
        </v-row>
        <v-card-text>
          <v-form id="login-form" ref="form">
            <v-text-field prepend-inner-icon="mdi-account"
                          outlined
                          v-model="email"
                          :label="$t('login.emailOrUser')"
                          placeholder=" "
                          autocomplete="username"
                          :rules="[v => !!v || 'login.emailOrUserRequired']">
              <template #message="{ message }">
                {{ $t(message) }}
              </template>
            </v-text-field>
            <v-text-field prepend-inner-icon="mdi-lock"
                          outlined
                          v-model="password"
                          :label="$t('login.password')"
                          placeholder=" "
                          :rules="[v => !!v || 'login.passwordRequired']"
                          @click:append="isPasswordVisible = !isPasswordVisible"
                          :type="isPasswordVisible ? 'text' : 'password'"
                          autocomplete="current-password"
                          :append-icon="isPasswordVisible ? 'mdi-eye-off' : 'mdi-eye'">
              <template #message="{ message }">
                {{ $t(message) }}
              </template>
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn class="primary" form="login-form"
                 type="submit"
                 :loading="loading"
                 @click.stop.prevent="handleLogin">
            {{ $t('login.submit') }}
          </v-btn>
        </v-card-actions>
        <!-- <v-card-actions>
          <router-link :to="{ name: 'Register' }">
            <v-btn text small form="login-form">
              {{ $t('login.register') }}
            </v-btn>
          </router-link>
        </v-card-actions> -->
        <v-card-actions>
          <v-row>
            <v-col class="d-flex align-center">
              <router-link :to="{ name: 'RequestPasswordReset' }">
                <v-btn text small form="login-form">
                  {{ $t('login.forgotPassword') }}
                </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script lang="ts">

import Component from 'vue-class-component'
import Vue from 'vue'
import { VForm } from '@/$refs'

@Component
export default class Login extends Vue {
  email = ''
  password = ''
  isPasswordVisible = false
  loading = false
  showFailedLogin = false

  get form (): VForm {
    return this.$refs.form as VForm
  }

  handleLogin (): void {
    this.form.validate()

    if (this.email && this.password) {
      this.loading = true

      // Convert to x-www-form-urlencoded
      const user = new URLSearchParams()
      user.append('username', this.email)
      user.append('password', this.password)

      this.$store.dispatch('auth/requestAuth', user).then(() => {
        this.forwardToDashboard()
        this.loading = false
      }).catch(error => {
        this.loading = false

        if (error.status === 401) {
          this.showFailedLogin = true
        }
      })
    }
  }

  forwardToDashboard () {
    this.$router.push({ name: 'Dashboard' }).catch(error => {
      if (error.name !== 'NavigationDuplicated') {
        throw error
      }
    })
  }

  mounted () {
    // forward if already logged in, we may need to "refresh" the token here to be sure?
    if (this.$store.getters['auth/isAuthenticated']) {
      this.forwardToDashboard()
    }
  }
}
</script>

<style scoped>

</style>
