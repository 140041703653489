import { render, staticRenderFns } from "./ConfirmPasswordResetForm.vue?vue&type=template&id=57322bfc&scoped=true"
import script from "./ConfirmPasswordResetForm.vue?vue&type=script&lang=ts"
export * from "./ConfirmPasswordResetForm.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57322bfc",
  null
  
)

export default component.exports