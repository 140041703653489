<template>
  <div>
    <LoadingCard v-if="loading" />
    <template v-if="showTest">
      <v-row v-if="pagedDisplay">
        <v-col>
          <v-progress-linear :value="progress" height="25">
            <span :class="progress > 55 ? 'white--text' : ''">{{ progress }}%</span>
          </v-progress-linear>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div v-html="html" ref="playerHtml"></div>
        </v-col>
      </v-row>
      <v-row v-if="pagedDisplay">
        <v-col class="d-flex justify-center">
          {{ $t('player.paging', { current: currentPage, total: totalPages }) }}
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="showPreviousButton" class="d-flex justify-center">
          <v-btn @click="previousPage">
            {{ $t('player.previous') }}
          </v-btn>
        </v-col>
        <v-col v-if="showNextButton" class="d-flex justify-center">
          <v-btn @click="nextPage">
            {{ $t('player.next') }}
          </v-btn>
        </v-col>
        <v-col v-if="showEndButton" class="d-flex justify-center">
          <v-btn v-if="!usedDialogIsShown" color="primary" :loading="saving" @click="checkSubmission">
            {{ $t('player.finish') }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <div v-else>
      {{ $t('player.errorLoading') }}
    </div>
    <SimpleConfirmDialog
      :showDialog.sync="showConfirmDialog"
      :title="$t('player.confirmDialog.title')"
      :text="$t('player.confirmDialog.text')"
      :cancelText="$t('player.confirmDialog.cancel')"
      :confirmText="$t('player.confirmDialog.confirm')"
      @onConfirm="submitTestForm" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import { CourseTest, Result, Test, TraceEntry, User, Version } from '@/types/Types'
import { TestUtils } from '@/utils/TestUtils'
import $ from 'jquery'
import 'jqueryui'
import { Watch } from 'vue-property-decorator'
import { EvaluationService } from '@/services/EvaluationService'
import i18n from '@/plugins/i18n'
import SimpleConfirmDialog from '@/components/SimpleConfirmDialog.vue'
import LoadingCard from '@/components/LoadingCard.vue'
import { DateTime, Interval } from 'luxon'
import { ResultUtils } from '@/utils/ResultUtils'
import '@rwap/jquery-ui-touch-punch/jquery.ui.touch-punch.js'
// only needed if we want to use tex
// import 'mathjax/es5/tex-svg.js'
// import 'mathjax/es5/tex-chtml.js'

const TestPlayerProps = Vue.extend({
  props: {
    courseTest: { type: Object as () => CourseTest },
    test: { type: Object as () => Test },
    version: { type: Object as () => Version },
    pupilInput: { type: Object as () => Record<string, string> },
    usedDialogIsShown: { type: Boolean, default: false },
    showSolution: { type: Boolean, default: false },
    pagedDisplay: { type: Boolean, default: true },
    pupilOutcomes: { type: Object as () => Record<string, boolean> }
  }
})
@Component({
  components: { LoadingCard, SimpleConfirmDialog }
})
export default class TestPlayer extends TestPlayerProps {
  correctIcon = '<i data-v-73ede018="" aria-hidden="true" class="v-icon correct--text notranslate mdi mdi-check-circle-outline mr-1 theme--light"></i>'
  wrongIcon = '<i data-v-73ede018="" aria-hidden="true" class="v-icon wrong--text notranslate mdi mdi-close-circle-outline mr-1 theme--light"></i>'
  loading = false
  saving = false
  showConfirmDialog = false
  currentPage = 1
  html = ''
  currentVersion: Version | null = null
  filledInputs = 0
  traceTimestamp: DateTime = DateTime.now()
  trace: Array<TraceEntry> | null = []

  get user (): User | null {
    return this.$store.getters['auth/user']
  }

  get pupilId (): string {
    return this.$store.getters['auth/pupilId']
  }

  get showEndButton (): boolean {
    return this.currentPage === this.totalPages
  }

  get showNextButton (): boolean {
    return this.pagedDisplay && !this.showEndButton
  }

  get showPreviousButton (): boolean {
    return this.pagedDisplay && this.currentPage > 1
  }

  get totalPages (): number {
    if (this.currentVersion) {
      return this.currentVersion.pageIds.length
    } else {
      return 0
    }
  }

  get showTest (): boolean {
    return !!this.html
  }

  get selectedLanguage (): string {
    return this.$store.state.language.currentLanguage
  }

  get progress (): number {
    if (this.filledInputs) {
      return Math.round(this.filledInputs / Object.keys(this.getInputs()).length * 100)
    }
    return 0
  }

  get mathJaxContent (): HTMLElement {
    return this.$refs.playerHtml as HTMLElement
  }

  @Watch('usedDialogIsShown')
  onDialogVisibilityChange (shown: boolean): void {
    // Reset values on dialog change, so the next we open it, it will be reset
    if (shown) {
      this.loadTest()
    } else {
      this.currentPage = 1
      this.html = ''
      if (this.pagedDisplay) {
        this.showCurrentPage()
      }
    }
  }

  @Watch('selectedLanguage')
  onLanguageChange (): void {
    this.loadTest()
  }

  previousPage (): void {
    this.addTraceEntry(this.currentPage)
    this.currentPage--
    this.showCurrentPage()
    this.scrollToTop()
  }

  nextPage (): void {
    this.addTraceEntry(this.currentPage)
    this.currentPage++
    this.showCurrentPage()
    this.scrollToTop()
  }

  scrollToTop () {
    this.$nextTick(() => {
      this.$vuetify.goTo(0, { duration: 200 })
    })
  }

  addTraceEntry (pageId: number): void {
    if (pageId) {
      const traceEntry: TraceEntry = {}
      traceEntry.pageId = pageId
      traceEntry.duration = Interval.fromDateTimes(this.traceTimestamp, DateTime.now()).length('seconds')
      this.traceTimestamp = DateTime.now()
      this.trace?.push(traceEntry)
    }
  }

  showCurrentPage (): void {
    // hide all pages
    $('.page').hide()
    // show first page only
    $('#page-' + this.currentPage).show()

    $('#test-form select, input, textarea').off()
    $('#test-form select, input, textarea').on('change', () => {
      this.filledInputs = Object.entries(this.getInputs()).filter(([key, value]) => value !== '' && value !== 'blank').length
    })
  }

  fillSolution (): void {
    // if a pupil input is given prefer
    if (this.pupilInput && this.pupilOutcomes) {
      this.fillPupilSolution()
    // if not use the sample solution
    } else if (this.currentVersion?.solution) {
      this.fillSampleSolution()
    }
  }

  fillPupilSolution (): void {
    Object.entries(this.pupilInput).forEach((item) => {
      const match = this.pupilOutcomes[item[0]]
      const jQuerySlider = this.findSlider(item)
      const jQueryDragDrop = this.findDroppable(item)

      if (jQuerySlider.length > 0) {
        this.setSliderValue(jQuerySlider, item)
        this.flagSliderSolution(jQuerySlider, match)
      } else if (jQueryDragDrop.length > 0) {
        this.setDroppableValue(jQueryDragDrop, item)
        this.flagDroppableSolution(jQueryDragDrop, match)
      } else {
        this.setFormValue(item)
        this.flagFormSolution(item, match)
      }
    })
  }

  setFormValue (item: any): any {
    let jQuerySelector = $('[name=' + item[0] + ']')
    // This was added to distinguish between dropdowns and the new radio groups
    if (jQuerySelector.is('select')) {
      // set the value of the dropdown
      jQuerySelector.val(item[1])
    } else if (jQuerySelector.is('input')) {
      // filter for the correct radio and override the selector to use it later for labeling
      // check if this is a text box or a radio
      if (jQuerySelector.attr('type') === 'text') {
        jQuerySelector.val(item[1])
      } else if (jQuerySelector.attr('type') === 'radio') {
        jQuerySelector = jQuerySelector.filter('[value=' + item[1] + ']')
        jQuerySelector.prop('checked', true)
      }
    }
  }

  flagFormSolution (item: any, match: boolean): void {
    const jQuerySelector = $('[name=' + item[0] + ']')
    if (match) {
      // correct
      jQuerySelector.addClass('correct')
      jQuerySelector.before(this.correctIcon)
    } else {
      // wrong
      jQuerySelector.addClass('wrong')
      jQuerySelector.before(this.wrongIcon)
    }
  }

  findSlider (item: any): any { // TODO Whats the correct type?
    return $("div[data-type='slider'][data-resp='" + item[0] + "']")
  }

  setSliderValue (jQuerySlider: any, item: any): void { // TODO Whats the correct type?
    const normalization = Number.parseInt(jQuerySlider.attr('data-normalization') || '')
    const value = Number.parseFloat(item[1])
    const sliderValue = ResultUtils.toSliderValue(value, normalization)
    jQuerySlider.slider('option', 'value', sliderValue)
    jQuerySlider.find('.ui-slider-handle').append('<span class="slider-solution">' + Math.round(value) + '</span>')
  }

  flagSliderSolution (jQuerySlider: any, match: boolean): void {
    // This is a question that contains a slider, so we add a buffer to see if the answer is correct
    if (match) {
      // correct
      jQuerySlider.find('.ui-slider-range').addClass('correct')
      jQuerySlider.parent().append(this.correctIcon)
    } else {
      // wrong
      jQuerySlider.find('.ui-slider-range').addClass('wrong')
      jQuerySlider.parent().append(this.wrongIcon)
    }
  }

  findDroppable (item: any): any { // TODO Whats the correct type?
    return $("div[data-type='dragdrop'][data-resp='" + item[0] + "']")
  }

  setDroppableValue (jQueryDroppable: any, item: any): void {
    const jQueryDraggable = $(".answer-item[data-value='" + item[1] + "']")

    jQueryDraggable.detach().css({ top: 0, left: 0 }).appendTo(jQueryDroppable)
  }

  flagDroppableSolution (jQueryDroppable: any, match: boolean): void {
    if (match) {
      // correct
      jQueryDroppable.addClass('correct')
      jQueryDroppable.before(this.correctIcon)
    } else {
      // wrong
      jQueryDroppable.addClass('wrong')
      jQueryDroppable.before(this.wrongIcon)
    }
  }

  fillSampleSolution (): void {
    if (this.currentVersion?.solution) {
      Object.entries(this.currentVersion.solution).forEach((item) => {
        const jQuerySlider = this.findSlider(item)
        const jQueryDroppable = this.findDroppable(item)

        if (jQuerySlider.length > 0) {
          this.setSliderValue(jQuerySlider, item)
        } else if (jQueryDroppable.length > 0) {
          this.setDroppableValue(jQueryDroppable, item)
        } else {
          this.setFormValue(item)
        }
      })
    }
  }

  checkSubmission (): void {
    const inputs: any = this.getInputs()
    if (Object.values(inputs).length - Object.values(inputs).filter(value => value !== '' && value !== 'blank').length) {
      // open dialog if not all values were filled
      this.showConfirmDialog = true
    } else {
      // if everything is filled out we'll simply submit the data
      this.submitTestForm()
    }
  }

  getInputs () {
    // Note: Only successful form controls are included in a FormData object, i.e. those with a name, not disabled and
    // checked (radio buttons and checkboxes) or selected (one or more options within a select).
    const inputs: any = {}

    if (document.getElementById('test-form')) {
      const testForm = document.getElementById('test-form') as HTMLFormElement
      const formData = new FormData(testForm)

      formData.forEach((value, key) => {
        inputs[key] = value
      })
    }

    return inputs
  }

  addDragDropLogic () {
    $('.answer-item').each(function () {
      $(this).data('original-parent', $(this).parent().data('resp'))
    })

    // Make the answer-items draggable
    $('.answer-item').draggable({
      stack: '.question-item'
    })

    // Make the question-items droppable
    $('.question-item').droppable({
      accept: function (draggable: any) {
        // Check if the draggable item's group matches this question-item's group
        return $(this).data('resp') === draggable.data('original-parent')
      },
      drop: function (event, ui) {
        const dropped = ui.draggable
        const droppedOn = $(this)
        const resp = droppedOn.data('resp')
        const value = dropped.data('value')

        // If there's already an answer-item in this question-item
        if (droppedOn.children('.answer-item').length > 0) {
          // Get the first answer-item (the one being replaced)
          const previous = droppedOn.children('.answer-item').first()

          // Remove the previous answer-item from the question-item and append it back to its original parent
          const originalParent = previous.data('original-parent')
          $('.answer-items[data-resp="' + originalParent + '"]').append(previous)
        }

        // Append the new answer-item to the question-item
        dropped.detach().css({ top: 0, left: 0 }).appendTo(droppedOn as any)
        droppedOn.data('value', value)
        $('input[name=' + resp + ']').val(value).trigger('change')
      },
      out: function (event, ui) {
        const droppedOn = $(this)
        const resp = droppedOn.data('resp')
        droppedOn.data('value', '')
        $('input[name=' + resp + ']').val('').trigger('change')
      }
    })
  }

  addSliderLogic () {
    $('.smart-slider').slider({
      range: 'min',
      create: function (event: any, ui: any) {
        $(this).slider('option', 'orientation', $(this).data('orientation'))
        $(this).slider('option', 'min', $(this).data('min'))
        $(this).slider('option', 'max', $(this).data('max'))
        $(this).slider('option', 'value', $(this).data('value'))
      },
      slide: function (event: any, ui: any) {
        const normalization = $(this).data('normalization')
        const rawValue = ui.value
        const value = rawValue * (100 / normalization)
        const resp = $(this).data('resp')
        $('input[name=' + resp + ']').val(value).trigger('change')
      }
    })
  }

  submitTestForm (): void {
    this.showConfirmDialog = false

    // if this is a preview (test instead of courseTest) never submit
    if (this.courseTest) {
      this.saving = true

      const inputs: any = this.getInputs()
      this.addTraceEntry(this.currentPage)
      if (this.courseTest.machineName &&
        this.courseTest.testVersion &&
        this.courseTest.testId &&
        this.courseTest._id &&
        this.courseTest.courseId &&
        this.pupilId) {
        const data: Result = {
          courseId: this.courseTest.courseId,
          testId: this.courseTest.testId,
          courseTestId: this.courseTest._id,
          pupilId: this.pupilId,
          machineName: this.courseTest.machineName,
          testVersion: TestUtils.getVersionString(this.courseTest.testVersion),
          inputs: inputs,
          trace: this.trace
        }

        EvaluationService.evaluateResults(data).then((results) => {
          this.$store.dispatch('notifications/showSuccess', {
            text: i18n.t('notifications.success.evaluate').toString()
          })

          this.$router.push({ name: 'PupilDashboard' })
        }).catch((error) => {
          this.$log.debug('Could not evaluate results ', error)

          this.$store.dispatch('notifications/showError', {
            text: i18n.t('notifications.error.evaluate').toString()
          })
        }).finally(() => {
          this.saving = false
        })
      }
    }
  }

  loadTest (): void {
    this.traceTimestamp = DateTime.now()

    let machineName

    if (this.courseTest && this.courseTest.testVersion && this.courseTest.versions) {
      this.currentVersion = TestUtils.findVersionInList(this.courseTest.testVersion, this.courseTest.versions)
      machineName = this.courseTest.machineName
    } else if (this.test) {
      this.currentVersion = this.version

      // in case we got no specific version, we'll use the "latest" one
      if (!this.currentVersion) {
        this.currentVersion = this.test.versions[this.test.versions.length - 1]
      }

      machineName = this.test.machineName
    }

    if (this.currentVersion && machineName) {
      this.loading = true

      TestUtils.getCompleteHTMLForTest(this.currentVersion, machineName, this.selectedLanguage, this.user !== null).then((result) => {
        this.html = result

        // wait for vue to render
        this.$nextTick(() => {
          if (this.pagedDisplay) {
            this.showCurrentPage()
          }

          this.addDragDropLogic()
          this.addSliderLogic()
          this.renderMathJax()

          if (this.showSolution && this.currentVersion?.solution) {
            this.$nextTick(() => {
              this.fillSolution()
            })
          }
        })
      }).finally(() => {
        this.loading = false
      })
    }
  }

  renderMathJax () {
    // This is all that is needed if we'd use tex instead of ascii
    // if (window.MathJax) {
    //   window.MathJax.startup.promise.then(() => {
    //     let mathJaxElement = this.mathJaxContent
    //     if (mathJaxElement && !Array.isArray(mathJaxElement)) {
    //       mathJaxElement = [mathJaxElement] as any
    //     }
    //
    //     window.MathJax.typesetPromise(mathJaxElement)
    //   })
    // }

    window.MathJax = {
      loader: {
        load: ['input/asciimath', 'output/svg']
      }
      // asciimath: {
      //   delimiters: [['$', '$'], ['`', '`']]
      // }
    }

    window.MathJax.promise = new Promise<void>(function (resolve, reject) {
      window.MathJax.startup = {
        ready () {
          window.MathJax.startup.defaultReady()
          window.MathJax.startup.promise.then(() => resolve())
        }
      }
    })

    // TODO: For this to work the user needs to copy the latest version of mathjax to the
    // TODO: public folder next to the tests-src
    window.MathJaxLoader = this.loadScript('/mathjax/es5/startup.js')
      .then(() => window.MathJax.config.promise)
  }

  loadScript (url: string) {
    return new Promise(function (resolve, reject) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src = url

      script.onload = resolve
      script.onerror = reject
      document.head.appendChild(script)
    })
  }

  mounted (): void {
    this.loadTest()
  }
}
</script>

<style scoped>
@import '../../node_modules/jqueryui/jquery-ui.css';
</style>
