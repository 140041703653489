<template>
  <div class="fill-height">
    <LoadingCard v-if="loading" />
    <router-view></router-view>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import LoadingCard from '@/components/LoadingCard.vue'
import { CategoryService } from '@/services/CategoryService'
@Component({
  components: { LoadingCard }
})
export default class Dashboard extends Vue {
  loading = true

  mounted (): void {
    if (!this.$store.getters['auth/isAuthenticated']) {
      this.$router.push({ name: 'Login' }).catch(error => {
        if (error.name !== 'NavigationDuplicated') {
          throw error
        }
      })
    } else {
      this.loading = false
    }
  }
}
</script>
