<template>
  <v-sheet>
    <LoadingCard v-if="isLoading" flat />
    <template v-else>
      <v-card v-if="selectedCourse" flat>
        <v-card-title>
          <h2>{{ $t('courseTestTable.headline') }}</h2>
          <v-spacer></v-spacer>
          <v-btn text @click="openAddTestDialog">
            <v-icon left>
              mdi-plus-circle-outline
            </v-icon>
            {{ $t('courses.addTest') }}
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p v-if="!courseTests || !courseTests.length" class="d-flex justify-center text-h5 text--disabled mt-10">
            {{ $t('courses.noTests') }}
          </p>
          <v-simple-table v-else>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  {{ $t('courses.test.name') }}
                </th>
                <th class="text-left">
                  {{ $t('courses.test.created') }}
                </th>
                <th class="text-left">
                  {{ $t('courses.test.progress') }}
                </th>
                <th class="text-right">
                  {{ $t('courses.test.action') }}
                </th>
                <th class="text-right">
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="test in courseTests" :key="test.testId + test.created">
                <td>{{ getCurrentLangText(test.name) }} ({{ test.variant }})</td>
                <td>{{ formatDate(test.created) }}</td>
                <td>
                  <v-progress-circular v-if="loadingProgress" indeterminate color="primary"></v-progress-circular>
                  <template v-else>
                    {{ getProgressStringById(test._id) }}
                  </template>
                </td>
                <td class="text-right">
                  <CourseTestToolbar :courseTest="test"
                                     :selectedCourse="selectedCourse"
                                     showDelete
                                     @onEdited="getCourseTests"
                                     @onTogglePublishTest="getCourseTests"
                                     @onDeleted="getCourseTests" />
                </td>
                <td class="text-right">
                  <v-tooltip :open-delay="500" top>
                    <template v-slot:activator="{ on }">
                      <v-btn color="orange" inverted outlined :aria-label="$t('tooltips.showAnalysis')" v-on="on"
                             @click="showTestAnalysis(test._id)">
                        <v-icon>
                          mdi-magnify
                        </v-icon>
                        {{ $t('tooltips.showAnalysis') }}
                      </v-btn>
                    </template>
                    <span>{{ $t('tooltips.showAnalysis') }}</span>
                  </v-tooltip>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
      <v-card v-else flat>
        <v-card-title>
          {{ $t('courses.title') }}
        </v-card-title>
        <v-divider />
        <v-card-text>
          {{ $t('courses.description') }}
        </v-card-text>
      </v-card>
    </template>
    <AddTestDialog v-if="selectedCourse"
                   :show.sync="showAddTestDialog"
                   :course="selectedCourse"
                   @added="testAdded"/>
  </v-sheet>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import LoadingCard from '@/components/LoadingCard.vue'
import CourseTestToolbar from '@/components/CourseTestToolbar.vue'
import { Course, CourseTest, Page, PageParams, ResultCount } from '@/types/Types'
import { DateTime } from 'luxon'
import { LangUtils } from '@/utils/LangUtils'
import { ResultService } from '@/services/ResultService'
import { PupilService } from '@/services/PupilService'
import { CourseTestService } from '@/services/CourseTestService'
import { AxiosResponse } from 'axios'
import AddTestDialog from '@/components/AddTestDialog.vue'
import { Watch } from 'vue-property-decorator'

const CourseTestTableProps = Vue.extend({
  props: {
    selectedCourseId: { type: String || null },
    courses: { type: Array as () => Array<Course>, required: true }
  }
})

@Component({
  components: { AddTestDialog, CourseTestToolbar, LoadingCard }
})
export default class CourseTestTable extends CourseTestTableProps {
  isLoading = true
  loadingProgress = true
  showAddTestDialog = false
  courseTestProgress: Array<ResultCount> = []
  courseTests: Array<CourseTest> = []
  pupilCount = 0

  get language (): string {
    return this.$i18n.locale
  }

  get selectedCourse (): Course | null {
    if (this.selectedCourseId !== null) {
      return this.courses.find(course => course._id === this.selectedCourseId) || null
    } else {
      return null
    }
  }

  @Watch('selectedCourseId')
  onCourseChange (): void {
    this.getCourseTests()
  }

  showTestAnalysis (courseTestId: string): void {
    this.$router.push({ name: 'Results', params: { courseTestId: courseTestId } })
  }

  testAdded (): void {
    this.showAddTestDialog = false
    this.getCourseTests()
  }

  openAddTestDialog (): void {
    this.showAddTestDialog = true
  }

  formatDate (date: string): string {
    return DateTime.fromISO(date).setLocale(this.language).toLocaleString()
  }

  getCurrentLangText (text: Record<string, string>): string {
    return LangUtils.getLangOrFallback(text, this.language)
  }

  getProgressStringById (courseTestId: string): string {
    const progress = this.courseTestProgress.find(item => item._id === courseTestId) || null

    if (progress) {
      return progress.resultCount.toString() + ' ' + this.$t('courses.of').toString() + ' ' + this.pupilCount.toString()
    } else {
      return '? ' + this.$t('courses.of').toString() + ' ' + this.pupilCount.toString()
    }
  }

  getProgress (): void {
    this.loadingProgress = true

    const courseTestIds = this.courseTests.map(item => item._id || '').filter(Boolean)

    if (courseTestIds.length && this.selectedCourseId) {
      const promises: Array<Promise<any>> = []

      promises.push(ResultService.getProgress(courseTestIds).then((results) => {
        this.courseTestProgress = results.data
      }))

      promises.push(PupilService.countPupilsByCourse(this.selectedCourseId).then((pupilCount) => {
        this.pupilCount = pupilCount.data
      }))

      Promise.allSettled(promises).finally(() => {
        this.loadingProgress = false
      })
    }
  }

  getCourseTests (): void {
    this.isLoading = true

    if (this.selectedCourse?._id) {
      const pageParams: PageParams = {}

      CourseTestService.getTestsForCourse(this.selectedCourse._id, pageParams).then((results: AxiosResponse<Page<CourseTest>>) => {
        this.courseTests = results.data.results

        this.getProgress()
      }).catch((error) => {
        this.$log.debug('Could not get course list ', error)

        this.$store.dispatch('notifications/showError', {
          text: this.$t('notifications.error.getCourseTestList').toString()
        })
      }).finally(() => {
        this.isLoading = false
      })
    }
  }

  mounted () {
    this.getCourseTests()
  }
}
</script>

<style scoped>

</style>
