<template>
  <v-container>
    <LoadingCard v-if="loading" />
    <TestPlayer v-else :courseTest="courseTest" />
  </v-container>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import router from '@/router'
import { CourseTest } from '@/types/Types'
import LoadingCard from '@/components/LoadingCard.vue'
import { CourseTestService } from '@/services/CourseTestService'
import { AxiosResponse } from 'axios'
import TestPlayer from '@/components/TestPlayer.vue'
@Component({
  components: { LoadingCard, TestPlayer }
})
export default class ConductTest extends Vue {
  loading = true
  courseTest: CourseTest | null = null

  mounted (): void {
    router.onReady(() => {
      const courseTestId = this.$route.params.courseTestId

      CourseTestService.getCourseTest(courseTestId).then((result: AxiosResponse<CourseTest>) => {
        this.courseTest = result.data
      }).catch((error) => {
        this.$log.debug('could not get course test', error)

        this.$store.dispatch('notifications/showError', {
          text: this.$t('notifications.error.getCourseTest').toString()
        })
      }).finally(() => {
        this.loading = false
      })
    })
  }
}
</script>

<style scoped>

</style>
