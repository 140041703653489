import { AxiosPromise } from 'axios'
import { ApiService } from '@/api/ApiService'
import { PageParams } from '@/types/Types'

export const TestService = {
  getAllTests (): AxiosPromise {
    return this.getTests()
  },

  getTests (params: PageParams = {}): AxiosPromise {
    return ApiService({
      url: '/tests',
      method: 'get',
      params: params
    })
  }
}
