import { AxiosPromise } from 'axios'
import { ApiService } from '@/api/ApiService'

export const ResultService = {
  getProgress (courseTestIdList: Array<string>): AxiosPromise {
    return ApiService({
      url: '/results/progress',
      method: 'post',
      data: {
        courseTestIdList: courseTestIdList
      }
    })
  }
}
