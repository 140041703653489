<template>
  <v-dialog v-model="dialog" max-width="100%">
    <v-card min-height="400px">
      <v-card-title>
        <v-row>
          <v-col v-if="mobile" cols="2">
            <v-app-bar-nav-icon @click.stop="showDrawer = !showDrawer"></v-app-bar-nav-icon>
          </v-col>
          <v-col :cols="mobile ? '9' : '11'">
            {{ $t('addTestDialog.title') }}
          </v-col>
          <v-col cols="1" class="d-flex justify-end">
            <v-btn icon @click="closeDialog">
              <v-icon>mdi-close-circle-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider/>
      <v-row no-gutters class="fill-height">
        <v-col class="shrink">
          <v-navigation-drawer
            :style="mobile ? '' : 'transform: translateX(0) !important;visibility: visible !important;'"
            :absolute="mobile"
            :permanent="!mobile"
            class="nav-color"
            v-model="showDrawer">
            <v-list nav>
              <v-list-item-group v-model="tab">
                <v-list-item v-for="category in categories"
                             link
                             :key="category.value">
                  <v-list-item-content>
                    <v-list-item-title>{{ category.nameKey ? $t(category.nameKey) : category.name }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-navigation-drawer>
        </v-col>
        <v-col>
          <LibraryTestContainer :tab="tab"
                         :course="course"
                         @testAdded="testAdded" />
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import LibraryTestContainer from '@/components/LibraryTestContainer.vue'
import { Category, Course } from '@/types/Types'
import { Options } from '@/types/Options'

const AddTestDialogProps = Vue.extend({
  props: {
    course: { type: Object as () => Course, required: true },
    show: { type: Boolean, required: true },
    saving: { type: Boolean, default: false }
  }
})
@Component({
  components: { LibraryTestContainer }
})
export default class AddTestDialog extends AddTestDialogProps {
  courseName = ''
  tab = 0
  showDrawer = true

  get categories (): Array<Category> {
    return this.$store.state.library.categories
  }

  get dialog (): boolean {
    return this.show
  }

  set dialog (value: boolean) {
    this.$emit('update:show', value)
  }

  get isSaving (): boolean {
    return this.saving
  }

  set isSaving (value: boolean) {
    this.$emit('update:saving', value)
  }

  get mobile (): boolean {
    return this.$vuetify.breakpoint.mobile
  }

  testAdded (): void {
    this.$emit('added')
  }

  save (): void {
    this.isSaving = true
    this.$emit('save', this.courseName)
  }

  closeDialog (): void {
    this.dialog = false
    this.$emit('close')
    this.tab = 0
  }
}
</script>
