import { AxiosPromise } from 'axios'
import { ApiService } from '@/api/ApiService'
import { PageParams, Pupil } from '@/types/Types'

export const PupilService = {
  getPupilsByCourse (courseId: string, pageParams: PageParams, includeResults = true): AxiosPromise {
    return ApiService({
      url: '/pupils/byCourse/' + courseId,
      method: 'get',
      params: { ...pageParams, includeResults }
    })
  },

  countPupilsByCourse (courseId: string): AxiosPromise {
    return ApiService({
      url: '/pupils/byCourse/' + courseId + '/count',
      method: 'get'
    })
  },

  createPupil (pupilData: Pupil): AxiosPromise {
    return ApiService({
      url: '/pupils',
      method: 'post',
      data: pupilData
    })
  },

  createPupils (courseId: string, amount: number, names: Array<string> = []): AxiosPromise {
    const pupils = {
      amount: amount,
      courseId: courseId,
      names: names
    }

    return ApiService({
      url: '/pupils/bulk',
      method: 'post',
      data: pupils
    })
  },

  deletePupil (pupilId: string): AxiosPromise {
    return ApiService({
      url: '/pupils/' + pupilId,
      method: 'delete'
    })
  }
}
